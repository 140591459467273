import { useAppSelector } from '../../store/hooks.ts'
import { selectCurrentOnBoardingStep, selectShowProductTour } from '../../store/productTour/productTourSlice.ts'
import { useDetectScreenSize } from '../../utils/useDetectScreenSize.ts'
import { useRef } from 'react'
import { selectAllMessages } from '../../store/messages/messagesSlice.ts'

export const useConversationPageHook = () => {
  const showProductTour = useAppSelector(selectShowProductTour)
  const isMobile = useDetectScreenSize()
  const step = useAppSelector(selectCurrentOnBoardingStep)
  const messages = useAppSelector(selectAllMessages)
  const targetRef = useRef<HTMLDivElement>(null)

  return {
    showProductTour,
    isMobile,
    step,
    targetRef,
    messages,
  }
}
