import { ReactElement, useState } from 'react'

export function useToaster() {
  const [displayToaster, setDisplayToaster] = useState(false)
  const [toasterMessageElement, setToasterMessageElement] = useState<ReactElement | string>('')
  const showToast = (message: ReactElement | string) => {
    setDisplayToaster(true)
    setToasterMessageElement(message)

    setTimeout(() => {
      setDisplayToaster(false)
      setToasterMessageElement('')
    }, 3000)
  }
  return {
    displayToaster,
    toasterMessageElement,
    showToast,
  }
}
