import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime'

export function TheIconRename(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg className={props.className} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2819_3918)">
        <path d="M12.8914 3.00006C13.0984 2.99737 13.3184 3.07953 13.496 3.25192C13.9127 3.65629 14.3295 4.06101 14.7357 4.47548C15.1053 4.85293 15.0837 5.40545 14.6933 5.79266C13.7139 6.76336 12.7322 7.73138 11.7511 8.70074C10.8565 9.58491 9.96046 10.4677 9.06854 11.3549C8.93942 11.4832 8.79766 11.563 8.61729 11.5974C7.89584 11.7344 7.17609 11.8799 6.45532 12.0203C6.03788 12.1021 5.76085 11.8216 5.84522 11.4088C5.98938 10.7034 6.13285 9.99804 6.2729 9.29198C6.30467 9.13138 6.3706 8.99838 6.49084 8.8802C8.3751 7.02835 10.257 5.17481 12.1378 3.31959C12.3366 3.12363 12.5621 3.00141 12.8914 3.0004V3.00006ZM12.8473 3.93239C12.8367 3.93878 12.8275 3.94283 12.8207 3.94922C10.961 5.77852 9.10168 7.60781 7.24407 9.43878C7.20922 9.47313 7.18531 9.52666 7.17575 9.57481C7.0883 9.99771 7.00427 10.4216 6.92057 10.8455C6.91272 10.8849 6.91545 10.9257 6.91238 10.9809C7.3708 10.8896 7.81078 10.8078 8.24734 10.7112C8.3488 10.689 8.45708 10.6331 8.53053 10.561C10.3366 8.78794 12.1381 7.01017 13.9425 5.23508C13.9824 5.19569 14.0436 5.17717 14.088 5.15293C13.6463 4.71791 13.2504 4.32835 12.8476 3.93171H12.8473V3.93239Z" />
        <path d="M13.8843 13.2465C13.8843 13.6926 13.676 13.8997 13.2261 13.8997H4.76777C4.33052 13.8997 4.11668 13.6879 4.11668 13.2566V11.3828C4.11668 11.0791 3.86698 10.833 3.55885 10.833H3.55783C3.24971 10.833 3 11.0791 3 11.3828V13.2377C3 14.3047 3.70574 14.9993 4.78861 15H11.4511C13.4676 15 11.2137 15.0003 13.2298 15C14.2895 14.9997 15 14.2993 15 13.2546C15 12.6307 15 12.0071 15 11.3832C15 11.0795 14.7503 10.8333 14.4422 10.8333C14.134 10.8333 13.8843 11.0795 13.8843 11.3832C13.8843 12.0044 13.8843 12.6256 13.8843 13.2468V13.2465Z" />
      </g>
      <defs>
        <clipPath id="clip0_2819_3918">
          <rect width="12" height="12" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  )
}
