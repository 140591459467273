import { useAppDispatch, useAppSelector } from '../store/hooks.ts'
import { useEffect, useRef } from 'react'
import { setTooltipPositions } from '../store/productTour/productTourSlice.ts'
import { selectAllMessages } from '../store/messages/messagesSlice.ts'
import { UpdateCurrentStepResponse } from '../types/productTour.ts'
import { Title } from '../types/conversation.ts'
import { PRODUCT_TOUR_FILE_COMPARISON } from '../utils/CONVERSATION_CONSTANTS.ts'

type Props = {
  title: Title
  step: UpdateCurrentStepResponse['data']['step']
  description: string
  isActive: boolean
}

export function ConversationTypeItem({ title, description, isActive, step }: Readonly<Props>) {
  const messages = useAppSelector(selectAllMessages)
  const conversationTypeRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const targetRect = conversationTypeRef.current?.getBoundingClientRect()
    if (!targetRect) return
    const tooltipWidth = 350 // Width of your tooltip
    const tooltipHeight = 180 // Height of your tooltip
    const tooltipParentPadding = 12 // Padding of the span element that contains the component
    const tooltipGrandParentPadding = 20 // Padding of the div element that contains the span element containing the component
    const above = targetRect.top - tooltipHeight + tooltipParentPadding - tooltipGrandParentPadding - 10
    const below = targetRect.bottom + tooltipParentPadding + tooltipGrandParentPadding + 10
    const left = targetRect.left + window.scrollX + targetRect.width / 2 - tooltipWidth / 2
    const position = {
      [step]: {
        top: step === PRODUCT_TOUR_FILE_COMPARISON ? above : below,
        left,
      },
    }
    dispatch(setTooltipPositions(position))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setTitleStyle = () => {
    return isActive ? 'text-primary' : 'text-label'
  }

  const setDescriptionStyle = () => {
    return isActive ? 'text-label' : 'text-silver'
  }

  const setMarginBottom = () => {
    return !messages.length && 'mb-4'
  }

  return (
    <div ref={conversationTypeRef}>
      <h3 className={`${setMarginBottom()} text-center text-base font-semibold ${setTitleStyle()}`}>{title}</h3>
      {!messages.length && <p className={`text-center text-sm ${setDescriptionStyle()} `}>{description}</p>}
    </div>
  )
}
