import { useDocumentTitle } from '../../utils/useDocumentTitle.ts'
import React from 'react'

type Props = {
  title: string
  children: React.ReactNode
}

export function Page(props: Readonly<Props>) {
  const titlePrefix = 'Olympe Olympe | '
  useDocumentTitle(`${titlePrefix}${props.title}`)
  return <>{props.children}</>
}
