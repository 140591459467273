import { TheIconClosingCross } from '../../icons/TheIconClosingCross.tsx'
import { useOnboardingCard } from './use-onboardingCard.hook.tsx'
import { useOnboardingCardZeroHook } from './use-onboardingCardZero.hook.tsx'

export function OnboardingStepZero() {
  const { handleQuitOnboarding, handleNextStep } = useOnboardingCard()

  const { firstName } = useOnboardingCardZeroHook()

  return (
    <div
      className={
        'flex h-[39.25rem] w-[32.875rem] flex-col items-end gap-1 rounded-2xl bg-mistySkyGray pb-7 pl-5 pr-3 pt-2 shadow-deepGrayShadow'
      }
    >
      <button className={'flex flex-col items-start gap-2.5 p-1'} onClick={handleQuitOnboarding}>
        <TheIconClosingCross width={'10'} height={'10'} />
      </button>
      <div className={'flex flex-1 flex-col items-center justify-center gap-2 self-stretch'}>
        <div className={'flex flex-1 flex-col items-center gap-8 self-stretch pr-2'}>
          {/* 1382 */}
          <div className={'flex flex-col items-end gap-2 self-stretch'}>
            <img
              src={'/assets/images/onboarding/onboarding_0.gif'}
              alt={'Illustration 0'}
              className={'h-[18rem] w-[30.375rem] rounded-xl border border-solid border-iceberg'}
            />
          </div>
          {/* 1367 */}
          <div className={'flex flex-col items-center gap-7 self-stretch'}>
            <div className={'flex flex-col items-center justify-center gap-3 self-stretch'}>
              {/* 1281 */}
              <div
                className={
                  ' flex flex-col items-center justify-center gap-2.5 text-center text-[1.75rem] font-semibold leading-9'
                }
              >
                <div>
                  {'Bienvenue à bord,'}
                  <br />
                  <span className={'font-normal text-label'}>{`${firstName} !`}</span>
                </div>
              </div>
              {/* 1282 */}
              <div className={'flex flex-col items-center justify-center self-stretch px-5 py-0'}>
                <p className={'self-stretch text-center text-base leading-6 text-label'}>
                  {'Je suis Olympe, l’assistant IA conçu par des DPOs experts.'}
                </p>
                <p className={'self-stretch text-center text-base leading-6 text-label'}>
                  {'Je suis un outil conçu pour vous assister.'}
                </p>
              </div>
            </div>
            <button
              className={
                'flex items-center justify-center gap-3 rounded-lg bg-primary px-6 py-3 text-sm leading-5 text-silver'
              }
              onClick={handleNextStep}
            >
              {"C'est parti !"}
            </button>
            <button
              className={'flex items-center justify-center gap-3 rounded-lg text-sm font-semibold leading-5'}
              onClick={handleQuitOnboarding}
            >
              {'Passer'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
