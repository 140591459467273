import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { UpdateCurrentStepResponse } from '../../../types/productTour.ts'

export const updateCurrentStep = createAppAsyncThunk(
  'productTour/updateCurrentStep',
  async (step: UpdateCurrentStepResponse['data']['step'], { extra: { productTourGateway } }) => {
    try {
      return await productTourGateway.updateCurrentStep(step)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
