import React, { RefObject, useRef, useState } from 'react'
import {
  CGUErrors,
  ConfirmPasswordErrors,
  EmailErrors,
  FirstNameErrors,
  hasAnyErrors,
  JobTitleErrors,
  LastNameErrors,
  passwordDetailedErrors,
  PasswordDetailedErrors,
  PasswordSimpleErrors,
  validateCGU,
  validateEmail,
  validateFirstName,
  validateJobTitle,
  validateLastName,
  validatePasswordConfirmation,
  validatePasswordDetailed,
  validatePasswordSimple,
} from '../../utils/formValidation.ts'
import { useAppDispatch } from '../../store/hooks.ts'
import { useNavigate } from 'react-router-dom'
import { createUser } from '../../store/auth/useCases/createUser.ts'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { useSelector } from 'react-redux'
import { selectRegisterStepper, setRegisterStepper } from '../../store/stepper/steppersSlice.ts'
import { useToaster } from '../../utils/useToaster.ts'

type FormKey = 'email' | 'password' | 'confirmPassword' | 'firstname' | 'lastname' | 'jobTitle' | 'cgu'

export interface DpoCard {
  isChecked: boolean
  isDisabled: boolean
  id: 'DPO Externe' | 'DPO Interne' | 'Autre'
}

const registerFomInitialState = {
  email: '',
  password: '',
  confirmPassword: '',
  firstname: '',
  lastname: '',
  jobTitle: 'DPO Externe',
  cgu: false,
}

const jobTitleCardsInitialState: DpoCard[] = [
  { isChecked: true, isDisabled: false, id: 'DPO Externe' },
  { isChecked: false, isDisabled: false, id: 'DPO Interne' },
  { isChecked: false, isDisabled: false, id: 'Autre' },
]

const lastNameErrorsInitialState: LastNameErrors = { emptyLastName: '', invalidFormat: '' }
const confirmPasswordErrorsInitialState: ConfirmPasswordErrors = { mismatch: '' }
const passwordErrorsInitialState: PasswordDetailedErrors = {
  lowerCase: passwordDetailedErrors.lowerCase,
  minLength: passwordDetailedErrors.minLength,
  specialCharacters: passwordDetailedErrors.specialCharacters,
  upperCase: passwordDetailedErrors.upperCase,
  numbers: passwordDetailedErrors.numbers,
}
const jobTitleErrorsInitialState: JobTitleErrors = { emptyJobTitle: '' }
const CGUErrorsInitialState: CGUErrors = { notAccepted: '' }
const emailErrorsInitialState: EmailErrors = { emptyEmail: '', invalidFormat: '' }
const firstNameErrorsInitialState: FirstNameErrors = { emptyFirstName: '', invalidFormat: '' }
const passwordSimpleErrorsInitialState: PasswordSimpleErrors = { emptyPassword: '' }

export const useSignupHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const steps = useSelector(selectRegisterStepper)

  // Form values local state
  const [form, setForm] = useState(registerFomInitialState)
  const [otherJobTitle, setOtherJobTitle] = useState('')

  // Errors local state
  const [lastNameErrors, setLastNameErrors] = useState<LastNameErrors>(lastNameErrorsInitialState)
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<ConfirmPasswordErrors>(
    confirmPasswordErrorsInitialState,
  )
  const [jobTitleErrors, setJobTitleErrors] = useState<JobTitleErrors>(jobTitleErrorsInitialState)
  const [passwordErrors, setPasswordErrors] = useState<PasswordDetailedErrors>(passwordErrorsInitialState)
  const [cguErrors, setCguErrors] = useState<CGUErrors>(CGUErrorsInitialState)
  const [emailErrors, setEmailErrors] = useState<EmailErrors>(emailErrorsInitialState)
  const [firstNameErrors, setFirstNameErrors] = useState<FirstNameErrors>(firstNameErrorsInitialState)
  const [passwordSimpleErrors, setPasswordSimpleErrors] = useState<PasswordSimpleErrors>(
    passwordSimpleErrorsInitialState,
  )

  // Stepper informations
  const [cardsWithIcon, setCardsWithIcon] = useState<DpoCard[]>(jobTitleCardsInitialState)
  const currentStepIndex = steps.findIndex((step) => step.isActive)
  const currentStep = currentStepIndex + 1

  // Form Inputs Refs
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const passwordConfirmInputRef = useRef<HTMLInputElement>(null)
  const firstNameInputRef = useRef<HTMLInputElement>(null)
  const lastNameInputRef = useRef<HTMLInputElement>(null)
  const externalDpoJobTitleRef = useRef<HTMLInputElement>(null)
  const internalDpoJobTitleRef = useRef<HTMLInputElement>(null)
  const otherJobTitleRef = useRef<HTMLInputElement>(null)
  const cguInputRef = useRef<HTMLInputElement>(null)

  // Utils local state
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false)
  const { showToast, toasterMessageElement } = useToaster()

  // Update state functions
  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('email', e)
    setEmailErrors(validateEmail(e.target.value))
  }
  function handlePasswordChange(e: React.FocusEvent<HTMLInputElement>) {
    getHandler('password', e)
    setPasswordErrors(validatePasswordDetailed(e.target.value))
  }
  function handleConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('confirmPassword', e)
    setConfirmPasswordErrors(validatePasswordConfirmation(form.password, e.target.value))
  }
  function handleLastNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('lastname', e)
    setLastNameErrors(validateLastName(e.target.value))
  }
  function handleFirstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('firstname', e)
    setFirstNameErrors(validateFirstName(e.target.value))
  }
  function handleJobTitleChange(id: DpoCard['id']) {
    setCardsWithIcon((prevCards) => prevCards.map((card) => ({ ...card, isChecked: card.id === id })))
    if (id !== 'Autre') {
      setForm({ ...form, jobTitle: id })
      setJobTitleErrors({ emptyJobTitle: '' })
      setOtherJobTitle('')
    }
  }
  function handleOtherJobTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setOtherJobTitle(e.target.value)
    setJobTitleErrors(validateJobTitle(e.target.value))
  }
  function handleChangeCGU(e: React.ChangeEvent<HTMLInputElement>) {
    setForm({ ...form, cgu: e.currentTarget.checked })
    setCguErrors(CGUErrorsInitialState)
  }

  // Validation functions for each input
  function handleEmailValidation(event: React.FocusEvent<HTMLInputElement>) {
    const emailErrors = validateEmail(event.target.value)
    setEmailErrors(emailErrors)
  }
  function handleLastNameValidation(e: React.FocusEvent<HTMLInputElement>) {
    const lastNameErrors = validateLastName(e.target.value)
    setLastNameErrors(lastNameErrors)
  }
  function handleFirstNameValidation(e: React.FocusEvent<HTMLInputElement>) {
    const firstNameErrors = validateFirstName(e.target.value)
    setFirstNameErrors(firstNameErrors)
  }
  function handleOtherJobTitleValidation(event: React.FocusEvent<HTMLInputElement>) {
    setJobTitleErrors(validateJobTitle(event.target.value))
  }

  // Validation functions for each section
  const validateEmailAndPassword = () => {
    const emailErrors = validateEmail(form.email)
    setEmailErrors(emailErrors)
    const passwordErrors = validatePasswordSimple(form.password)
    setPasswordSimpleErrors(passwordErrors)
    const passwordConfirmationErrors = validatePasswordConfirmation(form.password, form.confirmPassword)
    setConfirmPasswordErrors(passwordConfirmationErrors)
    return hasAnyErrors(emailErrors, passwordErrors, passwordConfirmationErrors)
  }
  const validateName = () => {
    const firstNameErrors =
      firstNameInputRef.current?.value && firstNameInputRef.current?.value.length
        ? validateFirstName(firstNameInputRef.current?.value)
        : validateFirstName(form.firstname)
    setFirstNameErrors(firstNameErrors)
    const lastNameErrors =
      lastNameInputRef.current?.value && lastNameInputRef.current?.value.length
        ? validateLastName(lastNameInputRef.current?.value)
        : validateLastName(form.lastname)
    setLastNameErrors(lastNameErrors)
    return hasAnyErrors(firstNameErrors, lastNameErrors)
  }
  const validateJobTitleAndCgu = () => {
    const jobTitleErrors = validateJobTitle(form.jobTitle)
    setJobTitleErrors(jobTitleErrors)
    const cguErrors = validateCGU(form.cgu)
    setCguErrors(cguErrors)
    return hasAnyErrors(jobTitleErrors, cguErrors)
  }

  // Navigation functions
  const handleNextStep = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const nextStepIndex = currentStepIndex + 1
    switch (currentStepIndex) {
      case 0:
        if (validateEmailAndPassword()) return
        moveToStep(currentStepIndex, nextStepIndex)
        break
      case 1:
        if (validateName()) return
        moveToStep(currentStepIndex, nextStepIndex)
        break
      case 2:
        if (validateJobTitleAndCgu()) return
        moveToStep(currentStepIndex, nextStepIndex)
        break
      default:
        break
    }
  }
  const handlePreviousStep = () => {
    const previousStepIndex = currentStepIndex - 1
    switch (currentStepIndex) {
      case 0:
        moveToStep(currentStepIndex, previousStepIndex)
        break
      case 1:
        moveToStep(currentStepIndex, previousStepIndex)
        break
      case 2:
        moveToStep(currentStepIndex, previousStepIndex)
        break
      default:
        break
    }
  }
  const moveToStep = (currentStepIndex: number, stepToSetActive: number) => {
    if (stepToSetActive < steps.length) dispatch(setRegisterStepper({ currentStepIndex, stepToSetActive }))
  }

  // Utils functions
  const getPageTitle = () => {
    const currentStepIndex = steps.findIndex((step) => step.isActive)
    switch (currentStepIndex) {
      case 2:
        return 'Votre fonction'
      case 1:
        return 'Vos informations'
      default:
        return 'Inscription'
    }
  }
  const handleIsCardsWithIconSelected = () => {
    setCardsWithIcon([])
  }
  const togglePasswordInputType = (inputRef: RefObject<HTMLInputElement>) => {
    const inputType = inputRef.current?.type
    if (!inputType) return
    inputRef.current.type = inputType === 'password' ? 'text' : 'password'
  }
  function handlePasswordFocus() {
    const passwordErrors = validatePasswordDetailed(form.password)
    setPasswordErrors(passwordErrors)
    setShowPasswordRequirements(true)
  }
  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    // Reset password input type
    setPasswordInputType('password')
    const checkedCard = getCheckedCard(cardsWithIcon)

    // Job title and CGU UI validation
    if (checkedCard?.id === 'Autre' && !otherJobTitle?.length) setJobTitleErrors(validateJobTitle(''))
    if (!cguInputRef.current?.checked) setCguErrors(validateCGU(false))
    if ((checkedCard?.id === 'Autre' && !otherJobTitle?.length) || !cguInputRef.current?.checked) return

    // Job title setting
    const jobTitleValue = otherJobTitle.length ? otherJobTitle : form.jobTitle

    // Check for errors in all form
    const hasErrors = hasAnyErrors(
      passwordErrors,
      emailErrors,
      firstNameErrors,
      lastNameErrors,
      jobTitleErrors,
      cguErrors,
      passwordSimpleErrors,
      confirmPasswordErrors,
    )

    if (!hasErrors) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmPassword, ...formWithoutConfirmPassword } = form

      // Create new form without confirmPassword key/value pair
      const user = {
        ...formWithoutConfirmPassword,
        jobTitle: jobTitleValue,
      }

      // Dispatch create user action
      const createUserResponse = await dispatch(createUser(user))

      // Check if createUser action was successful
      if (createUser.fulfilled.match(createUserResponse)) {
        navigate(ROUTE_PATHS.accountVerificationInfo + `?email=${user.email}`)
      } else {
        showToast(createUserResponse.error.message ?? '')
      }
    }
  }
  const getHandler = (name: FormKey, e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [name]: e.target.value })
  }
  const getCheckedCard = (cards: DpoCard[]): DpoCard | undefined => {
    return cards.find((card) => card.isChecked)
  }

  return {
    form,
    otherJobTitle,
    handleEmailChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleJobTitleChange,
    handleOtherJobTitleChange,
    handleEmailValidation,
    handleLastNameValidation,
    handleFirstNameValidation,
    emailInputRef,
    passwordInputRef,
    passwordConfirmInputRef,
    lastNameInputRef,
    firstNameInputRef,
    externalDpoJobTitleRef,
    internalDpoJobTitleRef,
    otherJobTitleRef,
    cguInputRef,
    emailErrors,
    passwordErrors,
    passwordSimpleErrors,
    confirmPasswordErrors,
    lastNameErrors,
    firstNameErrors,
    jobTitleErrors,
    cguErrors,
    handlePasswordFocus,
    handleOtherJobTitleBlur: handleOtherJobTitleValidation,
    passwordInputType,
    handleSubmit,
    showPasswordRequirements,
    handleChangeCGU,
    currentStep,
    steps,
    handleNextStep,
    handlePreviousStep,
    getPageTitle,
    cardsWithIcon,
    handleIsCardsWithIconSelected,
    togglePasswordInputType,
    toasterMessageElement,
  }
}
