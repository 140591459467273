import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import { useNavigate, useParams } from 'react-router-dom'
import { Conversation, ConversationType, Title } from '../../types/conversation.ts'
import { selectConversationById } from '../../store/conversations/conversationsSlice.ts'
import { selectAllMessages } from '../../store/messages/messagesSlice.ts'
import { useRef } from 'react'
import { createConversation } from '../../store/conversations/use Cases/createConversation.ts'
import { updateConversation } from '../../store/conversations/use Cases/updateConversation.ts'
import { deleteFilesInConversation } from '../../store/conversations/use Cases/deleteFilesInConversation.ts'
import { UpdateCurrentStepResponse } from '../../types/productTour.ts'
import { PRODUCT_TOUR_FILE_COMPARISON, PRODUCT_TOUR_SINGLE_FILE } from '../../utils/CONVERSATION_CONSTANTS.ts'

type ConversationSelectorUIElement = {
  title: Title
  step: UpdateCurrentStepResponse['data']['step']
  description: string
}

export const useTheConversationTypesListHook = () => {
  const dispatch = useAppDispatch()
  const { conversationId } = useParams() as { conversationId: Conversation['id'] }
  const currentConversation = useAppSelector(selectConversationById(conversationId))
  const messages = useAppSelector(selectAllMessages)
  const navigate = useNavigate()
  const targetRef = useRef<HTMLDivElement>(null)

  const typesOfConversationList: Record<ConversationType, ConversationSelectorUIElement> = {
    MESSAGE_ONLY: {
      title: 'Rechercher',
      step: 'PRODUCT_TOUR_MESSAGE_ONLY',
      description: 'Chattez avec Olympe via le module de conversation.',
    },
    SINGLE_FILE: {
      title: 'Analyser',
      step: PRODUCT_TOUR_SINGLE_FILE,
      description: 'Joignez un document et posez une question à Olympe à son sujet.',
    },
    FILE_COMPARISON: {
      title: 'Comparer',
      step: PRODUCT_TOUR_FILE_COMPARISON,
      description: 'Joignez deux documents à la fois et comparez-les grâce à Olympe.',
    },
  }

  const handleClick = async (type: ConversationType) => {
    if (messages.length) {
      const newConversation = await dispatch(createConversation()).unwrap()
      const conversationUpdated = {
        ...newConversation,
        type,
      } as Conversation

      await dispatch(updateConversation(conversationUpdated))
      navigate(`/conversations/${newConversation.id}`)
    } else {
      if (!currentConversation) {
        return
      }
      await dispatch(updateConversation({ ...currentConversation, type }))
      await dispatch(deleteFilesInConversation(conversationId))
    }
  }
  return {
    targetRef,
    handleClick,
    typesOfConversationList,
    messages,
    currentConversation,
  }
}
