export function TheIconMagnifyingGlass() {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7115 18.1571L13.321 14.0998C13.2885 14.0613 13.2527 14.0223 13.2141 13.9824C13.2694 13.9275 13.3257 13.8712 13.3815 13.8129C14.9179 12.2221 15.6536 10.2782 15.5689 8.0343C15.4876 5.87582 14.6394 4.03476 13.0471 2.56322C11.4758 1.11046 9.58595 0.418829 7.42963 0.507572C5.42026 0.590211 3.69083 1.35932 2.29018 2.79235C0.604469 4.51791 -0.15724 6.60549 0.0269093 8.99827C0.172927 10.9051 0.935102 12.5616 2.29158 13.9219C3.78849 15.4225 5.61743 16.1977 7.73143 16.2264C9.39296 16.2057 10.8755 15.7512 12.1403 14.8746C12.5933 15.3911 13.0439 15.9216 13.481 16.4363C13.6661 16.6536 13.5898 16.6551 13.7753 16.8725C14.1106 17.2645 14.4459 17.6571 14.7807 18.0496C15.116 18.4421 15.4508 18.8342 15.7866 19.2267C15.9331 19.3981 16.166 19.5 16.3809 19.5C16.4446 19.5 16.5069 19.4911 16.565 19.4723C16.7417 19.415 16.9598 19.269 16.9961 18.8957C17.0277 18.5731 16.8608 18.3318 16.7115 18.1571ZM3.30859 13.0274C1.96095 11.7141 1.29968 10.0885 1.34386 8.19582C1.38385 6.45148 2.03535 4.94191 3.28022 3.70796C4.4851 2.51439 5.94527 1.89084 7.6161 1.85515C9.61106 1.87675 11.1996 2.54444 12.4719 3.8953C13.5945 5.08747 14.1841 6.51017 14.2246 8.12398C14.2822 10.4496 13.2782 11.9921 12.4258 12.8771C11.3316 14.0125 9.83101 14.7309 8.30899 14.8483C6.38379 14.9966 4.70087 14.3839 3.30859 13.0274Z"
        fill="#6E7682"
      />
    </svg>
  )
}
