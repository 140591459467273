import { TheIconOlympeLogoWithText } from '../components/icons/TheIconOlympeLogoWithText.tsx'
import { useAppDispatch } from '../store/hooks.ts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from '../ROUTE_PATHS.ts'
import { KeyboardEvent, useEffect, useState } from 'react'
import { sendEmailVerification } from '../store/auth/useCases/sendEmailVerification.ts'

export function AccountVerificationInfo() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    const email = searchParams.get('retry')
    if (!email) return
    if (email.includes('@')) {
      dispatch(sendEmailVerification(email))
    } else {
      navigate(ROUTE_PATHS.login)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const resendEmail = async () => {
    const email = searchParams.get('email')
    if (email?.includes('@')) {
      const { sent } = await dispatch(sendEmailVerification(email)).unwrap()
      if (sent) setIsEmailSent(true)
    } else {
      navigate(ROUTE_PATHS.login)
    }
  }

  const renderSpan = (text: string) => {
    return <span className={'text-sm leading-6 text-label'}>{text}</span>
  }

  const handleKeyPress = async (e: KeyboardEvent<HTMLParagraphElement>) => {
    if (e.key !== 'Enter') return
    await resendEmail()
  }

  const renderLabel = () => {
    if (!isEmailSent) {
      return (
        <p
          tabIndex={1}
          onKeyUp={handleKeyPress}
          onClick={resendEmail}
          className={'text-sm font-bold text-label hover:cursor-pointer hover:text-primary'}
        >
          👉 Renvoyez-moi l’email.
        </p>
      )
    } else {
      return <p className={'text-sm font-bold text-label hover:cursor-pointer hover:text-primary'}>Email renvoyé ✅</p>
    }
  }

  return (
    <div className={'flex w-11/12 flex-col items-center gap-12 text-center sm:w-2/3'}>
      <TheIconOlympeLogoWithText />
      <div className={'flex flex-col gap-4 lg:w-2/3'}>
        <h1 className={'text-xl font-semibold text-primary sm:text-3xl'}>Vérifiez votre adresse e-mail.</h1>

        <span className={'text-base text-primary'}>
          📬 Cher utilisateur, nous avons envoyé un email de vérification à {"l'adresse"} que vous avez fournie lors de
          votre inscription.
        </span>
      </div>
      <div className={'mt-10 flex flex-col items-center justify-center text-center'}>
        {renderSpan('Vous n’avez rien reçu ?')}
        {renderLabel()}
        {renderSpan('ou consultez vos spams.')}
      </div>
    </div>
  )
}
