import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { ListSourceCollectionsError, ListSourceCollectionsResponse } from '../../../types/sourceCollection.ts'

export const listSourceCollections = createAppAsyncThunk('conversations/getSourceCollections', async () => {
  const request = new Request(`${import.meta.env.VITE_OLYMPE_GPT_API_URL}/v1/api/source-collections`, {
    method: 'GET',
    credentials: 'include',
  })
  const response = await fetch(request)
  if (!response.ok) {
    const error: ListSourceCollectionsError = await response.json()
    throw new Error(error.message)
  }
  const responseBody: ListSourceCollectionsResponse = await response.json()
  return responseBody.data
})
