import { OlympeGptApiCaller } from './interfaces/olympeGptApiCaller.ts'
import { MessageRepository } from './interfaces/messageRepository.ts'
import {
  ListConversationMessagesError,
  ListConversationMessagesResponseData,
  Message,
  PostMessageInConversationBodyData,
  UpdateMessageByIdError,
} from '../types/message.ts'
import { EndpointApi, FetchResponse } from '../types/shared.ts'
import { Conversation } from '../types/conversation.ts'

export class FetchMessageRepository implements MessageRepository {
  private apiCaller: OlympeGptApiCaller
  private endpoint: EndpointApi = '/conversations'

  constructor() {
    this.apiCaller = new OlympeGptApiCaller()
  }

  updateMessageById(
    conversationId: Conversation['id'],
    message: Message,
  ): FetchResponse<UpdateMessageByIdError, Message> {
    const endpoint = `${this.endpoint}/${conversationId}/messages/${message.id}`
    const body: PostMessageInConversationBodyData = {
      data: {
        ...message,
      },
    }

    return this.apiCaller.fetchProtected(
      endpoint,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      },
      'application/json',
    )
  }

  async streamResponseMessage(
    conversationId: Conversation['id'],
    content: Message['content'],
    sourceCollections: Message['sourceCollections'],
  ) {
    const endpoint = `${this.endpoint}/${conversationId}/messages`
    const body: PostMessageInConversationBodyData = {
      data: {
        content,
        sourceCollections,
      },
    }
    return this.apiCaller.fetchStreamable(
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      'application/json',
    )
  }

  async listConversationMessages(conversationId: Conversation['id']) {
    const endpoint = `${this.endpoint}/${conversationId}/messages`
    return this.apiCaller.fetchProtected<ListConversationMessagesError, ListConversationMessagesResponseData>(endpoint)
  }
}
