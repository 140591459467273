import { TheIconClosingCross } from '../../icons/TheIconClosingCross.tsx'
import { TheEllipseStepper } from '../../TheEllipseStepper.tsx'
import { useOnboardingCard } from './use-onboardingCard.hook.tsx'

export function OnboardingStepThree() {
  const { handleNextStep, steps, handleQuitOnboarding } = useOnboardingCard()
  return (
    <div
      className={
        'flex h-[39.25rem] w-[32.875rem] flex-col items-end gap-1 rounded-2xl bg-mistySkyGray pb-7 pl-5 pr-3 pt-2 shadow-deepGrayShadow'
      }
    >
      <button className={'flex flex-col items-start gap-2.5 p-1'} onClick={handleQuitOnboarding}>
        <TheIconClosingCross width={'10'} height={'10'} />
      </button>
      {/* 1372 */}
      <div className={'flex flex-1 flex-col items-center gap-5 self-stretch'}>
        {/* 1379 */}
        <div className={'flex flex-1 flex-col items-center gap-12 self-stretch pr-2'}>
          <img
            src={'/assets/images/onboarding/onboarding_3.gif'}
            alt={'Illustration 3'}
            className={'h-[17.5rem] w-[30.15956rem] rounded-[0.73438rem] border-[0.979px] border-solid border-iceberg'}
          />
          {/* 1374 */}
          <div className={'flex flex-1 flex-col items-center gap-12 self-stretch'}>
            {/* 1375 */}
            <div className={'flex flex-col items-center gap-9 self-stretch'}>
              {/* 1284 */}
              <div className={'flex flex-col items-center justify-center gap-3 self-stretch px-[3.25rem] py-0'}>
                {/* 1281 */}
                <div
                  className={'flex items-center justify-center gap-2.5 text-center text-2xl font-semibold leading-8'}
                >
                  Vous pouvez reposer la question ?
                </div>
                {/* 1282 */}
                <div
                  className={
                    'flex flex-col items-center justify-center gap-2 self-stretch text-center text-base leading-6'
                  }
                >
                  Si la réponse n’est pas adaptée, vous pourrez reformuler la question avec des mots-clés
                  supplémentaires.
                </div>
              </div>
              <button
                className={
                  'flex items-center justify-center gap-3 rounded-lg bg-primary px-6 py-3 text-sm leading-5 text-silver'
                }
                onClick={handleNextStep}
              >
                {'Suivant'}
              </button>
            </div>
          </div>
        </div>
        {/* 1371 */}
        <div className={'flex flex-col items-center gap-7'}>
          <TheEllipseStepper steps={steps} />
        </div>
      </div>
    </div>
  )
}
