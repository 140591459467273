interface ValidationErrors {
  [key: string]: string
}

// Error message constants
export const emailErrors = {
  emptyEmail: 'Veuillez renseigner votre email',
  invalidFormat: 'Format d’email invalide',
} as const satisfies ValidationErrors

export const firstNameErrors = {
  emptyFirstName: 'Veuillez renseigner votre prénom',
  // satisfies ValidationErrors
} as const satisfies ValidationErrors

export const lastNameErrors = {
  emptyLastName: 'Veuillez renseigner votre nom',
} as const satisfies ValidationErrors

export const passwordDetailedErrors = {
  minLength: '12 caractères minimum',
  lowerCase: '1 minuscule',
  upperCase: '1 majuscule',
  specialCharacters: '1 caractère spécial',
  numbers: '1 chiffre',
} as const satisfies ValidationErrors

export const passwordSimpleErrors = {
  emptyPassword: 'Veuillez renseigner votre mot de passe',
} as const satisfies ValidationErrors

export const confirmPasswordErrors = {
  mismatch: 'Les mots de passe ne correspondent pas.',
} as const satisfies ValidationErrors

export const jobTitleErrors = {
  emptyJobTitle: 'Veuillez renseigner votre fonction',
} as const satisfies ValidationErrors

export const cguErrors = {
  notAccepted: 'Veuillez accepter les conditions générales d’utilisation',
} as const satisfies ValidationErrors

export const fileNameErrors = {
  emptyFileName: 'Veuillez saisir un nom',
} as const satisfies ValidationErrors

// Interfaces with error messages
export interface EmailErrors extends ValidationErrors {
  emptyEmail: (typeof emailErrors)['emptyEmail'] | ''
  invalidFormat: (typeof emailErrors)['invalidFormat'] | ''
}

export interface FirstNameErrors extends ValidationErrors {
  emptyFirstName: (typeof firstNameErrors)['emptyFirstName'] | ''
}

export interface LastNameErrors extends ValidationErrors {
  emptyLastName: (typeof lastNameErrors)['emptyLastName'] | ''
}

export interface PasswordDetailedErrors extends ValidationErrors {
  minLength: (typeof passwordDetailedErrors)['minLength'] | ''
  specialCharacters: (typeof passwordDetailedErrors)['specialCharacters'] | ''
  lowerCase: (typeof passwordDetailedErrors)['lowerCase'] | ''
  upperCase: (typeof passwordDetailedErrors)['upperCase'] | ''
  numbers: (typeof passwordDetailedErrors)['numbers'] | ''
}

export interface PasswordSimpleErrors extends ValidationErrors {
  emptyPassword: (typeof passwordSimpleErrors)['emptyPassword'] | ''
}

export interface ConfirmPasswordErrors extends ValidationErrors {
  mismatch: (typeof confirmPasswordErrors)['mismatch'] | ''
}

export interface JobTitleErrors extends ValidationErrors {
  emptyJobTitle: (typeof jobTitleErrors)['emptyJobTitle'] | ''
}
export interface CGUErrors extends ValidationErrors {
  notAccepted: typeof cguErrors.notAccepted | ''
}

export interface FileNameErrors extends ValidationErrors {
  emptyFileName: (typeof fileNameErrors)['emptyFileName'] | ''
}

// Validation functions
export const validateEmail = (email: string): EmailErrors => {
  const errors: EmailErrors = {
    emptyEmail: '',
    invalidFormat: '',
  }

  if (email.length === 0) {
    errors.emptyEmail = emailErrors.emptyEmail
  }
  if (email.length && !email.includes('@')) {
    errors.invalidFormat = emailErrors.invalidFormat
  }

  return errors
}

export const validateFirstName = (firstName: string): FirstNameErrors => {
  const errors: FirstNameErrors = {
    emptyFirstName: '',
  }

  if (firstName.length === 0) {
    errors.emptyFirstName = firstNameErrors.emptyFirstName
  }

  return errors
}

export const validateLastName = (lastName: string): LastNameErrors => {
  const errors: LastNameErrors = {
    emptyLastName: '',
  }

  if (lastName.length === 0) {
    errors.emptyLastName = lastNameErrors.emptyLastName
  }

  return errors
}

export const validatePasswordDetailed = (value: string): PasswordDetailedErrors => {
  const errors: PasswordDetailedErrors = {
    minLength: '',
    lowerCase: '',
    upperCase: '',
    specialCharacters: '',
    numbers: '',
  }

  const minLength = 12
  const hasUpperCase = /[A-Z]/.test(value)
  const hasLowerCase = /[a-z]/.test(value)
  const hasSpecialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
  const hasNumber = /\d/.test(value)

  if (value.length < minLength) {
    errors.minLength = passwordDetailedErrors.minLength
  }
  if (!hasUpperCase) {
    errors.upperCase = passwordDetailedErrors.upperCase
  }
  if (!hasLowerCase) {
    errors.lowerCase = passwordDetailedErrors.lowerCase
  }
  if (!hasSpecialCharacters) {
    errors.specialCharacters = passwordDetailedErrors.specialCharacters
  }
  if (!hasNumber) {
    errors.numbers = passwordDetailedErrors.numbers
  }

  return errors
}

export const validatePasswordSimple = (password: string): PasswordSimpleErrors => {
  const errors: PasswordSimpleErrors = {
    emptyPassword: '',
  }

  if (password.length === 0) {
    errors.emptyPassword = passwordSimpleErrors.emptyPassword
  }

  return errors
}

export const validatePasswordConfirmation = (password: string, passwordConfirmation: string): ConfirmPasswordErrors => {
  const errors: ConfirmPasswordErrors = {
    mismatch: '',
  }

  if (password !== passwordConfirmation) {
    errors.mismatch = confirmPasswordErrors.mismatch
  }

  return errors
}

export const validateJobTitle = (jobTitle: string): JobTitleErrors => {
  const errors: JobTitleErrors = {
    emptyJobTitle: '',
  }

  if (jobTitle.length === 0) {
    errors.emptyJobTitle = jobTitleErrors.emptyJobTitle
  }

  return errors
}

export const validateCGU = (cgu: boolean): CGUErrors => {
  const errors: CGUErrors = {
    notAccepted: '',
  }
  if (!cgu) {
    errors.notAccepted = cguErrors.notAccepted
  }
  return errors
}

export const validateFileName = (fileName: string): FileNameErrors => {
  const errors: FileNameErrors = {
    emptyFileName: '',
  }

  if (fileName.length === 0) {
    errors.emptyFileName = fileNameErrors.emptyFileName
  }

  return errors
}

/**
 * @returns true if any of the errors passed as arguments has any errors
 * @param errors - an object of errors to check
 * @example
 * hasErrors(validateEmail(email))
 */
export function hasErrors<T extends ValidationErrors>(errors: T): boolean {
  return Object.values(errors).some((error) => error !== '')
}

/**
 * @Returns true if any of the errors passed as arguments has any errors
 * @param errors - an array of errors to check
 * @example
 * hasAnyErrors(validateEmail(email), validatePassword(password))
 * @example
 * hasAnyErrors(validateEmail(email), validatePassword(password), validateFirstName(firstName))
 */
export function hasAnyErrors(...errors: ValidationErrors[]): boolean {
  return errors.some((error) => hasErrors(error))
}

/**
 * @Returns an array of error messages from the errors passed as arguments
 * @param errors - an object of errors to get the messages from
 * @example
 * getErrorMessages(validatePassword(password))
 */
export function getErrorMessages<T extends ValidationErrors>(errors: T): string[] {
  return Object.values(errors).filter((error) => error !== '')
}
