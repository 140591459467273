import { ProductTourGateway } from './interface/productTour.gateway.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'
import { UpdateCurrentStepResponse, UpdateCurrentStepResponseData } from '../types/productTour.ts'

export class WretchProductTourGateway implements ProductTourGateway {
  private readonly endpoint = '/onboarding'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async updateCurrentStep(step: UpdateCurrentStepResponse['data']['step']): Promise<UpdateCurrentStepResponseData> {
    const payload = {
      data: {
        step,
      },
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/current-step`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).put(payload).json<UpdateCurrentStepResponse>()
    return response.data
  }
}
