import { UploadedFile } from '../../../types/file.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { Tag } from '../../../types/tag.ts'

export const uploadFile = createAppAsyncThunk(
  'files/uploadFile',
  async (
    { file, title, tags }: { file: File; title: UploadedFile['title']; tags: Tag['id'][] },
    { extra: { fileGateway } },
  ) => {
    try {
      const response = await fileGateway.uploadFile(file, title, tags)
      const titleParts = response.title.split('.')
      const extension = titleParts.length > 1 ? `.${titleParts[titleParts.length - 1].toUpperCase()}` : ''
      return {
        ...response,
        extension,
      }
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
