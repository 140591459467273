import { TheIconClosingCross } from '../../icons/TheIconClosingCross.tsx'
import { TheEllipseStepper } from '../../TheEllipseStepper.tsx'
import { useOnboardingCard } from './use-onboardingCard.hook.tsx'

export function OnboardingStepFour() {
  const { handleQuitOnboarding, steps } = useOnboardingCard()
  return (
    <div
      className={
        'flex h-[39.25rem] w-[32.875rem] flex-col items-end gap-4 rounded-xl bg-mistySkyGray pb-7 pl-5 pr-3 pt-2 shadow-deepGrayShadow'
      }
    >
      <button className={'flex flex-col items-start gap-2.5 p-1'} onClick={handleQuitOnboarding}>
        <TheIconClosingCross width={'10'} height={'10'} />
      </button>
      {/* 1385 */}
      <div className={'flex flex-1 flex-col items-center gap-2 self-stretch'}>
        {/* 1314 */}
        <div className={'flex flex-1 flex-col items-center justify-center gap-12 self-stretch pr-2'}>
          {/* 1377 */}
          <div className={'flex flex-1 flex-col items-center gap-12 self-stretch'}>
            <img
              src={'/assets/images/onboarding/onboarding_4.gif'}
              alt={'Illustration 4'}
              className={'h-[17rem] self-stretch rounded-lg border border-solid border-iceberg'}
            />
            {/* 1376 */}
            <div className={'flex flex-col items-center justify-center gap-8 self-stretch'}>
              {/* 1284 */}
              <div className={'flex flex-col items-center justify-center gap-3 self-stretch px-12 py-0'}>
                {/* 1281 */}
                <div
                  className={'flex items-center justify-center gap-2.5 text-center text-2xl font-semibold leading-8'}
                >
                  Vous êtes prêts à utiliser Olympe !
                </div>
                {/* 1282 */}
                <div
                  className={
                    'flex flex-col items-center justify-center gap-2.5 self-stretch px-10 py-0 text-center text-base leading-6 text-label'
                  }
                >
                  <p>
                    {"Si vous avez la moindre question, n'hésitez pas à contacter notre équipe à"}
                    <br />
                    <a href={'mailto:support@olympe.legal'} className={'underline'}>
                      support@olympe.legal
                    </a>
                  </p>
                </div>
              </div>
              <button
                className={
                  'flex items-center justify-center gap-3 rounded-lg bg-primary px-6 py-3 text-sm leading-5 text-silver'
                }
                onClick={handleQuitOnboarding}
              >
                {'Terminer'}
              </button>
            </div>
          </div>
        </div>
        {/* 1315 */}
        <div className={'flex flex-col items-center justify-center gap-7'}>
          <TheEllipseStepper steps={steps} />
        </div>
      </div>
    </div>
  )
}
