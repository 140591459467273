import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { TheIconPlus } from '../icons/TheIconPlus.tsx'
import { TheIconDocument } from '../icons/TheIconDocument.tsx'
import { TheIconOlympeLogoWithText } from '../icons/TheIconOlympeLogoWithText.tsx'
import { useSidebarHook } from './use-sidebar.hook.ts'
import { PRODUCT_TOUR_USER_FILES } from '../../utils/CONVERSATION_CONSTANTS.ts'
import { useConversationPageHook } from '../../routes/ConversationPage/use-conversation-page.hook.ts'
import { ProductTourWithOverlay } from '../ProductTourWithOverlay.tsx'

export function TheSidebarDesktopHeader() {
  const { handleClick, activeStyle, targetRef, messages } = useSidebarHook()
  const { showProductTour, step } = useConversationPageHook()

  return (
    <div
      className={
        'border-b-solid flex w-full flex-col items-center gap-4 border-b border-b-label pb-4 hover:cursor-pointer'
      }
    >
      <TheIconOlympeLogoWithText className={'fill-bright'} />

      <div className="flex w-full flex-col items-center gap-3">
        <button
          className="flex w-full items-center gap-3 rounded-lg bg-label px-3 py-2"
          onKeyDown={handleClick}
          onClick={handleClick}
        >
          <TheIconPlus className={'h-3 w-3'} />
          <span className="text-sm text-bright" data-testid="create-conversation-button">
            Nouvelle question
          </span>
        </button>
        <div className={'w-full'} ref={targetRef}>
          <Link
            className={`flex w-full items-center gap-3 px-3 py-2 hover:rounded-lg hover:bg-payneGrey ${activeStyle}`}
            to={ROUTE_PATHS.documents}
          >
            <TheIconDocument className={'h-[0.79813rem] w-[0.71831rem] fill-bright'} />
            <span className="text-sm text-bright">Mes documents</span>
          </Link>
        </div>
      </div>

      <ProductTourWithOverlay condition={showProductTour && step === PRODUCT_TOUR_USER_FILES && !messages.length} />
    </div>
  )
}
