import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function ArrowIconTop(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 54 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.7949 33.7549L27.0338 2.9918L9.27277 33.7549L44.7949 33.7549Z" fill="#212121" />
      <defs>
        <clipPath id="clip0_2629_10373">
          <rect width="54" height="18" fill="white" transform="matrix(-1 0 0 -1 54 18)" />
        </clipPath>
      </defs>
    </svg>
  )
}
