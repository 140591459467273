import { EmailValidationConfirmation } from '../components/EmailValidationConfirmation.tsx'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks.ts'
import { verifyEmail } from '../store/auth/useCases/verifyEmail.ts'
import { selectCurrentUser, selectTokenStatus } from '../store/auth/authSlice.ts'
import { EmailError } from '../components/EmailError.tsx'

export function EmailVerification() {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const tokenStatus = useAppSelector(selectTokenStatus)
  const currentUser = useAppSelector(selectCurrentUser)

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      dispatch(verifyEmail(token))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderConfirmedOrError = () => {
    return tokenStatus === 'validated' ? (
      <EmailValidationConfirmation />
    ) : (
      <EmailError tokenIssue={tokenStatus} email={currentUser?.email || null} />
    )
  }
  return <>{renderConfirmedOrError()}</>
}
