import { AppDispatch } from '../../store/configureStore.ts'
import { deleteFileById } from '../../store/files/useCases/deleteFileById.ts'
import { FileData, FileDataWithExtraColumn, UploadedFile } from '../../types/file.ts'
import React, { ChangeEvent, FormEvent, ReactElement, RefObject } from 'react'
import { FileNameErrors, validateFileName } from '../../utils/formValidation.ts'
import { TableData } from '../../components/atomics/BaseTable.tsx'
import { uploadFile } from '../../store/files/useCases/uploadFile.ts'
import { Tag } from '../../types/tag.ts'
import { applyTags } from '../../store/tags/useCases/applyTags.ts'
import { updateFile } from '../../store/files/useCases/updateFile.ts'
import { deleteAllUserFiles } from '../../store/files/useCases/deleteAllUserFiles.ts'
import { deleteManyFiles } from '../../store/files/useCases/deleteManyFiles.ts'
import { FilterKey } from '../../components/TheMobileFileFilterPanel.tsx'

export type ExpendablesSections = {
  isSortActionsSectionOpened: boolean
  isMoveActionsSectionOpened: boolean
}

export const fileNameErrorsInitialState: FileNameErrors = { emptyFileName: '' }

export const expendablesSectionsInitial: ExpendablesSections = {
  isSortActionsSectionOpened: false,
  isMoveActionsSectionOpened: false,
}

export type DocumentsViewModel = {
  deleteFileByRowIndex: (rowIndex: number) => void
  handleInputChange: (e: FormEvent<HTMLInputElement>) => void
  deleteFileByFileId: (file: FileData & { size: string }) => void
  toggleRowModal: () => void
  handleSetSelectedItem: (item: FileData) => void
  handleFileInputChange: (e: FormEvent<HTMLInputElement>) => Promise<void>
  handleSelectAll: (filesFromTable: FileData[]) => void
  toggleRenameFileModal: () => void
  toggleBulkModal: () => void
  toggleRowSelection: (item: FileData) => void
  handleApplyTags: (tag: Tag, files: FileData[]) => Promise<void>
  handleFilePreview: (file: FileData) => Promise<void>
  filterFiles: (e: ChangeEvent<HTMLInputElement>) => void
  handleUpdateDocument: (value: string, document: FileDataWithExtraColumn | null) => void
  handleDeleteDocuments: () => void
  handleClearSearchValue: () => void
  handleDeleteAllDocumentsOnMobile: () => void
  toggleSingleFileActionsModal: (file: FileData) => void
  setIsActive: (e: React.MouseEvent<HTMLButtonElement>) => void
  toggleAction: (action: keyof typeof expendablesSectionsInitial) => void
  handleCancel: () => void
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void
  getFontStyleBasedOnCurrentRef: (ref: RefObject<HTMLButtonElement>) => string | undefined
}

export const createDocumentsViewModel =
  ({
    dispatch,
    files,
    setFileNameErrors,
    inputValue,
    setInputValue,
    selectedRows,
    setSelectedRows,
    isRowModalVisible,
    setIsRowModalVisible,
    expendablesSectionsInitial,
    expendablesSections,
    setExpendablesSections,
    selectedItem,
    setSelectedItem,
    file,
    setFile,
    setDisplayLoading,
    currentTag,
    isRenameTagModalVisible,
    setIsRenameTagModalVisible,
    fileNameErrorsInitialState,
    isBulkModalVisible,
    setIsBulkModalVisible,
    setPdfData,
    previewFile,
    setPreviewFile,
    setSearchValue,
    setEmptyArrayMessage,
    setFilteredData,
    filesByTag,
    filterKey,
    setFilterKey,
    showToast,
  }: {
    dispatch: AppDispatch
    files: FileData[]
    fileNameErrorsInitialState: FileNameErrors
    setFileNameErrors: (FileNameError: FileNameErrors) => void
    inputValue: string
    setInputValue: (value: string) => void
    selectedRows: TableData<UploadedFile>
    setSelectedRows: (data: TableData<UploadedFile>) => void
    isRowModalVisible: boolean
    setIsRowModalVisible: (value: boolean) => void
    expendablesSectionsInitial: ExpendablesSections
    expendablesSections: ExpendablesSections
    setExpendablesSections: (value: ExpendablesSections) => void
    selectedItem: FileData | null
    setSelectedItem: (file: FileData | null) => void
    file: FileData
    setFile: (file: FileData & { size: string }) => void
    setDisplayLoading: (value: boolean) => void
    currentTag: Tag & { isTagActive: boolean }
    isRenameTagModalVisible: boolean
    setIsRenameTagModalVisible: (value: boolean) => void
    isBulkModalVisible: boolean
    setIsBulkModalVisible: (value: boolean) => void
    setPdfData: (value: string) => void
    previewFile: boolean
    setPreviewFile: (value: boolean) => void
    setSearchValue: (value: string) => void
    setEmptyArrayMessage: (value: string) => void
    setFilteredData: (data: TableData<FileDataWithExtraColumn>) => void
    filesByTag: FileData[]
    filterKey: FilterKey
    setFilterKey: (value: FilterKey) => void
    showToast: (message: ReactElement | string) => void
  }) =>
  (): DocumentsViewModel => {
    const handleApplyTags = async (tag: Tag, files: FileData[]) => {
      const messages = {
        singleAlreadyInFolder: 'Le document est déjà dans le dossier.',
        multipleAlreadyInFolder: 'Les documents sont déjà dans le dossier.',
        singleMoved: (tag: Tag) => (
          <>
            Votre document a été déplacé vers le dossier <span className={'font-semibold'}>{tag.name}.</span>
          </>
        ),
        multipleMoved: (count: number, tag: Tag) => (
          <>
            Vos {count} documents ont été déplacés vers le dossier <span className={'font-semibold'}>{tag.name}.</span>
          </>
        ),
        error: "Une erreur s'est produite. Veuillez réessayer.",
      }

      const { filesMoved, alreadyInFolder } = files.reduce<{
        filesMoved: ReturnType<typeof dispatch>[]
        alreadyInFolder: FileData['id'][]
      }>(
        (acc, file) => {
          const tagsToAdd: Tag['id'][] = [tag.id]
          const tagsToRemove: Tag['id'][] = file.tags[0] ? [file.tags[0]] : []

          if (
            tagsToAdd.every((element) => tagsToRemove.includes(element)) &&
            tagsToRemove.every((element) => tagsToAdd.includes(element))
          ) {
            acc.alreadyInFolder.push(file.id)
          } else {
            acc.filesMoved.push(dispatch(applyTags({ fileId: file.id, tagsToAdd, tagsToRemove })))
          }

          return acc
        },
        { filesMoved: [], alreadyInFolder: [] },
      )

      if (alreadyInFolder.length > 0) {
        const message = alreadyInFolder.length === 1 ? messages.singleAlreadyInFolder : messages.multipleAlreadyInFolder
        showToast(message)
      }

      if (filesMoved.length > 0) {
        try {
          await Promise.all(filesMoved)
          const movedMessage =
            filesMoved.length === 1 ? messages.singleMoved(tag) : messages.multipleMoved(filesMoved.length, tag)
          showToast(movedMessage)
        } catch (error) {
          showToast(<span className={'font-semibold text-red-500'}>{messages.error}</span>)
        }
      }

      setSelectedItem(null)
      setExpendablesSections({
        isSortActionsSectionOpened: false,
        isMoveActionsSectionOpened: false,
      })
      setSelectedRows([])
    }

    const handleFileInputChange = async (e: FormEvent<HTMLInputElement>) => {
      const inputElement = e.target as HTMLInputElement
      if (inputElement.files && inputElement.files.length > 0) {
        const file = inputElement.files[0]
        const titleParts = file.name.split('.')
        const extension = titleParts.length > 1 ? `.${titleParts[titleParts.length - 1].toUpperCase()}` : ''
        setFile({
          createdAt: '',
          extension,
          id: '',
          tags: [],
          title: file.name,
          size: file.size.toString(),
        })
        await upLoadFile(file)
      }
    }

    const upLoadFile = async (file: File) => {
      setDisplayLoading(true)
      const tags = currentTag?.id === 0 ? [] : [currentTag?.id]
      const uploadFileResponse = await dispatch(uploadFile({ file, title: file.name, tags }))
      setTimeout(() => {
        setDisplayLoading(false)
      }, 1000)
      if (uploadFile.fulfilled.match(uploadFileResponse)) {
        showToast(
          <>
            Votre document a été ajouté au dossier <span className={'font-semibold'}>{currentTag.name}</span>.
          </>,
        )
      }
    }

    const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
      setInputValue(e.currentTarget.value)
      setFileNameErrors(validateFileName(e.currentTarget.value))
    }

    const handleSetSelectedItem = (item: FileData) => {
      selectedItem === null || item.id !== selectedItem.id ? setSelectedItem(item) : setSelectedItem(null)
    }

    const deleteFileByRowIndex = (rowIndex: number) => {
      const file = files[rowIndex]
      dispatch(deleteFileById(file.id))
    }

    const deleteFileByFileId = (file: FileData & { size: string }) => {
      setSelectedRows(selectedRows.filter((row: { id: string }) => row.id !== file.id))
      dispatch(deleteFileById(file.id))
    }

    const toggleRowSelection = (item: FileData) => {
      if (selectedRows.includes(item)) {
        setSelectedRows(selectedRows.filter((row) => row !== item))
      } else {
        setSelectedRows([...selectedRows, item])
      }
    }

    const toggleRowModal = () => {
      setIsRowModalVisible(!isRowModalVisible)
      setExpendablesSections(expendablesSectionsInitial)
    }

    const toggleSingleFileActionsModal = (file: FileData) => {
      toggleRowModal()
      setFile({ ...file, size: '' })
    }

    const toggleRenameFileModal = () => {
      setIsRenameTagModalVisible(!isRenameTagModalVisible)
      setFileNameErrors(fileNameErrorsInitialState)
    }

    const toggleBulkModal = () => {
      setIsBulkModalVisible(!isBulkModalVisible)
      setExpendablesSections(expendablesSectionsInitial)
    }

    const handleSelectAll = (filesFromTable: FileData[]) => {
      if (selectedRows.length === filesFromTable.length) {
        setSelectedRows([])
      } else {
        setSelectedRows(filesFromTable)
      }
    }

    const handleFilePreview = async (file: FileData) => {
      const response = await fetch(`${import.meta.env.VITE_OLYMPE_GPT_API_URL}/v1/api/files/${file.id}`, {
        credentials: 'include',
      })
      const newBlob = await response.blob()
      const pdfData = URL.createObjectURL(newBlob)
      setPdfData(pdfData)
      setPreviewFile(!previewFile)
    }

    const filterDataByInputValue = (data: typeof files, inputValue: string) => {
      return data.filter((item) => {
        const itemWithoutId: Partial<typeof item> = { ...item }
        delete itemWithoutId.id
        const isInputValueIncluded = Object.values(itemWithoutId).some((el) =>
          String(el).toLowerCase().includes(inputValue),
        )
        return isInputValueIncluded || null
      })
    }

    const filterFiles = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value)
      const inputValue = e.target.value.toLowerCase()
      const filteredDataFromAllFiles = filterDataByInputValue(files, inputValue)
      const filteredDataFromFilesFilteredByTagId = filterDataByInputValue(filesByTag, inputValue)

      setEmptyArrayMessage('Nous n’avons trouvé aucun document correspondant à votre recherche.')
      currentTag.id === 0
        ? setFilteredData(filteredDataFromAllFiles as TableData<FileDataWithExtraColumn>)
        : setFilteredData(filteredDataFromFilesFilteredByTagId as TableData<FileDataWithExtraColumn>)
    }

    const handleUpdateDocument = (value: string, document: FileDataWithExtraColumn | null) => {
      if (!document) return
      const extension = document.title.split('.')[1]
      const newTitleWithExtension = `${value}.${extension}`
      dispatch(updateFile({ id: document.id, title: newTitleWithExtension }))
    }

    const handleDeleteDocuments = () => {
      const ids = selectedRows.map((file) => {
        return file.id
      })
      selectedRows.length === files.length ? dispatch(deleteAllUserFiles()) : dispatch(deleteManyFiles(ids))
      setSelectedRows([])
    }

    const handleClearSearchValue = () => {
      setSearchValue('')
      currentTag.id === 0
        ? setFilteredData(files as TableData<FileDataWithExtraColumn>)
        : setFilteredData(filesByTag as TableData<FileDataWithExtraColumn>)
    }

    const handleDeleteAllDocumentsOnMobile = () => {
      toggleBulkModal()
      dispatch(deleteAllUserFiles())
    }

    const toggleAction = (action: keyof typeof expendablesSections) => {
      setExpendablesSections({
        ...expendablesSections,
        [action]: !expendablesSections[action],
      })
    }

    const setIsActive = (e: React.MouseEvent<HTMLButtonElement>) => {
      const target = e.target as HTMLLIElement

      setFilterKey(target.id as FilterKey)
      toggleBulkModal()
    }

    const handleCancel = () => {
      toggleRenameFileModal()
      setInputValue('')
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setFileNameErrors(validateFileName(inputValue))
      if (inputValue.length === 0) return
      handleUpdateDocument(inputValue, file as unknown as FileDataWithExtraColumn)
      toggleRenameFileModal()
      setInputValue('')
    }

    const getFontStyleBasedOnCurrentRef = (ref: RefObject<HTMLButtonElement>) => {
      if (!ref) {
        return
      }
      return ref?.current?.id === filterKey ? 'font-semibold text-primary' : 'font-normal text-label'
    }

    return {
      deleteFileByRowIndex,
      handleInputChange,
      deleteFileByFileId,
      toggleRowModal,
      handleSetSelectedItem,
      handleFileInputChange,
      handleSelectAll,
      toggleRenameFileModal,
      toggleBulkModal,
      toggleRowSelection,
      handleApplyTags,
      handleFilePreview,
      filterFiles,
      handleUpdateDocument,
      handleDeleteDocuments,
      handleClearSearchValue,
      handleDeleteAllDocumentsOnMobile,
      toggleSingleFileActionsModal,
      setIsActive,
      toggleAction,
      handleCancel,
      handleSubmit,
      getFontStyleBasedOnCurrentRef,
    }
  }
