import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconCross(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 10 10" fill="none">
      <path d="M0.753774 0.00601461C1.33384 0.150371 1.64494 0.707748 2.06644 1.11275C3.00176 2.01297 3.91702 2.93324 4.81822 3.86755C5.04302 4.10213 5.11528 3.90765 5.22567 3.79738C6.32759 2.69866 7.42148 1.59394 8.52741 0.499233C8.78432 0.244604 9.02718 -0.0822031 9.47076 0.0421038C9.74574 0.118292 9.92237 0.298738 9.97254 0.585445C10.0609 1.08267 9.6514 1.30322 9.38245 1.57589C8.2725 2.70067 7.14248 3.80339 6.0225 4.91814C6.00042 4.9402 6.00444 5.0224 6.02652 5.04646C7.27295 6.29554 8.52941 7.53661 9.76982 8.79371C10.0508 9.07841 10.089 9.45133 9.80394 9.75208C9.48481 10.0869 9.09944 10.0528 8.78231 9.73804C7.52986 8.49898 6.28945 7.24589 5.04503 5.9968C4.92059 5.87049 4.92059 6.03891 4.88446 6.07299C3.64405 7.30403 2.41367 8.54309 1.17126 9.77012C0.870188 10.0669 0.504889 10.0689 0.211848 9.76611C-0.0952438 9.44732 -0.0551011 9.07641 0.245969 8.76965C1.44824 7.55465 2.65453 6.34366 3.87487 5.14671C4.04347 4.9803 3.98325 4.93418 3.86082 4.81188C2.68263 3.645 1.51046 2.4721 0.33629 1.30121C0.0934266 1.05861 -0.0872156 0.799975 0.0653266 0.435074C0.18174 0.158391 0.400519 0.0340842 0.753774 0V0.00601461Z" />
    </svg>
  )
}
