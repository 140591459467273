import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../configureStore.ts'

export interface Step {
  isActive: boolean
  id: number
}

interface SteppersState {
  registerStepper: Step[]
  onboardingStepper: Step[]
}

const initialState: SteppersState = {
  registerStepper: [
    { isActive: true, id: 1 },
    { isActive: false, id: 2 },
    { isActive: false, id: 3 },
  ],
  onboardingStepper: [
    {
      id: 0,
      isActive: true,
    },
    {
      id: 1,
      isActive: false,
    },
    {
      id: 2,
      isActive: false,
    },
    {
      id: 3,
      isActive: false,
    },
    {
      id: 4,
      isActive: false,
    },
  ],
}

export const steppersSlice = createSlice({
  name: 'steppers',
  initialState,
  reducers: {
    setRegisterStepper: (state, action) => {
      const updatedSteps = [...state.registerStepper]

      // Set the current step to inactive
      updatedSteps[action.payload.currentStepIndex].isActive = false

      // Set the following step to active
      updatedSteps[action.payload.stepToSetActive].isActive = true
      state.registerStepper = updatedSteps
    },
    resetRegisterStepper: (state) => {
      state.registerStepper = initialState.registerStepper
    },
    resetOnboardingStepper: (state) => {
      state.onboardingStepper = initialState.onboardingStepper
    },
    nextOnboardingStep: (state) => {
      const updatedSteps = [...state.onboardingStepper]
      const currentStepIndex = updatedSteps.findIndex((step) => step.isActive)
      if (currentStepIndex === -1) return
      updatedSteps[currentStepIndex].isActive = false
      const nextStep = currentStepIndex + 1
      const nextStepToSetActive = nextStep > updatedSteps.length - 1 ? 0 : nextStep
      updatedSteps[nextStepToSetActive].isActive = true
      state.onboardingStepper = updatedSteps
    },
  },
})

export const { setRegisterStepper, resetRegisterStepper, nextOnboardingStep } = steppersSlice.actions

export const selectRegisterStepper = (state: RootState) => state.entities.steppers.registerStepper
export const selectOnboardingStepper = (state: RootState) => state.entities.steppers.onboardingStepper
export const selectActiveOnboardingStep = (state: RootState) =>
  state.entities.steppers.onboardingStepper.find((step) => step.isActive) ?? initialState.onboardingStepper[0]
