import { useEffect, useRef, useState } from 'react'
import { BaseButton } from './atomics/BaseButton'
import { TheCalendlyModal } from './TheCalendlyModal'
import { TheIconCalendar } from './icons/TheIconCalendar.tsx'
import { TheIconRatingStar } from './icons/TheIconRatingStar.tsx'

type Props = {
  updateRating: (rating: number) => void
  rating: number
}

export function TheStarRating({ updateRating, ...props }: Readonly<Props>) {
  const [rating, setRating] = useState(props.rating)
  const calendlyCtaRef = useRef<HTMLDivElement>(null)
  const ratingStarsRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (calendlyCtaRef.current && rating < 3) {
      calendlyCtaRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    } else {
      ratingStarsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [rating])
  const handleRatingClick = (clickedRating: number) => {
    setRating(clickedRating)
    updateRating(clickedRating)
  }

  const toggleCalendlyStyle = () => {
    return rating < 3 && rating > 0 ? 'visible' : 'hidden'
  }

  return (
    <>
      <div
        ref={ratingStarsRef}
        className="mt-1 flex items-center justify-center gap-7 sm:justify-start sm:gap-2 sm:text-left"
      >
        {[1, 2, 3, 4, 5].map((star) => (
          <TheIconRatingStar
            key={star}
            onClick={() => handleRatingClick(star)}
            className="mr-2 w-6 cursor-pointer sm:w-3.5"
            fill={star <= rating ? '#212121' : '#E4E4E4'}
          />
        ))}
      </div>
      <div ref={calendlyCtaRef} className={`${toggleCalendlyStyle()} mt-5 flex flex-col gap-3 sm:pl-9`}>
        <p className={`mb-2 text-left text-center text-sm sm:text-left`}>
          Souhaitez-vous contacter nos spécialistes pour mieux répondre à votre question ?
        </p>
        <BaseButton
          onClick={() => setIsModalOpen(!isModalOpen)}
          label="Prendre RDV"
          color="quaternary"
          icon={<TheIconCalendar />}
          className="w-1/2 self-center rounded-3xl border border-primary px-1.5 py-0.5 text-sm font-light hover:text-sm hover:font-light sm:self-start sm:rounded-md"
        />
      </div>
      {isModalOpen ? <TheCalendlyModal closeModal={() => setIsModalOpen(!isModalOpen)} /> : ''}
    </>
  )
}
