import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

// adding props is needed since TheIconError will be used in various contexts,
// including the BaseButton context, which injects use Cases props programmatically
export function TheIconExclamationMark(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Calque_1">
        <path
          id="Vector"
          d="M11.9091 24C8.6806 23.9573 5.86049 22.7951 3.53125 20.4824C1.44816 18.4138 0.267805 15.8914 0.0412446 12.9696C-0.243354 9.29538 0.943989 6.11976 3.53055 3.4973C5.70105 1.29724 8.37153 0.137067 11.4469 0.0118886C14.7418 -0.12308 17.6584 0.909117 20.1037 3.14904C22.5497 5.38897 23.8636 8.19954 23.9895 11.4982C24.1182 14.8885 23.0084 17.8656 20.6198 20.3174C18.2514 22.7468 15.3599 23.9594 11.9091 24.0014V24ZM11.7336 1.66788C9.14911 1.72243 6.74855 2.67281 4.77454 4.60992C2.78794 6.55963 1.73485 8.9653 1.67052 11.7346C1.60059 14.7235 2.66277 17.3229 4.82069 19.4055C7.04573 21.5524 9.73998 22.5314 12.8342 22.2958C17.6304 21.93 22.4749 17.8753 22.3169 11.6227C22.2518 9.04362 21.2932 6.78342 19.5072 4.90574C17.4451 2.73714 14.8893 1.70145 11.7329 1.66718L11.7336 1.66788Z"
        />
        <path
          id="Vector_2"
          d="M11.1805 10.3136C11.1805 9.00516 11.1944 7.69673 11.1714 6.389C11.1644 6.00577 11.5315 5.62044 11.8329 5.60156C12.3867 5.5673 12.7307 5.79388 12.8006 6.25333C12.8286 6.43865 12.8524 6.62677 12.8524 6.81419C12.8559 9.22125 12.8545 11.629 12.8545 14.0361C12.8545 14.355 12.7936 14.6424 12.5251 14.8613C12.2727 15.0676 12.0007 15.1298 11.7007 15.0207C11.4014 14.9116 11.1812 14.5878 11.1805 14.2913C11.1798 12.9654 11.1805 11.6395 11.1798 10.3136H11.1805Z"
        />
        <path
          id="Vector_3"
          d="M11.9951 16.49C12.6335 16.4145 13.1272 17.0376 13.1202 17.576C13.1125 18.1775 12.6209 18.6698 12.0245 18.6915C11.3035 18.718 10.8756 18.1243 10.8763 17.5586C10.877 17.0229 11.3427 16.4103 11.9951 16.49Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_912_14357">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  )
}
