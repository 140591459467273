import { BaseSearchInput } from './atomics/BaseSearchInput.tsx'
import { ChangeEvent, FormEvent, useRef } from 'react'
import { TheIconTripleDots } from './icons/TheIconTripleDots.tsx'
import { BaseButton } from './atomics/BaseButton.tsx'
import { TheIconImportDocument } from './icons/TheIconImportDocument.tsx'

type Props = {
  searchValue: string
  filterData: (e: ChangeEvent<HTMLInputElement>) => void
  handleClearSearchValue: () => void
  handleDisplayModal?: () => void
  handleFileInputChange: ((event: FormEvent<HTMLInputElement>) => void) | undefined
  title: string
  responsiveStyle: string
}

export function DocumentSearchBar({
  searchValue,
  handleDisplayModal,
  filterData,
  handleClearSearchValue,
  title,
  responsiveStyle,
  handleFileInputChange,
}: Readonly<Props>) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    handleFileInputChange?.(e)
  }

  const handleClick = () => {
    inputRef.current?.click()
  }
  return (
    <div className={`${responsiveStyle}`}>
      <h3 className="flex w-3/4 items-center gap-4 text-xl font-semibold text-primary sm:text-base lg:px-3 lg:text-xl">
        {title}
        <TheIconTripleDots onClick={handleDisplayModal} className={'self-end sm:hidden'} />
      </h3>
      <div className={'flex items-center gap-4'}>
        <BaseSearchInput
          placeholder="Rechercher un document"
          onChange={filterData}
          onClear={handleClearSearchValue}
          value={searchValue}
        />
        <BaseButton
          onClick={handleClick}
          label={'Importer'}
          iconPosition={'left'}
          icon={<TheIconImportDocument className={'fill-bright'} />}
        />
        <input
          ref={inputRef}
          onChange={handleChange}
          id={'fileInputRef'}
          accept=".pdf"
          className="hidden"
          type="file"
        />
      </div>
    </div>
  )
}
