import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import { rootReducer } from './rootReducer.ts'
import { AuthGateway } from '../gateways/interface/auth.gateway.ts'
import { ConversationGateway } from '../gateways/interface/conversation.gateway.ts'
import { FileGateway } from '../gateways/interface/file.gateway.ts'
import { ProductTourGateway } from '../gateways/interface/productTour.gateway.ts'
import { TagGateway } from '../gateways/interface/tag.gateway.ts'

export type Dependencies = {
  authGateway: AuthGateway
  conversationGateway: ConversationGateway
  fileGateway: FileGateway
  productTourGateway: ProductTourGateway
  tagGateway: TagGateway
}

export const createAppStore = (
  dependencies: Dependencies,
  preloadedState?: Partial<ReturnType<typeof rootReducer>>,
) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies,
        },
        serializableCheck: {
          ignoredActions: ['conversations/uploadFileInConversation/fulfilled'],
        },
        preloadedState,
      }),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = ThunkDispatch<RootState, Dependencies, Action>
export type AppStore = ReturnType<typeof createAppStore>
