import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import { selectAllMessages } from '../../store/messages/messagesSlice.ts'
import { useLocation, useNavigate } from 'react-router-dom'
import { createConversation } from '../../store/conversations/use Cases/createConversation.ts'
import { useEffect, useRef } from 'react'
import { setTooltipPositions } from '../../store/productTour/productTourSlice.ts'
import { useSelector } from 'react-redux'
import { selectAllConversations } from '../../store/conversations/conversationsSlice.ts'

export const useSidebarHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const messages = useAppSelector(selectAllMessages)
  const conversations = useSelector(selectAllConversations)
  const targetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const targetRect = targetRef.current?.getBoundingClientRect()
    if (!targetRect) return
    const marginLeft = 50
    const top = targetRect.top / 2 + 10
    const left = targetRect.left + targetRect.width + marginLeft
    const position = {
      PRODUCT_TOUR_USER_FILES: {
        top,
        left,
      },
    }
    dispatch(setTooltipPositions(position))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  async function handleClick() {
    const conversation = await dispatch(createConversation()).unwrap()
    navigate(`/conversations/${conversation.id}`)
  }
  const setActiveStyle = () => {
    return location.pathname === '/documents' && 'bg-payneGrey rounded-lg'
  }

  return {
    messages,
    conversations,
    handleClick,
    targetRef,
    activeStyle: setActiveStyle(),
  }
}
