import { createSlice } from '@reduxjs/toolkit'
import { GetCurrentUserResponseData, VerifyEmailResponseData } from '../../types/user.ts'
import { logUser } from './useCases/logUser.ts'
import { RootState } from '../configureStore.ts'
import { createUser } from './useCases/createUser.ts'
import { refreshToken } from './useCases/refreshToken.ts'
import { getCurrentUser } from './useCases/getCurrentUser.ts'
import { logoutUser } from './useCases/logoutUser.ts'
import { verifyEmail } from './useCases/verifyEmail.ts'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null as GetCurrentUserResponseData | null,
    isLoggedIn: false,
    error: '',
    isLoading: false,
    tokenStatus: 'invalid' as 'invalid' | 'validated' | 'expired',
    currentUser: null as VerifyEmailResponseData['user'] | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logUser.fulfilled, (state) => {
      state.error = ''
      state.isLoggedIn = true
    })
    builder.addCase(logUser.rejected, (state, action) => {
      state.isLoggedIn = false
      state.error = action.error.message || ''
    })
    builder.addCase(logUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createUser.fulfilled, (state) => {
      state.error = ''
      state.isLoggedIn = true
    })
    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoggedIn = false
      state.error = action.error.message || ''
    })
    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(refreshToken.fulfilled, (state) => {
      state.error = ''
      state.isLoggedIn = true
    })
    builder.addCase(refreshToken.rejected, (state, action) => {
      state.isLoggedIn = false
      state.error = action.error.message || ''
    })
    builder.addCase(refreshToken.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.error = ''
      state.user = action.payload
    })
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.user = null
      state.error = action.error.message || ''
    })
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.isLoggedIn = false
      state.user = null
      window.location.href = ROUTE_PATHS.login
    })
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.error = action.error.message || ''
    })
    builder.addCase(logoutUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      if (action.payload.validated) {
        state.tokenStatus = 'validated'
      } else if (!action.payload.validated && action.payload.user) {
        state.tokenStatus = 'expired'
      } else {
        state.tokenStatus = 'invalid'
      }
      state.currentUser = action.payload.user
      state.isLoading = false
    })
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.error = action.error.message ?? ''
      state.isLoading = false
    })
    builder.addCase(verifyEmail.pending, (state) => {
      state.isLoading = true
    })
  },
})

export const selectAuthErrorMessage = (state: RootState) => state.entities.auth.error

export const selectUser = (state: RootState) => state.entities.auth.user

export const selectTokenStatus = (state: RootState) => state.entities.auth.tokenStatus

export const selectCurrentUser = (state: RootState) => state.entities.auth.currentUser

export const selectIsLoading = (state: RootState) => state.entities.auth.isLoading
