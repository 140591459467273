import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function TheIconPlus(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 7L1 7" stroke="#FDFDFD" strokeWidth="1.16667" strokeLinecap="round" />
      <path d="M7 1V13" stroke="#FDFDFD" strokeWidth="1.16667" strokeLinecap="round" />
    </svg>
  )
}
