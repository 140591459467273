import { createSlice } from '@reduxjs/toolkit'
import { UpdateCurrentStepResponse } from '../../types/productTour.ts'
import { RootState } from '../configureStore.ts'
import { updateCurrentStep } from './useCases/updateCurrentStep.ts'
import {
  BEGIN,
  END,
  PRODUCT_TOUR_FILE_COMPARISON,
  PRODUCT_TOUR_SINGLE_FILE,
  PRODUCT_TOUR_USER_FILES,
} from '../../utils/CONVERSATION_CONSTANTS.ts'
import { getCurrentUser } from '../auth/useCases/getCurrentUser.ts'

export const productTourSlice = createSlice({
  name: 'productTour',
  initialState: {
    error: '',
    showProductTour: false,
    currentStep: { step: BEGIN } as UpdateCurrentStepResponse['data'],
    isLoading: false,
    tooltipPositions: {
      BEGIN: {
        top: 0,
        left: 0,
      },
      ONBOARDING_TOUR: {
        top: 0,
        left: 0,
      },
      PRODUCT_TOUR_SINGLE_FILE: {
        top: 0,
        left: 0,
      },
      PRODUCT_TOUR_MESSAGE_ONLY: {
        top: 0,
        left: 0,
      },
      PRODUCT_TOUR_FILE_COMPARISON: {
        top: 0,
        left: 0,
      },
      PRODUCT_TOUR_USER_FILES: {
        top: 0,
        left: 0,
      },
      END: {
        title: '',
        top: 0,
        left: 0,
      },
    },
    arrowPositions: {
      PRODUCT_TOUR_SINGLE_FILE: {
        bottom: 0,
        left: 0,
      },
      PRODUCT_TOUR_FILE_COMPARISON: {
        top: 0,
        left: 0,
      },
      PRODUCT_TOUR_USER_FILES: {
        top: 0,
        left: 0,
      },
    },
  },
  reducers: {
    hideProductTour: (state) => {
      state.showProductTour = false
    },
    showProductTour: (state) => {
      state.showProductTour = true
    },
    setTooltipPositions: (state, action) => {
      state.tooltipPositions = { ...state.tooltipPositions, ...action.payload }
    },
    setTooltipArrowPositions: (state, action) => {
      state.arrowPositions = { ...state.arrowPositions, ...action.payload }
    },
    setCurrentOnBoardingStep: (state, action) => {
      state.currentStep.step = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCurrentStep.fulfilled, (state, action) => {
      state.error = ''
      state.currentStep = action.payload
      state.isLoading = false
    })
    builder.addCase(updateCurrentStep.rejected, (state, action) => {
      state.error = action.error.message || ''
      state.isLoading = false
    })
    builder.addCase(updateCurrentStep.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      const step = action.payload?.onboardingCurrentStep
      if (step) {
        state.currentStep = { step }
      }
    })
  },
})

export const {
  hideProductTour,
  setTooltipPositions,
  setTooltipArrowPositions,
  setCurrentOnBoardingStep,
  showProductTour,
} = productTourSlice.actions

export const selectCurrentOnBoardingStep = (state: RootState) => state.entities.productTour.currentStep.step
export const selectNextStep = (state: RootState) => {
  switch (selectCurrentOnBoardingStep(state)) {
    case PRODUCT_TOUR_SINGLE_FILE:
      return PRODUCT_TOUR_FILE_COMPARISON
    case PRODUCT_TOUR_FILE_COMPARISON:
      return PRODUCT_TOUR_USER_FILES
    default:
      return END
  }
}
export const selectPreviousStep = (state: RootState) => {
  switch (selectCurrentOnBoardingStep(state)) {
    case PRODUCT_TOUR_USER_FILES:
      return PRODUCT_TOUR_FILE_COMPARISON
    case PRODUCT_TOUR_FILE_COMPARISON:
      return PRODUCT_TOUR_SINGLE_FILE
    default:
      return END
  }
}
export const selectTooltipPositionByTitle =
  (title: UpdateCurrentStepResponse['data']['step']) => (state: RootState) => {
    return state.entities.productTour.tooltipPositions[title]
  }

export const selectArrowPositions = () => (state: RootState) => {
  return state.entities.productTour.arrowPositions
}
export const selectShowProductTour = (state: RootState) => state.entities.productTour.showProductTour
