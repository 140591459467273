import { TheIconOlympeLogoWithText } from '../components/icons/TheIconOlympeLogoWithText.tsx'
import BaseInput from '../components/atomics/BaseInput.tsx'
import { BaseButton } from '../components/atomics/BaseButton.tsx'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../ROUTE_PATHS.ts'
import { useAppDispatch } from '../store/hooks.ts'
import { sendPasswordResetEmail } from '../store/auth/useCases/sendPasswordResetEmail.ts'
import { EmailErrors, getErrorMessages, hasErrors, validateEmail } from '../utils/formValidation.ts'

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

export function ResetPassword() {
  const navigate = useNavigate()
  const emailErrorsInitialState: EmailErrors = { emptyEmail: '', invalidFormat: '' }
  const [emailErrors, setEmailErrors] = useState<EmailErrors>(emailErrorsInitialState)
  const dispatch = useAppDispatch()

  async function handleSubmit(e: React.FormEvent<LoginFormElement>) {
    e.preventDefault()

    const form = e.currentTarget
    const email = form.email.value
    setEmailErrors(validateEmail(email))
    if (!hasErrors(emailErrors)) {
      navigate(ROUTE_PATHS.resetPasswordInfo + `?email=${email}`)
      dispatch(sendPasswordResetEmail(email))
    }
  }

  function handleEmailBlur(e: React.FocusEvent<HTMLInputElement>) {
    setEmailErrors(validateEmail(e.target.value))
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailErrors(validateEmail(e.target.value))
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-10">
      <div className={'flex w-11/12 flex-col items-center justify-center sm:w-1/2'}>
        <div className="mb-10 flex flex-col items-center gap-3 sm:w-[26rem]">
          <TheIconOlympeLogoWithText className={'fill-primary'} />
          <h1 className="text-center text-xl font-semibold text-primary sm:text-3xl">Mot de passe oublié ?</h1>
          <p className={'text-center text-base font-normal text-label'}>
            ✉️ Entrez {"l'adresse"} email associée à votre compte et nous vous enverrons un email avec un lien de
            réinitialisation.
          </p>
        </div>

        <form className="flex w-full flex-col gap-6 sm:w-96" method={'post'} onSubmit={handleSubmit}>
          <BaseInput
            placeholder="exemple@email.com"
            name="email"
            label={'Adresse email'}
            type="email"
            onBlur={handleEmailBlur}
            errormessage={getErrorMessages(emailErrors)[0]}
            onChange={handleEmailChange}
          />

          <BaseButton className="w-2/3 self-center" label="Envoyer" type="submit" />
        </form>
      </div>
    </div>
  )
}
