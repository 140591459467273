import { useNavigate } from 'react-router-dom'
import { KeyboardEvent } from 'react'
import { ROUTE_PATHS } from '../ROUTE_PATHS.ts'
import { useCountDown } from '../utils/useCountDown.ts'
import { useRedirect } from '../utils/useRedirect.ts'
import { RedirectionLink } from '../components/RedirectionLink.tsx'
import { EmailConfirmationPageHeader } from '../components/EmailConfirmationPageHeader.tsx'

export function ResetPasswordConfirmation() {
  const navigate = useNavigate()
  const counter = useCountDown()
  useRedirect(ROUTE_PATHS.login, 3000)

  const handleClick = () => {
    navigate(ROUTE_PATHS.login)
  }
  const handleKeyPress = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key !== 'Enter') return
    navigate(ROUTE_PATHS.login)
  }

  return (
    <div className={'flex w-11/12 flex-col items-center gap-12 text-center sm:w-2/3'}>
      <EmailConfirmationPageHeader counter={counter} title={'🎉 Votre mot de passe a été réinitialisé avec succès !'} />
      <RedirectionLink handleClick={handleClick} handleKeyPress={handleKeyPress} />
    </div>
  )
}
