import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const sendEmailVerification = createAppAsyncThunk(
  'auth/sendEmailVerification',
  async (email: string, { extra: { authGateway } }) => {
    try {
      return await authGateway.sendEmailVerification(email)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
