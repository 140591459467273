import { useAppDispatch, useAppSelector } from '../../../store/hooks.ts'
import { nextOnboardingStep, selectOnboardingStepper } from '../../../store/stepper/steppersSlice.ts'
import { updateCurrentStep } from '../../../store/productTour/useCases/updateCurrentStep.ts'
import { PRODUCT_TOUR_SINGLE_FILE } from '../../../utils/CONVERSATION_CONSTANTS.ts'
import { showProductTour } from '../../../store/productTour/productTourSlice.ts'

export function useOnboardingCard() {
  const steps = useAppSelector(selectOnboardingStepper)

  const dispatch = useAppDispatch()
  function handleNextStep() {
    dispatch(nextOnboardingStep())
  }
  function handleQuitOnboarding() {
    dispatch(updateCurrentStep(PRODUCT_TOUR_SINGLE_FILE))
    dispatch(showProductTour())
  }
  return {
    handleQuitOnboarding,
    handleNextStep,
    steps: steps.slice(1),
  }
}
