import { Link } from 'react-router-dom'
import BaseInput from '../../components/atomics/BaseInput.tsx'
import { BaseButton } from '../../components/atomics/BaseButton.tsx'
import { BaseToaster } from '../../components/atomics/BaseToaster.tsx'
import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { TheIconHideEye } from '../../components/icons/TheIconHideEye.tsx'
import { TheIconVisibleEye } from '../../components/icons/TheIconVisibleEye.tsx'
import { getErrorMessages } from '../../utils/formValidation.ts'
import { TheIconOlympeLogoWithoutText } from '../../components/icons/TheIconOlympeLogoWithoutText.tsx'
import { useLoginHook } from './use-login.hook.ts'
import { TheIconSuccess } from '../../components/icons/TheIconSuccess.tsx'

export function Login() {
  const {
    form,
    LegalMentionsRef,
    enableLoginFromParentWindow,
    handleSubmit,
    handleEmailChange,
    handlePasswordChange,
    emailErrors,
    passwordInputType,
    passwordSimpleErrors,
    togglePasswordInputType,
    handleEmailValidation,
    handlePasswordValidation,
    handleResetRegisterStepper,
    toasterMessageElement,
  } = useLoginHook()

  enableLoginFromParentWindow()

  const gradientStyle = {
    background: 'linear-gradient(301deg, #E8ECF1 5.95%, rgba(234, 241, 246, 0.50) 51.34%)',
  }

  const renderLeftScreenSection = () => {
    return (
      <div className={'flex h-full w-4/5 flex-col gap-9'}>
        <div className={'flex flex-col gap-4'}>
          <p className={'text-2xl font-normal text-label'}>Simplifiez votre quotidien grâce à Olympe</p>
          <p className={'text-sm font-normal'}>
            Trouvez rapidement les réponses dont vous avez besoin.
            <br />
            Prenez des décisions éclairées en un temps record.
          </p>
        </div>
        <div className={'relative h-3/4'}>
          <img
            src={'/assets/images/connexion-screenshot.png'}
            alt={'placeholder'}
            className={'h-auto w-auto max-w-full self-stretch rounded-xl border border-iceberg'}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={'relative flex h-screen p-7'}>
      <div
        style={gradientStyle}
        className={`hidden w-7/12 flex-col gap-14 overflow-auto rounded-2xl pl-8 pr-7 pt-6 sm:flex`}
      >
        <div>
          <TheIconOlympeLogoWithText />
        </div>
        {renderLeftScreenSection()}
      </div>
      <div
        className={
          'flex h-full w-full flex-col items-center gap-8 pt-8 sm:w-5/12 sm:px-4 md:pl-8 md:pr-6 lg:px-20 xl:px-20'
        }
      >
        <BaseToaster message={toasterMessageElement} color={'danger'}>
          <TheIconSuccess width="24" height="24" className={'fill-primary'} />
        </BaseToaster>
        <div className={'flex h-[95%] w-full max-w-[500px] flex-col items-center justify-center gap-8'}>
          <div className={'flex h-full w-full flex-col'}>
            <div className={'mb-7 flex flex-col items-center gap-4'}>
              <TheIconOlympeLogoWithoutText width="41" height="40" className={'fill-primary'} />
              <h1 className="h-14 text-center text-4xl font-semibold text-primary">Connexion</h1>
            </div>
            <div className={'flex flex-col gap-7 overflow-auto'}>
              <form data-testid="login-form" className={'mb-12 flex h-2/3 flex-col gap-4'}>
                <BaseInput
                  placeholder="Adresse email"
                  name="email"
                  label="Adresse email"
                  type="email"
                  value={form.email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailValidation}
                  errormessage={getErrorMessages(emailErrors)[0]}
                />
                <BaseInput
                  placeholder="Mot de passe"
                  name="password"
                  label="Mot de passe"
                  type={passwordInputType}
                  value={form.password}
                  onChange={handlePasswordChange}
                  onBlur={handlePasswordValidation}
                  errormessage={getErrorMessages(passwordSimpleErrors)[0]}
                >
                  {passwordInputType === 'password' ? (
                    <TheIconHideEye
                      data-testid={'icon-eye'}
                      className="w-6 fill-primary hover:cursor-pointer"
                      onClick={togglePasswordInputType}
                    />
                  ) : (
                    <TheIconVisibleEye
                      className="w-6 fill-primary hover:cursor-pointer"
                      onClick={togglePasswordInputType}
                    />
                  )}
                </BaseInput>
                <div className="flex-flex-col self-end">
                  <Link to="/reset-password" className="hover:opacity-70">
                    <p className="text-sm font-normal text-label">Mot de passe oublié ?</p>
                  </Link>
                </div>
              </form>
              <div className={'mx-1 flex flex-col gap-4 sm:mx-0 sm:gap-10'}>
                <BaseButton className="w-2/3 self-center" label="Connexion" onClick={handleSubmit}>
                  Connexion
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-col items-center gap-10'}>
          <div className="flex-flex-col">
            <Link onClick={handleResetRegisterStepper} to="/register" className="hover:opacity-70">
              <p className="text-center">Vous n’avez pas de compte ?</p>
              <p className="text-center text-base font-semibold leading-5 text-primary">Créez un compte</p>
            </Link>
          </div>
          <div ref={LegalMentionsRef} className={'hover:cursor-pointer'}>
            <a
              className={'text-sm font-semibold text-primary hover:opacity-70'}
              href={import.meta.env.VITE_OLYMPE_LEGAL_MENTIONS_URL}
              target={'_blank'}
              rel="noreferrer"
            >
              Mentions légales
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
