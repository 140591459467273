import { passwordDetailedErrors, PasswordDetailedErrors } from '../utils/formValidation.ts'
import { TheIconCheck } from './icons/TheIconCheck.tsx'
import { TheIconError } from './TheIconError.tsx'

export function PasswordRequirements(props: Readonly<{ passwordErrors: PasswordDetailedErrors }>) {
  return (
    <ul className={'mt-2 pl-6'}>
      <li
        className={`${
          props.passwordErrors.minLength === passwordDetailedErrors.minLength
            ? 'text-primary'
            : 'font-semibold text-woodlandSoul'
        } flex items-center text-sm`}
      >
        <span className={'mr-2 w-2 '}>
          {props.passwordErrors.minLength === passwordDetailedErrors.minLength ? (
            <TheIconError />
          ) : (
            <TheIconCheck className={'fill-woodlandSoul'} />
          )}
        </span>
        {passwordDetailedErrors.minLength}
      </li>
      <li
        className={`${
          props.passwordErrors.upperCase === passwordDetailedErrors.upperCase
            ? 'text-primary'
            : 'font-semibold text-woodlandSoul'
        } flex items-center text-sm`}
      >
        <span className={'mr-2 w-2 '}>
          {props.passwordErrors.upperCase === passwordDetailedErrors.upperCase ? (
            <TheIconError />
          ) : (
            <TheIconCheck className={'fill-woodlandSoul'} />
          )}
        </span>
        {passwordDetailedErrors.upperCase}
      </li>
      <li
        className={`${
          props.passwordErrors.lowerCase === passwordDetailedErrors.lowerCase
            ? 'text-primary'
            : 'font-semibold text-woodlandSoul'
        }  flex items-center text-sm`}
      >
        <span className={'mr-2 w-2 '}>
          {props.passwordErrors.lowerCase === passwordDetailedErrors.lowerCase ? (
            <TheIconError />
          ) : (
            <TheIconCheck className={'fill-woodlandSoul'} />
          )}
        </span>
        {passwordDetailedErrors.lowerCase}
      </li>
      <li
        className={`${
          props.passwordErrors.numbers === passwordDetailedErrors.numbers
            ? 'text-primary'
            : 'font-semibold text-woodlandSoul'
        }  flex items-center text-sm`}
      >
        <span className={'mr-2 w-2 '}>
          {props.passwordErrors.numbers === passwordDetailedErrors.numbers ? (
            <TheIconError />
          ) : (
            <TheIconCheck className={'fill-woodlandSoul'} />
          )}
        </span>
        {passwordDetailedErrors.numbers}
      </li>
      <li
        className={`${
          props.passwordErrors.specialCharacters === passwordDetailedErrors.specialCharacters
            ? 'text-primary'
            : 'font-semibold text-woodlandSoul'
        } flex items-center text-sm`}
      >
        <span className={'mr-2 w-2 '}>
          {props.passwordErrors.specialCharacters === passwordDetailedErrors.specialCharacters ? (
            <TheIconError />
          ) : (
            <TheIconCheck className={'fill-woodlandSoul'} />
          )}
        </span>
        {passwordDetailedErrors.specialCharacters}
      </li>
    </ul>
  )
}
