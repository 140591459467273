import { TheIconOlympeLogoWithText } from '../components/icons/TheIconOlympeLogoWithText.tsx'
import { useAppDispatch } from '../store/hooks.ts'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from '../ROUTE_PATHS.ts'
import { KeyboardEvent, useEffect, useState } from 'react'
import { sendPasswordResetEmail } from '../store/auth/useCases/sendPasswordResetEmail.ts'

export function ResetPasswordInfo() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    const email = searchParams.get('retry')
    if (!email) return
    if (email.includes('@')) {
      dispatch(sendPasswordResetEmail(email))
    } else {
      navigate(ROUTE_PATHS.resetPassword)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const resendEmail = async () => {
    const email = searchParams.get('email')
    if (email?.includes('@')) {
      const { sent } = await dispatch(sendPasswordResetEmail(email)).unwrap()
      if (sent) setIsEmailSent(true)
    } else {
      navigate(ROUTE_PATHS.resetPassword)
    }
  }

  const handleKeyPress = async (e: KeyboardEvent<HTMLParagraphElement>) => {
    if (e.key !== 'Enter') return
    await resendEmail()
  }

  const renderLabel = () => {
    if (!isEmailSent) {
      return (
        <p
          tabIndex={1}
          onKeyUp={handleKeyPress}
          onClick={resendEmail}
          className={'text-sm font-bold text-label hover:cursor-pointer hover:text-primary'}
        >
          👉 Renvoyez-moi l’email.
        </p>
      )
    } else {
      return <p className={'text-sm font-bold text-label'}>Email renvoyé ✅</p>
    }
  }

  return (
    <div className={'flex w-11/12 flex-col items-center gap-12 text-center sm:w-2/3'}>
      <div className={'flex flex-col items-center gap-6 text-center'}>
        <TheIconOlympeLogoWithText />
        <div className={'flex flex-col gap-4 sm:w-2/3'}>
          <h1 className={'text-xl font-semibold text-primary sm:text-3xl'}>Consultez vos emails ! 📬</h1>

          <p className={'text-base text-primary'}>
            Nous avons envoyé un email à {"l'adresse"} que vous avez fournie, avec les instructions de réinitialisation.
          </p>
        </div>
      </div>
      <div className={'mt-10 flex flex-col items-center justify-center text-center'}>
        <p className={'text-sm leading-6 text-label'}>Vous n’avez rien reçu ?</p>
        {renderLabel()}
        <p className={'text-sm leading-6 text-label'}>ou consultez vos spams.</p>
      </div>
    </div>
  )
}
