import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../configureStore.ts'
import { ListSourceCollectionsResponseData } from '../../types/sourceCollection.ts'
import { listSourceCollections } from './use Cases/listSourceCollections.ts'

export const sourceCollectionsSlice = createSlice({
  name: 'sourceCollections',
  initialState: {
    isLoading: false,
    sourceCollections: [] as ListSourceCollectionsResponseData,
    listSourceCollectionsError: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listSourceCollections.fulfilled, (state, action) => {
        state.sourceCollections = action.payload
      })
      .addCase(listSourceCollections.rejected, (state, action) => {
        state.listSourceCollectionsError = action.error.message ?? ''
      })
  },
})

export const selectSourceCollections = (state: RootState) => state.entities.sourceCollections.sourceCollections

const getSourceCollections = (state: RootState) => state.entities.sourceCollections.sourceCollections

export const selectSourceCollectionsLabels = createSelector(
  [getSourceCollections],
  (sourceCollections) =>
    sourceCollections?.flatMap((sourceCollection) =>
      sourceCollection.idAsSourceCollection
        ? [sourceCollection.idAsSourceCollection]
        : sourceCollection.collections.map((collection) => collection.id),
    ) ?? [],
)

export const selectIsLoading = (state: RootState) => state.entities.conversations.isLoading
