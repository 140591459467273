import { Conversation } from '../types/conversation.ts'
import { makeRight } from '../utils/either.ts'

import { MessageRepository } from './interfaces/messageRepository.ts'
import { FetchResponse } from '../types/shared.ts'
import { ListConversationMessagesError, Message, Source, UpdateMessageByIdError } from '../types/message.ts'

export class InMemoryMessageRepository implements MessageRepository {
  messages: Map<Conversation['id'], Map<Message['id'], Message>> = new Map()

  constructor(withDefault: boolean = true) {
    if (withDefault) {
      const firstMessage: Message = { createdAt: '', content: 'firstMessage', id: '1', sources: [], type: 'HUMAN' }
      const secondMessage: Message = {
        createdAt: '',
        content: 'secondMessage',
        id: '2',
        sources: [],
        type: 'GENERIC',
      }
      const thirdMessage: Message = {
        createdAt: '',
        content: 'thirdMessage',
        id: '3',
        sources: [],
        type: 'SYSTEM',
      }
      const firstConversationId = '1'
      const secondConversationId = '2'
      const thirdConversationId = '3'
      this.messages.set(
        firstConversationId,
        new Map([
          [firstMessage.id, firstMessage],
          [secondMessage.id, secondMessage],
          [thirdMessage.id, thirdMessage],
        ]),
      )
      this.messages.set(
        secondConversationId,
        new Map([
          [secondMessage.id, secondMessage],
          [thirdMessage.id, thirdMessage],
          [firstMessage.id, firstMessage],
        ]),
      )
      this.messages.set(
        thirdConversationId,
        new Map([
          [secondMessage.id, secondMessage],
          [firstMessage.id, firstMessage],
          [thirdMessage.id, thirdMessage],
        ]),
      )
    }
  }

  updateMessageById(
    conversationId: Conversation['id'],
    message: Message,
  ): FetchResponse<UpdateMessageByIdError, Message> {
    this.messages.set(conversationId, new Map([[message.id, message]]))
    return Promise.resolve(makeRight({ status: 200, data: message }))
  }

  streamResponseMessage(conversationId: Conversation['id'], content: Source['content']): Promise<Response> {
    const message: Message = {
      createdAt: '',
      id: '1',
      content,
      sources: [],
      type: 'HUMAN',
    }
    if (!this.messages.has(conversationId)) {
      this.messages.set(conversationId, new Map())
    }
    const conversationMessages = this.messages.get(conversationId)!
    conversationMessages.set(message.id, message)
    return Promise.resolve({
      type: '',
      ok: true,
      redirected: false,
      headers: {},
      status: 201,
      body: new ArrayBuffer(1),
      statusText: 'ok',
    } as unknown as Response)
  }

  listConversationMessages(
    conversationId: Conversation['id'],
  ): FetchResponse<ListConversationMessagesError, Message[]> {
    const conversationMessages = this.messages.get(conversationId)
    if (!conversationMessages) {
      return Promise.resolve(makeRight({ status: 200, data: [] }))
    }
    return Promise.resolve(makeRight({ status: 200, data: Array.from(conversationMessages.values()) }))
  }
}
