import {
  CreateUserBodyData,
  CreateUserResponse,
  CreateUserResponseData,
  GetCurrentUserResponse,
  GetCurrentUserResponseData,
  LogUserBody,
  LogUserBodyData,
  ResetPasswordResponse,
  ResetPasswordResponseData,
  SendEmailVerificationResponse,
  SendEmailVerificationResponseData,
  SendPasswordResetEmailResponse,
  SendPasswordResetEmailResponseData,
  User,
  VerifyEmailResponse,
  VerifyEmailResponseData,
} from '../types/user.ts'
import { AuthGateway } from './interface/auth.gateway.ts'
import { WretchResponse } from 'wretch'
import { ERROR_MESSAGES } from '../utils/errorMessages.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'

export class WretchAuthGateway implements AuthGateway {
  private readonly endpoint = '/auth'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async resetPassword(newPassword: string, token: string): Promise<ResetPasswordResponseData> {
    const body = {
      data: {
        newPassword,
        token,
      },
    }

    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/reset-password`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post(body).json<ResetPasswordResponse>()
    return response.data
  }

  async sendPasswordResetEmail(email: string): Promise<SendPasswordResetEmailResponseData> {
    const body = {
      data: {
        email,
      },
    }

    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/send-password-reset`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post(body).json<SendPasswordResetEmailResponse>()
    return response.data
  }

  async sendEmailVerification(email: string): Promise<SendEmailVerificationResponseData> {
    const body = {
      data: {
        email,
      },
    }

    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/send-email-verification`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post(body).json<SendEmailVerificationResponse>()
    return response.data
  }

  async verifyEmail(token: string): Promise<VerifyEmailResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/verify-email?token=${token}`
    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .get()
      .json<VerifyEmailResponse>()
      .catch(() => {
        throw new Error(ERROR_MESSAGES.GENERIC)
      })
    return response.data
  }

  refreshToken(): Promise<void> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/refresh-token`
    return this.olympeGptApi.url(requestInfoOrUrl).get().res()
  }

  async logUser({ email, password }: LogUserBody): Promise<WretchResponse> {
    const body: LogUserBodyData = {
      data: {
        email,
        password,
      },
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/login`

    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .post(body)
      .error(401, (error) => {
        if (error.json.message === 'Invalid credentials') {
          throw new Error(ERROR_MESSAGES.CREDENTIALS)
        }
        throw error
      })
      .res()
      .catch((error) => {
        if (error.message !== ERROR_MESSAGES.CREDENTIALS) {
          throw new Error(ERROR_MESSAGES.GENERIC)
        }
        throw error
      })
  }

  logout(): Promise<void> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/logout`
    return this.olympeGptApi.url(requestInfoOrUrl).get().res()
  }

  async getCurrentUser(): Promise<GetCurrentUserResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/current-user`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetCurrentUserResponse>()
    return response.data
  }

  async createUser(createUserBody: User): Promise<CreateUserResponseData> {
    const body: CreateUserBodyData = {
      data: createUserBody,
    }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/register`
    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .post(body)
      .error(409, (error) => {
        if (error.json.message === 'User already exists') {
          throw new Error(ERROR_MESSAGES.USER_ALREADY_EXISTS)
        }
        throw error
      })
      .json<CreateUserResponse>()
      .catch((error) => {
        if (error.message !== ERROR_MESSAGES.USER_ALREADY_EXISTS) {
          throw new Error(ERROR_MESSAGES.GENERIC)
        }
        throw error
      })
    return response.data
  }
}
