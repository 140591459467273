import { combineReducers } from 'redux'
import { conversationsSlice } from './conversations/conversationsSlice.ts'
import { filesSlice } from './files/filesSlice.ts'
import { authSlice } from './auth/authSlice.ts'
import { messagesSlice } from './messages/messagesSlice.ts'
import { genericErrorSlice } from './genericErrorStore.ts'
import { productTourSlice } from './productTour/productTourSlice.ts'
import { tagsSlice } from './tags/tagSlice.ts'
import { modalsSlice } from './modal/modalsSlice.ts'
import { sourceCollectionsSlice } from './sourceCollections/sourceCollectionsSlice.ts'
import { steppersSlice } from './stepper/steppersSlice.ts'

export const entitiesReducer = combineReducers({
  [conversationsSlice.name]: conversationsSlice.reducer,
  [sourceCollectionsSlice.name]: sourceCollectionsSlice.reducer,
  [filesSlice.name]: filesSlice.reducer,
  [tagsSlice.name]: tagsSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [productTourSlice.name]: productTourSlice.reducer,
  [messagesSlice.name]: messagesSlice.reducer,
  [genericErrorSlice.name]: genericErrorSlice.reducer,
  [modalsSlice.name]: modalsSlice.reducer,
  [steppersSlice.name]: steppersSlice.reducer,
})
