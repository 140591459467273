import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { uploadFile } from './useCases/uploadFile.ts'
import { UploadedFile } from '../../types/file.ts'
import { RootState } from '../configureStore.ts'
import { deleteAllUserFiles } from './useCases/deleteAllUserFiles.ts'
import { deleteFileById } from './useCases/deleteFileById.ts'
import { updateFile } from './useCases/updateFile.ts'
import { listUserFiles } from './useCases/listUserFiles.ts'
import { deleteManyFiles } from './useCases/deleteManyFiles.ts'
import { Tag } from '../../types/tag.ts'
import { applyTags } from '../tags/useCases/applyTags.ts'

const filesEntityAdapter = createEntityAdapter<UploadedFile & { extension: string }>()
export const filesSlice = createSlice({
  name: 'files',
  initialState: filesEntityAdapter.getInitialState({
    isLoading: false,
    addFileError: '',
    listFilesError: '',
    isUploading: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.fulfilled, (state, action) => {
        filesEntityAdapter.addOne(state, action.payload)
        state.isUploading = false
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.addFileError = action.error.message ?? ''
        state.isUploading = false
      })
      .addCase(uploadFile.pending, (state) => {
        state.isUploading = true
      })
      .addCase(deleteFileById.fulfilled, (state, action) => {
        state.isLoading = false
        filesEntityAdapter.removeOne(state, action.meta.arg)
      })
      .addCase(deleteFileById.pending, (state, action) => {
        state.isLoading = true
        filesEntityAdapter.removeOne(state, action.meta.arg)
      })
      .addCase(deleteFileById.rejected, (state, action) => {
        state.isLoading = false
        state.listFilesError = action.error.message ?? ''
      })
      .addCase(deleteAllUserFiles.fulfilled, (state) => {
        state.isLoading = false
        filesEntityAdapter.removeAll(state)
      })
      .addCase(deleteAllUserFiles.pending, (state) => {
        state.isLoading = true
        filesEntityAdapter.removeAll(state)
      })
      .addCase(deleteAllUserFiles.rejected, (state, action) => {
        state.isLoading = false
        state.listFilesError = action.error.message ?? ''
      })
      .addCase(deleteManyFiles.fulfilled, (state, action) => {
        state.isLoading = false
        filesEntityAdapter.removeMany(state, action.meta.arg)
      })
      .addCase(deleteManyFiles.pending, (state, action) => {
        state.isLoading = true
        filesEntityAdapter.removeMany(state, action.meta.arg)
      })
      .addCase(deleteManyFiles.rejected, (state, action) => {
        state.isLoading = false
        state.listFilesError = action.error.message ?? ''
      })
      .addCase(updateFile.fulfilled, (state, action) => {
        state.isLoading = false
        filesEntityAdapter.updateOne(state, {
          id: action.meta.arg.id,
          changes: {
            title: action.meta.arg.title,
          },
        })
      })
      .addCase(updateFile.pending, (state, action) => {
        state.isLoading = true
        filesEntityAdapter.updateOne(state, {
          id: action.meta.arg.id,
          changes: {
            title: action.meta.arg.title,
          },
        })
      })
      .addCase(updateFile.rejected, (state, action) => {
        state.isLoading = false
        state.listFilesError = action.error.message ?? ''
      })
      .addCase(listUserFiles.fulfilled, (state, action) => {
        state.listFilesError = ''
        state.isLoading = false
        filesEntityAdapter.setAll(state, action.payload)
      })
      .addCase(listUserFiles.pending, (state) => {
        state.isLoading = true
      })
      .addCase(listUserFiles.rejected, (state, action) => {
        state.isLoading = false
        state.listFilesError = action.error.message ?? ''
      })
      .addCase(applyTags.fulfilled, (state, action) => {
        filesEntityAdapter.updateOne(state, {
          id: action.meta.arg.fileId,
          changes: {
            tags: action.payload.file?.tags,
          },
        })
      })
  },
})

export const filesSelector = filesEntityAdapter.getSelectors((state: RootState) => state.entities.files)
export const selectAllFiles = (state: RootState) => filesSelector.selectAll(state)
export const selectFilesByTagId = (tagId: Tag['id']) =>
  createSelector([selectAllFiles], (allFiles) => allFiles.filter((file) => file.tags.some((id) => id === tagId)))
export const selectIsUploading = (state: RootState) => state.entities.files.isUploading
export const selectUploadFileError = (state: RootState) => state.entities.files.addFileError
