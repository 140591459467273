import { TheIconMagnifyingGlass } from '../icons/TheIconMagnifyingGlass.tsx'
import { ChangeEvent, useState } from 'react'
import { TheIconClosingCross } from '../icons/TheIconClosingCross.tsx'

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClear: () => void
  placeholder: string
  value?: string
}

export function BaseSearchInput({ onChange, placeholder, onClear, value }: Readonly<Props>) {
  const [borderStyle, setBorderStyle] = useState('border border-solid border-bInput')
  const setOnFocusStyle = () => {
    setBorderStyle('border border-solid border-primary')
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  const handleClear = () => {
    setBorderStyle('border border-solid border-bInput')
    onClear()
  }

  return (
    <div className={`${borderStyle} flex w-full items-center gap-4 rounded-lg bg-bright py-3 pl-4 pr-3 lg:w-[20rem]`}>
      <TheIconMagnifyingGlass />
      <input
        onFocus={setOnFocusStyle}
        onChange={handleChange}
        className="w-4/5 text-sm outline-none sm:w-full"
        placeholder={placeholder}
        value={value}
        type="text"
      />
      {value?.length ? <TheIconClosingCross onClick={handleClear} className="hover:cursor-pointer" /> : null}
    </div>
  )
}
