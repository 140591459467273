import { useSelector } from 'react-redux'
import { closeTimeSavedModal, selectTimeSavedModal } from '../store/modal/modalsSlice.ts'
import { TheIconBravo } from './TheIconBravo.tsx'
import { BaseButton } from './atomics/BaseButton.tsx'
import { createConversation } from '../store/conversations/use Cases/createConversation.ts'
import { useAppDispatch } from '../store/hooks.ts'
import { useNavigate } from 'react-router-dom'

export function TimeSavedModal() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { hoursSaved, isOpen } = useSelector(selectTimeSavedModal)

  const handleOkClick = () => {
    dispatch(closeTimeSavedModal())
  }

  async function handleRestartClick() {
    const newConversation = await dispatch(createConversation()).unwrap()
    navigate(`/conversations/${newConversation.id}`)
  }

  const modalPositionClasses = isOpen ? 'sm:-translate-x-[1.75rem] opacity-100 duration-300' : 'opacity-0'

  return (
    <div
      className={`absolute w-[21rem] rounded-[1.27275rem] border border-iceberg bg-brightGray px-[1.91rem] pb-[1.7rem] pt-[2.12rem] ${modalPositionClasses} sm:right-0 sm:top-0 sm:mt-[6.94rem] sm:w-[12.375rem] sm:rounded-xl sm:px-[1.12rem] sm:pb-4 sm:pt-5`}
    >
      <div className="flex flex-col items-center gap-[1.697rem] sm:gap-4">
        <div className="flex flex-col items-center gap-[0.42425rem] sm:gap-1">
          <TheIconBravo className={'h-[10.64144rem] w-[13.7195rem] sm:h-[8.08469rem] sm:w-[6.27081rem]'} />
          <div className={'flex flex-col items-center gap-[0.42425rem] sm:gap-1'}>
            <div className="w-[13.7195rem] text-center text-[1.75rem] font-semibold leading-9 text-primary sm:w-full sm:text-xl sm:leading-6">
              Vous avez gagné
              <br />
              {`${hoursSaved} heure${hoursSaved > 1 ? 's' : ''}`}
            </div>
            <div className=" text-center text-xl text-label sm:text-sm">
              de travail en posant votre question à Olympe.
            </div>
          </div>
        </div>
        <div className="flex w-[13.7195rem] flex-col items-center gap-5 sm:w-[6.27081rem] sm:gap-3">
          <BaseButton
            label={'Recommencer'}
            onClick={handleRestartClick}
            className={
              'w-full rounded-[0.8485rem] px-[1.7rem] py-[0.85rem] text-[1.48488rem] sm:rounded-lg sm:px-4 sm:py-2 sm:text-sm'
            }
          />
          <button
            onClick={handleOkClick}
            className="w-fit text-[1.27275rem] font-semibold leading-[1.697rem] sm:text-xs sm:leading-4"
          >
            OK !
          </button>
        </div>
      </div>
    </div>
  )
}
