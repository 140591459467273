import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../configureStore.ts'
import { ConversationType } from '../../types/conversation.ts'

interface TimeSavedModalState {
  hoursSaved: number
  isOpen: boolean
}

interface ModalsState {
  timeSavedModal: TimeSavedModalState
}

const initialState: ModalsState = {
  timeSavedModal: {
    hoursSaved: 1,
    isOpen: false,
  },
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openTimeSavedModal: (state, action: PayloadAction<ConversationType>) => {
      const conversationType = action.payload
      const hoursSavedByConversationType: Record<ConversationType, number> = {
        MESSAGE_ONLY: 1,
        SINGLE_FILE: 2,
        FILE_COMPARISON: 3,
      }
      state.timeSavedModal.hoursSaved = hoursSavedByConversationType[conversationType]
      state.timeSavedModal.isOpen = true
    },
    closeTimeSavedModal: (state) => {
      state.timeSavedModal.isOpen = false
    },
  },
})

export const { openTimeSavedModal, closeTimeSavedModal } = modalsSlice.actions

export const selectTimeSavedModal = (state: RootState) => state.entities.modals.timeSavedModal
