import { forwardRef, InputHTMLAttributes, ReactNode, Ref, useState } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  errormessage?: string // Size variant for the button.
  padding?: 'small' | 'medium' | 'large' // Size variant for the button.
  label?: string
  children?: ReactNode
  width?: 'w-1/2' | 'w-full'
}

function BaseInput(props: Readonly<InputProps>, ref: Ref<HTMLInputElement>) {
  const [isFocused, setIsFocused] = useState(false)

  /**
   * Determines the CSS classes for the input element based on focus and error state.
   *
   * @returns {string} The CSS classes to be applied to the input element.
   */
  function handleInputStyle(): string {
    if (isFocused && !props.errormessage?.length) {
      return 'border border-solid border-primary outline-none'
    } else if (isFocused && props.errormessage?.length) {
      return 'border border-solid border-error outline-none'
    } else if (props.disabled) {
      return 'border-none bg-bInput'
    } else if (props.errormessage?.length) {
      return 'border border-solid border-error'
    } else {
      return 'border border-solid border-bInput outline-none'
    }
  }

  /**
   * Determines the size-specific CSS classes for the input element.
   *
   * @returns {string} The size-specific CSS classes to be applied to the input element.
   */
  function handleInputSize(): string {
    return props.padding === 'small' ? 'pr-5 pl-6' : 'px-6 py-3.5'
  }

  const { children, ...defaultAttributes } = props
  const width = props.width ?? 'w-full'
  return (
    <div className={`flex ${width} flex-col justify-between gap-1`}>
      {props.label && <label className="h-6 text-sm text-label">{props.label}</label>}
      <div className={`relative flex items-center justify-between rounded-lg ${handleInputStyle()}`}>
        <input
          className={`w-full rounded-lg border-none text-base leading-5 text-label outline-none ${handleInputSize()}`}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={ref}
          {...defaultAttributes}
        />
        <span className="absolute right-0 mr-4">{children}</span>
      </div>
      {!!props.errormessage?.length && <span className="text-sm text-error">{props.errormessage}</span>}
    </div>
  )
}
export default forwardRef(BaseInput)
