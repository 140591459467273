import { UploadFileInConversationError } from '../../../types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { UploadFileInConversationPayload } from '../../../types/file.ts'

export const uploadFileInConversation = createAppAsyncThunk(
  'conversations/uploadFileInConversation',
  async ({ conversationId, file, fileName }: UploadFileInConversationPayload, { extra: { conversationGateway } }) => {
    const response = await conversationGateway.uploadFileInConversation({ conversationId, file, fileName })
    if (!response.ok) {
      const error: UploadFileInConversationError = await response.json()
      return Promise.reject(error.message)
    }
    const responseBody = { conversationId, fileName, file }
    return Promise.resolve(responseBody)
  },
)
