import { PRODUCT_TOUR_FILE_COMPARISON, PRODUCT_TOUR_USER_FILES } from '../../utils/CONVERSATION_CONSTANTS.ts'
import { ArrowIconLeft } from '../icons/ArrowIconLeft.tsx'
import { ArrowIconBottom } from '../icons/ArrowIconBottom.tsx'
import { ArrowIconTop } from '../icons/ArrowIconTop.tsx'
import { useTooltipHook } from './use-tooltip.hook.ts'

export function ProductTourTooltipArrowIcon() {
  const { step, arrowDimension, currentArrowPosition } = useTooltipHook()

  switch (step) {
    case PRODUCT_TOUR_USER_FILES:
      return (
        <ArrowIconLeft
          width={arrowDimension}
          height={arrowDimension}
          className={`absolute`}
          style={currentArrowPosition.PRODUCT_TOUR_USER_FILES}
        />
      )
    case PRODUCT_TOUR_FILE_COMPARISON:
      return (
        <ArrowIconBottom
          width={arrowDimension}
          height={arrowDimension}
          className={`absolute`}
          style={currentArrowPosition.PRODUCT_TOUR_FILE_COMPARISON}
        />
      )
    default:
      return (
        <ArrowIconTop
          width={arrowDimension}
          height={arrowDimension}
          className={`absolute`}
          style={currentArrowPosition.PRODUCT_TOUR_SINGLE_FILE}
        />
      )
  }
}
