import { LoaderFunction, Navigate, Outlet } from 'react-router-dom'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { WretchAuthGateway } from '../../gateways/WretchAuth.gateway.ts'
import { hideProductTour, setCurrentOnBoardingStep, showProductTour } from '../../store/productTour/productTourSlice.ts'
import { useAppDispatch } from '../../store/hooks.ts'
import { CreateUserResponseData } from '../../types/user.ts'
import { olympeGptApiWretch } from '../../gateways/olympeGptApiWretch.ts'
import { END } from '../../utils/CONVERSATION_CONSTANTS.ts'

export let currentUser: CreateUserResponseData | null = null

export const currentUserLoader = (): LoaderFunction => async () => {
  if (currentUser) {
    return currentUser
  }
  const authService = new WretchAuthGateway(olympeGptApiWretch)
  try {
    currentUser = await authService.getCurrentUser()
    return currentUser
  } catch (error) {
    window.location.href = ROUTE_PATHS.login
  }
  return null
}

export function ProtectedRoutes() {
  const dispatch = useAppDispatch()
  const currentStep = currentUser?.onboardingCurrentStep

  if (!currentUser) {
    return <Navigate to={ROUTE_PATHS.login} />
  }
  dispatch(setCurrentOnBoardingStep(currentStep))
  if (currentStep !== END) dispatch(showProductTour())
  if (currentStep === END) dispatch(hideProductTour())

  return <Outlet />
}
