import { TheIconFilter } from './icons/TheIconFilter.tsx'
import { ButtonHTMLAttributes } from 'react'

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: (() => void) | undefined
  label: string
}

export function MyCustomButton({ onClick, label, ...props }: Readonly<CustomButtonProps>) {
  return (
    <button
      onClick={onClick}
      className={`flex items-center self-end rounded-lg border hover:cursor-pointer ${
        props.disabled ? 'bg-bInput' : 'bg-bright'
      }`}
      {...props}
    >
      <span className="mr-2 cursor-pointer py-2 pl-4">
        <TheIconFilter disabled={props.disabled} />
      </span>
      <span className={`cursor-pointer py-2 pr-4 ${props.disabled ? 'text-silver' : 'text-label'}`}>{label}</span>
    </button>
  )
}
