import { TheIconCross } from '../../icons/TheIconCross.tsx'
import { useTooltipHook } from '../use-tooltip.hook.ts'

export function ProductTourTooltipBody() {
  const { handleCancel, subtitle, description, descriptionWidth } = useTooltipHook()

  return (
    <div className={'flex items-start justify-between gap-4'}>
      <div className={'w-6/8 flex flex-col gap-1'}>
        <h3 className={'text-base font-semibold text-bright'}>{subtitle}</h3>
        <p className={`${descriptionWidth} text-sm text-silver`}>{description}</p>
      </div>
      <div className={'w-2/8'}>
        <TheIconCross onClick={handleCancel} className={'w-3 fill-silver hover:cursor-pointer'} />
      </div>
    </div>
  )
}
