import { Message } from '../types/message.ts'
import { TheMessageItem } from './TheMessageItem.tsx'
import { useEffect, useRef } from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  messages: Message[]
  className?: string
}

export function TheMessagesList({ messages, className, children }: Readonly<Props>) {
  const ref = useRef<HTMLDivElement>(null)
  const previousMessagesLength = useRef(0)

  useEffect(() => {
    // This code apply a different kind of scrolling depending on
    // whether the number of messages changes (for example at page reload)
    // or only if the content of the array changes (for exemple when the last message is streamed)
    if (previousMessagesLength.current !== messages.length) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    } else {
      ref.current?.scrollIntoView({
        block: 'end',
      })
    }
    previousMessagesLength.current = messages.length
  }, [messages])

  return (
    <div className={className}>
      {children ?? <></>}
      {messages.map((message) => (
        <TheMessageItem key={message.id} message={message} />
      ))}
      <div ref={ref} />
    </div>
  )
}
