import { ConversationGateway } from './interface/conversation.gateway.ts'
import {
  Conversation,
  CreateConversationResponse,
  CreateConversationResponseData,
  GetConversationByIdResponse,
  GetConversationByIdResponseData,
  ListUserConversationsResponse,
  ListUserConversationsResponseData,
  UpdateConversationByIdBodyData,
  UpdateConversationByIdResponse,
  UpdateConversationByIdResponseData,
} from '../types/conversation.ts'
import { UploadFileInConversationPayload } from '../types/file.ts'
import {
  ListConversationMessagesResponse,
  ListConversationMessagesResponseData,
  Message,
  UpdateMessageByIdResponse,
  UpdateMessageByIdResponseData,
} from '../types/message.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'
import { WretchResponse } from 'wretch'
import FormDataAddon from 'wretch/addons/formData'

export class WretchConversationGateway implements ConversationGateway {
  private readonly endpoint = '/conversations'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async updateMessageById(
    conversationId: Conversation['id'],
    message: Message,
  ): Promise<UpdateMessageByIdResponseData> {
    const body = { data: message }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/messages/${message.id}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).put(body).json<UpdateMessageByIdResponse>()
    return response.data
  }

  async listConversationMessages(conversationId: Conversation['id']): Promise<ListConversationMessagesResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/messages`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListConversationMessagesResponse>()
    return response.data
  }

  async getConversationById(conversationId: string): Promise<GetConversationByIdResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetConversationByIdResponse>()
    return response.data
  }

  uploadFileInConversation({
    fileName,
    file,
    conversationId,
  }: UploadFileInConversationPayload): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/files`
    return this.olympeGptApi.url(requestInfoOrUrl).addon(FormDataAddon).formData({ file, title: fileName }).post().res()
  }

  async updateConversation(conversation: Conversation): Promise<UpdateConversationByIdResponseData> {
    const body: UpdateConversationByIdBodyData = { data: conversation }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversation.id}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).put(body).json<UpdateConversationByIdResponse>()
    return response.data
  }

  async listUserConversations(): Promise<ListUserConversationsResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = this.endpoint
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListUserConversationsResponse>()
    return response.data
  }

  deleteFilesInConversation(conversationId: Conversation['id']): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/files`
    return this.olympeGptApi.url(requestInfoOrUrl).delete().res()
  }

  deleteConversationById(conversationId: Conversation['id']): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}`
    return this.olympeGptApi.url(requestInfoOrUrl).delete().res()
  }

  async createConversation(): Promise<CreateConversationResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = this.endpoint
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post().json<CreateConversationResponse>()
    return response.data
  }
}
