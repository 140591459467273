import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { TheAddDocumentButton } from './TheAddDocumentButton.tsx'
import { ReactNode, useRef } from 'react'
import { TheAddedDocumentTag } from './TheAddedDocumentTag.tsx'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { MyCustomButton } from './MyCustomButton.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'

export function TheInputSingleFileType({
  isQuestionLoading,
  userInput,
  onInput,
  className,
  handleKeyDown,
  handleFileInputChange,
  handleInputFileOpening,
  currentConversation,
  handleSend,
  messages,
  toggleDisplaySourceFilter,
}: Readonly<TheConversationMessageInputProps>) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const handleClickFileIcon = () => {
    if (handleInputFileOpening && inputRef) {
      handleInputFileOpening(inputRef)
    }
  }

  const handleSubmit = () => {
    if (currentConversation && currentConversation?.currentFilesInfos?.length < 1) return
    if (!userInput.length) return
    handleSend()
  }

  const renderSubmitOrLoadingIcon = (): ReactNode => {
    if (isQuestionLoading) {
      return <TheDotAnimation />
    } else if (messages?.length) {
      return (
        <div>
          <TheIconArrowSubmission
            onClick={handleSubmit}
            disabled={
              !userInput.length || (!!currentConversation && currentConversation?.currentFilesInfos?.length < 1)
            }
          />
        </div>
      )
    } else {
      return (
        <div className={'flex gap-4'}>
          <MyCustomButton onClick={toggleDisplaySourceFilter} label={'Sources'} />
          <TheIconArrowSubmission
            onClick={handleSend}
            disabled={
              !userInput.length || (!!currentConversation && currentConversation?.currentFilesInfos?.length < 1)
            }
          />
        </div>
      )
    }
  }

  return (
    <div
      className={`min-w-1/2 hidden flex-col justify-between rounded-lg bg-bright py-4 pl-6 pr-5 shadow-custom sm:flex sm:w-full sm:gap-2.5 ${className}`}
    >
      <textarea
        rows={1}
        className="my-4 max-h-[200px] w-full resize-none rounded-lg bg-bright text-base leading-[18px] outline-none sm:my-0 sm:py-2.5"
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading}
        onInput={onInput}
        onKeyDown={handleKeyDown}
      />
      <div className="flex w-full flex-col items-center gap-2 rounded-md md:flex-row">
        <div className="mr-5 flex w-3/5 items-center gap-2">
          <TheAddDocumentButton
            disabled={isQuestionLoading || (currentConversation && currentConversation?.currentFilesInfos.length === 1)}
            onClick={handleClickFileIcon}
          />
          {!!currentConversation?.currentFilesInfos.length && (
            <TheAddedDocumentTag fileInfos={currentConversation?.currentFilesInfos[0]} />
          )}
        </div>
        <div className="relative flex w-2/5 items-center justify-end gap-2 self-end">{renderSubmitOrLoadingIcon()}</div>
      </div>
      <input ref={inputRef} accept=".pdf" className="hidden" type="file" onChange={handleFileInputChange} />
    </div>
  )
}
