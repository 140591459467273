import { FormEvent } from 'react'
import { TheIconImportDocument } from './icons/TheIconImportDocument.tsx'

type Props = {
  handleFileInputChange: (e: FormEvent<HTMLInputElement>) => void
}

export function TheFileUploaderMobile({ handleFileInputChange }: Readonly<Props>) {
  function handleChange(e: FormEvent<HTMLInputElement>) {
    handleFileInputChange(e)
  }

  return (
    <>
      <label htmlFor="fileInputRefMobile">
        <span
          className="my-6 inline-flex h-9 w-full items-center justify-center gap-4 rounded-lg border
          bg-primary px-4 py-2 text-center text-sm text-bright sm:hidden"
        >
          <TheIconImportDocument className={'fill-bright'} />
          Importer un document
        </span>
      </label>
      <input onChange={handleChange} id="fileInputRefMobile" accept=".pdf" className="hidden" type="file" />
    </>
  )
}
