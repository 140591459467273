import { TheIconFolder } from './icons/TheIconFolder.tsx'
import { Tag, TagWithFileCountAndActiveStatus } from '../types/tag.ts'

type Props = {
  tag: TagWithFileCountAndActiveStatus
  onClick: (tag: Tag) => void
}
export function TheTag({ tag, onClick }: Readonly<Props>) {
  const activeTagContainerStyle = () => {
    return tag.isTagActive
      ? 'shadow-softElevatedShadow border border-solid border-brightGray bg-bright'
      : 'border border-solid border-mistySerenity bg-mistySkyGray hover:cursor-pointer'
  }

  const getDocumentLabel = () => {
    return tag.count > 1 ? 'documents' : 'document'
  }

  const getFilesLength = () => {
    return tag.count
  }

  const handleClick = () => {
    if (tag.isTagActive) return
    onClick(tag)
  }

  return (
    <div
      onClick={handleClick}
      className={`${activeTagContainerStyle()} flex w-[12.5rem] flex-none flex-col gap-4 rounded-lg px-4 pb-3 pt-5`}
    >
      <TheIconFolder isActive={tag.isTagActive} />
      <div className={'flex flex-col gap-1'}>
        <h4
          className={`${
            tag.isTagActive ? 'text-tealBlue' : 'text-primary'
          } w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold`}
        >
          {tag.name}
        </h4>
        <p
          className={`text-xs ${tag.isTagActive ? 'text-primary' : 'text-label'}`}
        >{`${getFilesLength()} ${getDocumentLabel()}`}</p>
      </div>
    </div>
  )
}
