import { Conversation } from '../../../types/conversation.ts'
import { Message } from '../../../types/message.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const updateMessageById = createAppAsyncThunk(
  'messages/updateMessageById',
  async (
    { conversationId, message }: { conversationId: Conversation['id']; message: Message },
    { extra: { conversationGateway } },
  ) => {
    try {
      return await conversationGateway.updateMessageById(conversationId, message)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
