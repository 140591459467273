import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import { ReactNode } from 'react'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { MyCustomButton } from './MyCustomButton.tsx'

export function TheInputMessageOnlyType({
  isQuestionLoading,
  userInput,
  onInput,
  className,
  handleKeyDown,
  handleSend,
  toggleDisplaySourceFilter,
  messages,
}: Readonly<TheConversationMessageInputProps>) {
  const renderSubmitOrLoadingIcon = (): ReactNode => {
    if (isQuestionLoading) {
      return <TheDotAnimation />
    } else if (!messages?.length) {
      return (
        <div className={'flex gap-4'}>
          <MyCustomButton onClick={toggleDisplaySourceFilter} label={'Sources'} />
          <TheIconArrowSubmission disabled={!userInput.length} onClick={handleSend} />
        </div>
      )
    } else {
      return <TheIconArrowSubmission onClick={handleSend} disabled={!userInput.length} />
    }
  }
  return (
    <div
      className={`min-w-1/2 hidden justify-between rounded-lg bg-bright py-4 pl-6 pr-5 shadow-custom sm:flex sm:w-full sm:gap-2.5 lg:min-w-[40rem] ${className}`}
    >
      <textarea
        rows={1}
        className="my-4 max-h-[200px] w-full resize-none rounded-lg bg-bright text-base leading-5 outline-none sm:my-0 sm:py-2.5"
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading}
        onInput={onInput}
        onKeyDown={handleKeyDown}
      />
      {renderSubmitOrLoadingIcon()}
    </div>
  )
}
