import { ConversationTypeItem } from '../ConversationTypeItem.tsx'
import { nanoid } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import { ConversationType } from '../../types/conversation.ts'
import { useTheConversationTypesListHook } from './use-the-conversation-types-list.hook.ts'

type Props = {
  subtitle: string
}

export function TheConversationTypesList({ subtitle }: Readonly<Props>) {
  const { targetRef, messages, typesOfConversationList, currentConversation, handleClick } =
    useTheConversationTypesListHook()
  const renderTypesOfConversationCards = (): ReactNode => {
    const typesOfConversation = Object.keys(typesOfConversationList) as ConversationType[]
    return typesOfConversation.map((typeOfConversation) => {
      const type = typesOfConversationList[typeOfConversation]
      return (
        <button
          onClick={() => handleClick(typeOfConversation)}
          className={`w-full flex-col items-center gap-3 rounded-2xl p-3 hover:cursor-pointer lg:w-1/3 ${
            currentConversation?.type === typeOfConversation && 'bg-brightGray'
          }`}
          onKeyDown={() => handleClick(typeOfConversation)}
          key={nanoid()}
        >
          <ConversationTypeItem
            key={nanoid()}
            title={type.title}
            step={type.step}
            description={type.description}
            isActive={currentConversation?.type === typeOfConversation}
          />
        </button>
      )
    })
  }

  return (
    <div
      ref={targetRef}
      className={`${messages.length ? 'flex-row' : 'flex-col'} relative flex items-center justify-center`}
    >
      <h2 className={'mb-5 text-center text-base font-semibold leading-5 text-label'}>
        {!messages.length && subtitle}
      </h2>
      <div className={'flex w-full flex-col gap-4 rounded-2xl p-4 shadow-shadowSoft sm:p-2 lg:flex-row'}>
        {renderTypesOfConversationCards()}
      </div>
    </div>
  )
}
