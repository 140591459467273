import { TheSidebarConversationList } from './TheSidebarConversationList.tsx'
import { TheSidebarFooter } from './TheSidebarFooter.tsx'
import { useNavigate } from 'react-router-dom'
import { Conversation } from '../../types/conversation.ts'
import { TheIconDocument } from '../icons/TheIconDocument.tsx'
import { TheIconSignout } from '../icons/TheIconSignout.tsx'
import { TheIconCross } from '../icons/TheIconCross.tsx'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'

type Props = {
  onSignout: () => void
  onClose: () => void
  conversations: Conversation[]
  additionalClasses?: string
}

export function TheSidebarMobile({ onSignout, onClose, additionalClasses = '' }: Readonly<Props>) {
  const navigate = useNavigate()

  return (
    <>
      <div
        className={`absolute -left-full z-20 h-screen w-2/3 transform overflow-auto bg-primary pb-5 pl-4 pr-6 pt-4 sm:hidden ${additionalClasses}`}
      >
        <div className={'flex h-full flex-col justify-between'}>
          <div className={'flex h-full flex-col'}>
            <div>
              <div className="flex items-center justify-between border-b border-solid border-b-bright pb-3">
                <TheIconCross onClick={onClose} className={'w-5 fill-silver'} />
                <TheIconSignout className={'h-6 w-6 fill-silver'} onClick={onSignout} />
              </div>
              <div className="flex items-center gap-4 border-b border-solid border-label py-8 pl-4 pr-6">
                <TheIconDocument className={'h-6 w-6 fill-bright'} />
                <button
                  className="text-bright"
                  onKeyDown={() => navigate(ROUTE_PATHS.documents)}
                  onClick={() => navigate(ROUTE_PATHS.documents)}
                >
                  Mes documents
                </button>
              </div>
            </div>
            <TheSidebarConversationList />
          </div>
          <TheSidebarFooter />
        </div>
      </div>
      <button
        onKeyDown={onClose}
        onClick={onClose}
        className={`${additionalClasses} absolute -left-full z-10 h-screen w-4/5 transform`}
      />
    </>
  )
}
