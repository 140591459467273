interface Props extends React.HTMLAttributes<SVGSVGElement> {
  isActive?: boolean
}
export function TheEllipseIcon({ isActive, ...props }: Readonly<Props>) {
  const fill = isActive ? '#212121' : '#D9D9D9'
  return (
    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="4.21094" cy="4" r="4" fill={fill} />
    </svg>
  )
}
