import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { Conversation, ConversationType } from '../../types/conversation.ts'
import { deleteFilesInConversation } from '././use Cases/deleteFilesInConversation.ts'
import { uploadFileInConversation } from '././use Cases/uploadFileInConversation.ts'
import { deleteConversationById } from '././use Cases/deleteConversationById.ts'
import { getConversationById } from '././use Cases/getConversationById.ts'
import { updateConversation } from '././use Cases/updateConversation.ts'
import { createConversation } from '././use Cases/createConversation.ts'
import { AppDispatch, RootState } from '../configureStore.ts'
import { listUserConversations } from '././use Cases/listUserConversations.ts'
import { NavigateFunction } from 'react-router-dom'

const conversationsEntityAdapter = createEntityAdapter<Conversation>({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})
export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: conversationsEntityAdapter.getInitialState({
    isLoading: false,
    createConversationError: '',
    getConversationByIdError: '',
    updateConversationError: '',
    listConversationsError: '',
    currentConversationType: 'unspecified' as ConversationType,
    listSourceCollectionsError: '',
  }),
  reducers: {
    updateConversationTypeByConversationId: (
      state,
      action: { payload: { conversationId: Conversation['id']; type: Conversation['type'] } },
    ) => {
      conversationsEntityAdapter.updateOne(state, {
        id: action.payload.conversationId,
        changes: {
          type: action.payload.type,
        },
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listUserConversations.fulfilled, (state, action) => {
        state.isLoading = false
        const payload: Conversation[] = action.payload.map((conversation) => {
          return {
            ...conversation,
            currentFilesInfos: conversation.currentFilesInfos.map((fileInfos) => ({
              ...fileInfos,
              uploadStatus: 'UPLOADED',
            })),
          }
        })
        conversationsEntityAdapter.setAll(state, payload)
      })
      .addCase(createConversation.fulfilled, (state, action) => {
        state.isLoading = false
        const payload: Conversation = {
          ...action.payload,
          currentFilesInfos: action.payload.currentFilesInfos.map((fileInfos) => ({
            ...fileInfos,
            uploadStatus: 'UPLOADED',
          })),
        }
        conversationsEntityAdapter.addOne(state, payload)
      })
      .addCase(createConversation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.isLoading = false
        state.createConversationError = action.error.message ?? ''
      })
      .addCase(updateConversation.fulfilled, (state, action) => {
        state.isLoading = false
        const payload: Conversation = {
          ...action.payload,
          currentFilesInfos: action.payload.currentFilesInfos.map((fileInfos) => ({
            ...fileInfos,
            uploadStatus: 'UPLOADED',
          })),
        }
        conversationsEntityAdapter.upsertOne(state, payload)
      })
      .addCase(updateConversation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateConversation.rejected, (state, action) => {
        state.isLoading = false
        state.updateConversationError = action.error.message ?? ''
      })
      .addCase(getConversationById.fulfilled, (state, action) => {
        state.isLoading = false
        const payload: Conversation = {
          ...action.payload,
          currentFilesInfos: action.payload.currentFilesInfos.map((fileInfos) => ({
            ...fileInfos,
            uploadStatus: 'UPLOADED',
          })),
        }
        conversationsEntityAdapter.upsertOne(state, payload)
      })
      .addCase(getConversationById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getConversationById.rejected, (state, action) => {
        state.isLoading = false
        state.getConversationByIdError = action.error.message ?? ''
      })
      .addCase(deleteConversationById.fulfilled, (state, action) => {
        const conversationId = action.meta.arg
        state.isLoading = false
        conversationsEntityAdapter.removeOne(state, conversationId)
      })
      .addCase(deleteConversationById.pending, (state, action) => {
        const conversationId = action.meta.arg
        // Remove the conversation from the list even if the request is not finished
        conversationsEntityAdapter.removeOne(state, conversationId)
        state.isLoading = true
      })
      .addCase(deleteConversationById.rejected, (state, action) => {
        state.isLoading = false
        state.getConversationByIdError = action.error.message ?? ''
      })
      .addCase(deleteFilesInConversation.fulfilled, (state, action) => {
        state.isLoading = false
        conversationsEntityAdapter.updateOne(state, {
          id: action.payload,
          changes: {
            currentFilesInfos: [],
          },
        })
      })
      .addCase(deleteFilesInConversation.pending, (state, action) => {
        state.isLoading = true
        conversationsEntityAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: {
            currentFilesInfos: [],
          },
        })
      })
      .addCase(deleteFilesInConversation.rejected, (state, action) => {
        state.isLoading = false
        state.getConversationByIdError = action.error.message ?? ''
      })
      .addCase(uploadFileInConversation.fulfilled, (state, action) => {
        state.isLoading = false

        const current = state.entities[action.payload.conversationId]?.currentFilesInfos
        const lastElement = current?.pop()
        if (lastElement && current) {
          const newLast: Conversation['currentFilesInfos'][0] = { ...lastElement, uploadStatus: 'UPLOADED' }
          conversationsEntityAdapter.updateOne(state, {
            id: action.payload.conversationId,
            changes: {
              currentFilesInfos: [...current, newLast],
            },
          })
        }
      })
      .addCase(uploadFileInConversation.rejected, (state, action) => {
        state.isLoading = false

        const current = state.entities[action.meta.arg.conversationId]?.currentFilesInfos
        const lastElement = current?.pop()
        if (lastElement && current) {
          const newLast: Conversation['currentFilesInfos'][0] = { ...lastElement, uploadStatus: 'ERROR' }
          conversationsEntityAdapter.updateOne(state, {
            id: action.meta.arg.conversationId,
            changes: {
              currentFilesInfos: [...current, newLast],
            },
          })
        }
      })
      .addCase(uploadFileInConversation.pending, (state, action) => {
        state.isLoading = true
        conversationsEntityAdapter.updateOne(state, {
          id: action.meta.arg.conversationId,
          changes: {
            currentFilesInfos: [
              ...(state.entities[action.meta.arg.conversationId]?.currentFilesInfos ?? []),
              { title: action.meta.arg.fileName, uploadStatus: 'IN_PROGRESS' },
            ],
          },
        })
      })
  },
})

export const conversationsSelector = conversationsEntityAdapter.getSelectors(
  (state: RootState) => state.entities.conversations,
)
// select all
export const selectConversationsTotal = (state: RootState) => conversationsSelector.selectTotal(state)
export const selectConversationIds = (state: RootState) => conversationsSelector.selectIds(state)
export const selectAllConversations = (state: RootState) => conversationsSelector.selectAll(state)

export const selectConversationById = (conversationId: Conversation['id']) => (state: RootState) =>
  conversationsSelector.selectById(state, conversationId)

export async function navigateToFirstConversationOrCreateOneIfNoneExists(
  dispatch: AppDispatch,
  navigate: NavigateFunction,
) {
  const conversations = await dispatch(listUserConversations()).unwrap()

  if (conversations.length === 0) {
    const newConversation = await dispatch(createConversation()).unwrap()
    navigate(`/conversations/${newConversation.id}`)
  } else {
    navigate(`/conversations/${conversations[0].id}`)
  }
}

export const selectIsLoading = (state: RootState) => state.entities.conversations.isLoading
