import BaseInput from '../../components/atomics/BaseInput.tsx'
import { BaseButton } from '../../components/atomics/BaseButton.tsx'
import { Link } from 'react-router-dom'
import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { TheIconHideEye } from '../../components/icons/TheIconHideEye.tsx'
import { TheIconVisibleEye } from '../../components/icons/TheIconVisibleEye.tsx'
import BaseCheckbox from '../../components/atomics/BaseCheckbox.tsx'
import { TheIconExclamationMark } from '../../components/icons/TheIconExclamationMark.tsx'
import { BaseToaster } from '../../components/atomics/BaseToaster.tsx'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { getErrorMessages, hasErrors } from '../../utils/formValidation.ts'
import { PasswordRequirements } from '../../components/PasswordRequirements.tsx'
import { TheIconOlympeLogoWithoutText } from '../../components/icons/TheIconOlympeLogoWithoutText.tsx'
import { TheEllipseStepper } from '../../components/TheEllipseStepper.tsx'
import { useSignupHook } from './use-signup.hook.ts'
import { CardWithIcon } from '../../components/CardWithIcon.tsx'
import { TheIconInteractiveShield } from '../../components/icons/TheIconInteractiveShield.tsx'
import { TheIconModernCircleButton } from '../../components/icons/TheIconModernCircleButton.tsx'
import { TheIconMinimalistHarmony } from '../../components/icons/TheIconMinimalistHarmony.tsx'
import { TheIconControlPanelOutline } from '../../components/icons/TheIconControlPanelOutline.tsx'
import { TheIconExternalDpoDisabled } from '../../components/icons/TheIconExternalDpoDisabled.tsx'
import { ReactElement } from 'react'
import { TheIconExternalDpo } from '../../components/icons/TheIconExternalDpo.tsx'
import { TheIconCollaborateVision } from '../../components/icons/TheIconCollaborateVision.tsx'
import { TheIconInternalDpoDisabled } from '../../components/icons/TheIconInternalDpoDisabled.tsx'
import { TheIconMinimalistHarmonyDisabled } from '../../components/icons/TheIconMinimalistHarmonyDisabled.tsx'

export function SignUp() {
  const {
    form,
    otherJobTitle,
    handleEmailChange,
    handleConfirmPasswordChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailValidation,
    handleFirstNameValidation,
    handleLastNameValidation,
    handleJobTitleChange,
    handleOtherJobTitleChange,
    lastNameErrors,
    confirmPasswordErrors,
    handlePasswordChange,
    passwordErrors,
    jobTitleErrors,
    togglePasswordInputType,
    handlePasswordFocus,
    handleOtherJobTitleBlur,
    passwordInputType,
    handleSubmit,
    showPasswordRequirements,
    handleChangeCGU,
    cguErrors,
    firstNameErrors,
    emailErrors,
    passwordSimpleErrors,
    handleNextStep,
    handlePreviousStep,
    steps,
    getPageTitle,
    cardsWithIcon,
    currentStep,
    emailInputRef,
    lastNameInputRef,
    firstNameInputRef,
    passwordInputRef,
    passwordConfirmInputRef,
    externalDpoJobTitleRef,
    internalDpoJobTitleRef,
    otherJobTitleRef,
    cguInputRef,
    toasterMessageElement,
  } = useSignupHook()

  const gradientStyle = {
    background: 'linear-gradient(301deg, #E8ECF1 5.95%, rgba(234, 241, 246, 0.50) 51.34%)',
  }

  // RENDERS
  const renderCGULabel = () => {
    return (
      <p className="text-sm font-normal text-primary">
        {"J'accepte "}
        <a
          className="text-sm font-semibold text-primary"
          href={import.meta.env.VITE_OLYMPE_CGU_URL}
          target="_blank"
          rel="noreferrer"
        >
          {"les conditions générales d'utilisation."}
        </a>
      </p>
    )
  }

  const renderPrivacyPolicyText = () => {
    return (
      <p className="w-full text-justify text-xs font-normal">
        {
          'La mise à disposition de Olympe implique la mise en oeuvre de traitements de données à caractère personnel. Pour en savoir plus à ce sujet, consultez '
        }
        <a
          className="text-xs font-semibold text-primary"
          href={import.meta.env.VITE_OLYMPE_PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
        >
          la Politique de confidentialité de Olympe.
        </a>
      </p>
    )
  }

  const renderExternalDpoIcons = (disabled: boolean, checked: boolean): ReactElement => {
    if (disabled) return <TheIconExternalDpoDisabled />
    return checked ? <TheIconCollaborateVision /> : <TheIconExternalDpo />
  }

  const renderInternalDpoIcons = (disabled: boolean, checked: boolean): ReactElement => {
    if (disabled) return <TheIconInternalDpoDisabled />
    return checked ? <TheIconInteractiveShield /> : <TheIconModernCircleButton />
  }

  const renderOtherJobTitleIcons = (disabled: boolean, checked: boolean): ReactElement => {
    if (disabled) return <TheIconMinimalistHarmonyDisabled />
    return checked ? <TheIconControlPanelOutline /> : <TheIconMinimalistHarmony />
  }

  const renderRightScreenSection = () => {
    switch (currentStep) {
      case 3:
        return (
          <>
            <CardWithIcon
              isChecked={cardsWithIcon[0].isChecked}
              title={'DPO externe'}
              subtitle={'Vous travaillez pour plusieurs structures.'}
            >
              <>
                <BaseCheckbox
                  ref={externalDpoJobTitleRef}
                  type="checkbox"
                  checked={cardsWithIcon[0].isChecked}
                  onChange={() => handleJobTitleChange('DPO Externe')}
                  className="hidden" // Hide the default checkbox
                />
                {renderExternalDpoIcons(cardsWithIcon[0].isDisabled, cardsWithIcon[0].isChecked)}
              </>
            </CardWithIcon>
            <CardWithIcon
              isChecked={cardsWithIcon[1].isChecked}
              title={'DPO interne'}
              subtitle={'Vous travaillez au sein d’une structure.'}
            >
              <>
                <BaseCheckbox
                  ref={internalDpoJobTitleRef}
                  type="checkbox"
                  checked={cardsWithIcon[1].isChecked}
                  onChange={() => handleJobTitleChange('DPO Interne')}
                  className="hidden" // Hide the default checkbox
                />
                {renderInternalDpoIcons(cardsWithIcon[1].isDisabled, cardsWithIcon[1].isChecked)}
              </>
            </CardWithIcon>
            <div className={'flex items-center justify-between gap-3.5'}>
              <CardWithIcon
                isChecked={cardsWithIcon[2].isChecked}
                size={'small'}
                title={'Autre profession'}
                subtitle={'Juriste, étudiant, etc.'}
              >
                <>
                  <BaseCheckbox
                    ref={otherJobTitleRef}
                    type="checkbox"
                    checked={cardsWithIcon[2].isChecked}
                    onChange={() => handleJobTitleChange('Autre')}
                    className="hidden" // Hide the default checkbox
                  />
                  {renderOtherJobTitleIcons(cardsWithIcon[2].isDisabled, cardsWithIcon[2].isChecked)}
                </>
              </CardWithIcon>
              {cardsWithIcon[2].isChecked && (
                <div className={'flex w-[45%] flex-col'}>
                  <BaseInput
                    label={'Précisez...'}
                    placeholder={'Ex: juriste'}
                    name={'otherJobTitle'}
                    type={'text'}
                    value={otherJobTitle}
                    onChange={handleOtherJobTitleChange}
                    onBlur={handleOtherJobTitleBlur}
                  />
                  {hasErrors(jobTitleErrors) && (
                    <p className={'text-sm text-error'}>{getErrorMessages(jobTitleErrors)[0]}</p>
                  )}
                </div>
              )}
            </div>
            <div>
              <BaseCheckbox
                ref={cguInputRef}
                className={'group-hover:bg-red flex items-center gap-4 accent-primary'}
                onChange={handleChangeCGU}
                name="cgu"
                checked={form.cgu}
              >
                {renderCGULabel()}
              </BaseCheckbox>
              {hasErrors(cguErrors) ? (
                <span className={'pl-7 pt-2 text-sm text-error'}>{getErrorMessages(cguErrors)[0]}</span>
              ) : null}
            </div>
            <div className={'flex justify-center gap-4'}>
              <BaseButton color={'tertiary'} className={'self-center'} label="Précédent" onClick={handlePreviousStep} />
              <BaseButton className={'self-center'} label="Inscription" onClick={(e) => handleSubmit(e)} />
            </div>
            {renderPrivacyPolicyText()}
          </>
        )
      case 2:
        return (
          <div className={'flex flex-col gap-32'}>
            <div className={'flex flex-col gap-6'}>
              <BaseInput
                ref={lastNameInputRef}
                placeholder="Indiquez votre nom"
                name="lastname"
                label="Votre nom"
                type="text"
                value={form.lastname}
                onChange={handleLastNameChange}
                onBlur={handleLastNameValidation}
                errormessage={getErrorMessages(lastNameErrors)[0]}
              />
              <BaseInput
                ref={firstNameInputRef}
                placeholder="Indiquez votre prénom"
                name="firstname"
                label="Votre prénom"
                type="text"
                value={form.firstname}
                onChange={handleFirstNameChange}
                onBlur={handleFirstNameValidation}
                errormessage={getErrorMessages(firstNameErrors)[0]}
              />
            </div>
            <div className={'flex justify-center gap-4'}>
              <BaseButton color={'tertiary'} className={'self-center'} label="Précédent" onClick={handlePreviousStep} />
              <BaseButton className={'self-center'} label="Suivant" onClick={(e) => handleNextStep(e)} />
            </div>
          </div>
        )
      default:
        return (
          <div className={'flex flex-col gap-9'}>
            <div className={'flex flex-col gap-6'}>
              <BaseInput
                ref={emailInputRef}
                placeholder="Renseignez votre email"
                name="email"
                label="Adresse email"
                type={'email'}
                value={form.email}
                onChange={handleEmailChange}
                onBlur={handleEmailValidation}
                errormessage={getErrorMessages(emailErrors)[0]}
              />
              <BaseInput
                placeholder="Choisissez un mot de passe"
                name="password"
                label="Mot de passe"
                type={passwordInputType}
                value={form.password}
                onChange={handlePasswordChange}
                onFocus={handlePasswordFocus}
                ref={passwordInputRef}
                errormessage={showPasswordRequirements ? '' : getErrorMessages(passwordSimpleErrors)[0]}
              >
                {passwordInputType === 'password' ? (
                  <TheIconHideEye
                    className={'w-6 fill-primary hover:cursor-pointer'}
                    onClick={() => togglePasswordInputType(passwordInputRef)}
                  />
                ) : (
                  <TheIconVisibleEye
                    className={'w-6 fill-primary hover:cursor-pointer'}
                    onClick={() => togglePasswordInputType(passwordInputRef)}
                  />
                )}
              </BaseInput>
              {showPasswordRequirements && <PasswordRequirements passwordErrors={passwordErrors} />}
              <BaseInput
                label={'Confirmez le mot de passe'}
                placeholder="Saisissez à nouveau le mot de passe"
                name="confirmPassword"
                value={form.confirmPassword}
                ref={passwordConfirmInputRef}
                type={passwordInputType}
                errormessage={confirmPasswordErrors.mismatch}
                onChange={handleConfirmPasswordChange}
              >
                {passwordInputType === 'password' ? (
                  <TheIconHideEye
                    className={'w-6 fill-primary hover:cursor-pointer'}
                    onClick={() => togglePasswordInputType(passwordConfirmInputRef)}
                  />
                ) : (
                  <TheIconVisibleEye
                    className={'w-6 fill-primary hover:cursor-pointer'}
                    onClick={() => togglePasswordInputType(passwordConfirmInputRef)}
                  />
                )}
              </BaseInput>
            </div>
            <BaseButton className={'self-center'} label="Suivant" onClick={(e) => handleNextStep(e)} />
          </div>
        )
    }
  }

  const renderLeftScreenSection = () => {
    switch (currentStep) {
      case 3:
        return (
          <div className={'flex h-full flex-col gap-9 overflow-auto'}>
            <div className={'flex flex-col gap-4'}>
              <p className={'text-2xl font-semibold text-label'}>
                Disponible 24h.24, 7j/7, en français, pour répondre à vos besoins à tout moment.
              </p>
              <p className={'text-sm font-normal text-primary'}>
                Une mise à jour régulière, intégrant de nouvelles sources.
              </p>
            </div>
            <div className={'relative w-[98%]'}>
              <img
                src={'/assets/images/inscription-screenshot-4.png'}
                alt={'placeholder'}
                className={'w-auto max-w-[80%] self-stretch rounded-xl shadow-SubtleDepthShadow'}
              />
              <img
                src={'/assets/images/inscription-screenshot-5.png'}
                alt={'placeholder'}
                className={
                  'absolute right-0 top-1/2 h-4/5 w-auto max-w-full -translate-y-1/2 transform self-stretch rounded-xl shadow-subtleBoxShadow'
                }
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div className={'mt-28 flex flex-col gap-12 overflow-auto'}>
            <p className={'text-2xl font-semibold text-label'}>Utilisé par plus de 300 cabinets de DPO.</p>
            <div className={'relative w-[98%]'}>
              <img
                src={'/assets/images/inscription-screenshot-3.png'}
                alt={'placeholder'}
                className={'w-auto max-w-full self-stretch rounded-xl shadow-SubtleDepthShadow'}
              />
            </div>
          </div>
        )
      default:
        return (
          <div className={'flex h-full flex-col gap-9 overflow-auto'}>
            <div className={'flex flex-col gap-4'}>
              <p className={'text-2xl font-normal text-label'}>
                Une mémoire riche de{' '}
                <span className={'text-2xl font-semibold text-label'}>
                  1 million d’heures de références juridiques.
                </span>
              </p>
              <p className={'text-sm font-normal'}>
                Des avis, des règlements, des arrêtés, des jurisprudences et des conclusions{' '}
                <span className={'text-sm font-semibold'}>CNIL, CEPD, CJUE.</span>
              </p>
            </div>
            <div className={'relative w-[98%]'}>
              <img
                src={'/assets/images/inscription-screenshot-1.jpg'}
                alt={'placeholder'}
                className={'w-auto max-w-[90%] self-stretch rounded-xl shadow-SubtleDepthShadow'}
              />
              <img
                src={'/assets/images/inscription-screenshot-2.png'}
                alt={'placeholder'}
                className={
                  'absolute right-0 top-1/2 h-4/5 w-auto max-w-full -translate-y-1/2 transform self-stretch rounded-xl shadow-subtleBoxShadow'
                }
              />
            </div>
          </div>
        )
    }
  }

  return (
    <>
      <div style={gradientStyle} className={`hidden w-1/2 flex-col gap-14 rounded-2xl pl-8 pr-7 pt-6 sm:flex`}>
        <div>
          <TheIconOlympeLogoWithText />
        </div>
        {renderLeftScreenSection()}
      </div>
      <div className={'flex h-full w-full flex-col items-center pt-8 sm:w-1/2 sm:px-4 md:pl-8 md:pr-6 xl:px-28'}>
        <div className={'mb-2 flex h-full w-full max-w-[500px] flex-col overflow-auto px-2'}>
          <BaseToaster message={toasterMessageElement}>
            <TheIconExclamationMark width="24" height="24" />
          </BaseToaster>
          <div className={'mb-7 flex flex-col items-center gap-4'}>
            <TheIconOlympeLogoWithoutText width="41" height="40" className={'fill-primary'} />
            <h1 className="h-14 text-center text-4xl font-semibold text-primary">{getPageTitle()}</h1>
          </div>

          <form className={'mb-12 flex h-2/3 flex-col gap-4'}>{renderRightScreenSection()}</form>
        </div>
        <div className={'flex flex-col gap-10'}>
          <div className="mt-4 flex flex-col gap-10">
            <Link to={ROUTE_PATHS.login}>
              <p className={'text-center text-sm text-label hover:opacity-70'}>Vous avez déjà un compte ?</p>
              <p className={'@text-primary text-center text-sm font-semibold hover:opacity-70'}>Connectez-vous</p>
            </Link>
          </div>
          <TheEllipseStepper steps={steps} />
        </div>
      </div>
    </>
  )
}
