import { TheStarRating } from './TheStarRating'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { TEMPORARY_AI_ID } from '../utils/CONVERSATION_CONSTANTS.ts'
import { nanoid } from '@reduxjs/toolkit'
import { TheAddedDocumentTag } from './TheAddedDocumentTag.tsx'
import { TheSourcesListItem } from './TheSourcesListItem.tsx'
import { BaseModal } from './atomics/BaseModal.tsx'
import { SourceReadingInfos } from '../types/source.ts'
import { updateMessageById } from '../store/messages/useCases/updateMessageById.ts'
import { useAppDispatch, useAppSelector } from '../store/hooks.ts'
import { useParams } from 'react-router-dom'
import { Conversation } from '../types/conversation.ts'
import { Message } from '../types/message.ts'
import { BaseButton } from './atomics/BaseButton.tsx'
import { TheIconAvatarHuman } from './icons/TheIconAvatarHuman.tsx'
import { TheIconAvatarAI } from './icons/TheIconAvatarAI.tsx'
import { useDetectScreenSize } from '../utils/useDetectScreenSize.ts'
import { selectConversationById } from '../store/conversations/conversationsSlice.ts'

type Props = { message: Message }

export function TheMessageItem(props: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const { conversationId } = useParams() as { conversationId: Conversation['id'] }
  const conversation = useAppSelector(selectConversationById(conversationId))
  const [isSourcesListVisible, setIsSourcesListVisible] = useState(false)
  const isMobile = useDetectScreenSize()

  const wrapperClass = () => {
    return props.message.type === 'HUMAN' ? 'flex-col items-end sm:flex-row-reverse' : 'flex-col sm:flex-row'
  }

  const contentClass = () => {
    return props.message.type === 'HUMAN' ? 'text-right' : 'text-left prose'
  }

  const ctaDisplayClasses = props.message.id === TEMPORARY_AI_ID ? 'hidden' : ''

  const mobileAnimationClass = 'translate-y-full'
  const desktopAnimationClass = 'translate-x-full'
  const getAnimationClass = () => {
    return isMobile ? mobileAnimationClass : desktopAnimationClass
  }
  const getPositionClass = () => {
    return isMobile ? 'bottom-0' : 'right-0'
  }
  const toggleSourcesList = () => {
    setIsSourcesListVisible(!isSourcesListVisible)
  }
  const updateRating = (rating: number) => {
    const messageUpdated = {
      ...props.message,
      rating,
    }

    dispatch(updateMessageById({ conversationId, message: messageUpdated }))
  }

  const renderCTASection = () => {
    return (
      <>
        <BaseButton
          className={'w-full sm:w-fit'}
          label={`Voir les ${props.message.sources.length} sources officielles`}
          onClick={toggleSourcesList}
          disabled={props.message.sources.length < 1}
        />
        <div className="mb-2 mt-3">
          <p className="mb-2 text-center text-base text-label sm:text-left sm:text-sm">
            Que pensez-vous de cette réponse ?
          </p>
          <TheStarRating rating={props.message.rating ?? 0} updateRating={updateRating} />
        </div>
      </>
    )
  }

  const renderAvatar = () => {
    return props.message.type === 'HUMAN' ? (
      <TheIconAvatarHuman width="38" height="38" />
    ) : (
      <TheIconAvatarAI width="38" height="38" />
    )
  }

  const calculateContainerStyleBasedOnScreenSize = () => {
    return isMobile ? 'z-10 h-4/5 w-screen rounded-t-lg bg-brightGray shadow-softOutline' : 'h-full w-96 bg-brightGray'
  }

  return (
    <>
      {props.message.type === 'SYSTEM' ? (
        <div
          className={`mr-0 flex w-full rounded-lg bg-brightGray py-3 sm:justify-normal lg:ml-auto lg:w-3/4 lg:gap-4 ${wrapperClass()}`}
        >
          <div className="m-1 mr-2 h-fit w-fit px-4 py-3">{renderAvatar()}</div>
          <div className={'flex flex-col gap-4 text-center'}>
            <ReactMarkdown className={`${contentClass()} text-center text-sm leading-8 sm:text-left`}>
              {props.message.content}
            </ReactMarkdown>
            <div className={'flex flex-col justify-between gap-2 lg:flex-row lg:gap-0'}>
              {conversation?.currentFilesInfos.length &&
                conversation?.currentFilesInfos.map((fileInfos) => (
                  <TheAddedDocumentTag
                    key={nanoid()}
                    fileInfos={{
                      ...fileInfos,
                      uploadStatus: 'UPLOADED',
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={'my-4'}>
          <div className={`flex gap-2 py-4 sm:gap-4 ${wrapperClass()}`}>
            <div className="m-0 h-fit w-fit p-0">{renderAvatar()}</div>
            <div className="w-full">
              <ReactMarkdown className={`${contentClass()} text-base leading-6`}>{props.message.content}</ReactMarkdown>
            </div>
          </div>
          <div className={`w-full sm:mb-4 sm:ml-14 sm:w-fit ${ctaDisplayClasses}`}>
            {props.message.type !== 'HUMAN' && renderCTASection()}
          </div>
          <BaseModal
            contentVisible={isSourcesListVisible}
            onClick={toggleSourcesList}
            positionClass={getPositionClass()}
            styleBasedOnScreenSize={calculateContainerStyleBasedOnScreenSize()}
            animationClass={getAnimationClass()}
          >
            <div className={'my-5 flex flex-col items-center justify-center'}>
              {props.message.sources.map((source: SourceReadingInfos) => (
                <TheSourcesListItem
                  key={props.message.sources.indexOf(source)}
                  sourceNumber={props.message.sources.indexOf(source) + 1}
                  source={source}
                />
              ))}
            </div>
          </BaseModal>
        </div>
      )}
    </>
  )
}
