export function TheIconMinimalistHarmony() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="40" height="40" rx="20" fill="#E8ECF1" />
      <rect x="4" y="4" width="40" height="40" rx="20" stroke="#C5CFE0" strokeOpacity="0.15" strokeWidth="8" />
      <circle cx="16" cy="24" r="2" fill="#6E7682" />
      <circle cx="24" cy="24" r="2" fill="#6E7682" />
      <circle cx="32" cy="24" r="2" fill="#6E7682" />
    </svg>
  )
}
