import { LoaderFunction, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TheSidebarDesktop } from '../components/sidebar/TheSidebarDesktop.tsx'
import { TheSidebarMobile } from '../components/sidebar/TheSidebarMobile.tsx'
import { TheHeaderMobile } from '../components/TheHeaderMobile.tsx'
import { FormEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks.ts'
import { useSelector } from 'react-redux'
import { selectAllConversations } from '../store/conversations/conversationsSlice.ts'
import { AppStore } from '../store/configureStore.ts'
import { listUserConversations } from '../store/conversations/./use Cases/listUserConversations.ts'
import { logoutUser } from '../store/auth/useCases/logoutUser.ts'
import { BaseModal } from '../components/atomics/BaseModal.tsx'
import BaseInput from '../components/atomics/BaseInput.tsx'
import { BaseButton } from '../components/atomics/BaseButton.tsx'
import { createTag } from '../store/tags/useCases/createTag.ts'
import { selectShowAddTagModal, toggleAddTagsModal } from '../store/tags/tagSlice.ts'
import { TheSidebarFooter } from '../components/sidebar/TheSidebarFooter.tsx'
import { OnboardingCard } from '../components/onboarding/OnboardingCard.tsx'
import { selectCurrentOnBoardingStep } from '../store/productTour/productTourSlice.ts'
import { BEGIN, ONBOARDING_TOUR } from '../utils/CONVERSATION_CONSTANTS.ts'

export const layoutLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async () => {
    store.dispatch(listUserConversations())
    return null
  }

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement
  password: HTMLInputElement
}

interface AddTagFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

export function DefaultLayout() {
  const dispatch = useAppDispatch()
  const conversations = useSelector(selectAllConversations)
  const [animationSidebar, setAnimationSidebar] = useState('')
  const currentOnboardingStep = useAppSelector(selectCurrentOnBoardingStep)
  const navigate = useNavigate()
  const location = useLocation()
  const displayAddTagModal: boolean = useAppSelector(selectShowAddTagModal)
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const lastConversation = conversations[0]
    if (location.pathname === '/') navigate(`/conversations/${lastConversation.id}`)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setAnimationSidebar('animation-slide-out-sidebar-mobile')
  }

  const handleSignout = async () => {
    await dispatch(logoutUser())
  }

  const handleSidebar = () => {
    setAnimationSidebar('animation-slide-in-sidebar-mobile')
  }

  const validate = (value: string) => {
    if (value.length === 0) {
      setErrorMessage('Veuillez saisir un nom')
    } else {
      setErrorMessage('')
    }
  }

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
    validate(e.currentTarget.value)
  }

  const handleCancel = () => {
    dispatch(toggleAddTagsModal())
    setInputValue('')
    setErrorMessage('')
  }

  const handleSubmit = (e: FormEvent<AddTagFormElement>) => {
    e.preventDefault()
    validate(inputValue)
    if (inputValue.length === 0) return
    dispatch(createTag(inputValue))
    dispatch(toggleAddTagsModal())
    setErrorMessage('')
    setInputValue('')
  }

  return (
    <div className="sm:flex-row">
      <TheSidebarDesktop>
        <TheSidebarFooter onLogout={handleSignout} />
      </TheSidebarDesktop>
      <TheSidebarMobile
        additionalClasses={animationSidebar}
        conversations={conversations}
        onClose={handleClose}
        onSignout={handleSignout}
      />
      <TheHeaderMobile onHandleSidebar={handleSidebar} />
      <Outlet />
      <BaseModal contentVisible={displayAddTagModal} onClick={handleCancel} animationClass={'translate-x-full'}>
        <form
          onSubmit={handleSubmit}
          className={
            'fixed left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 transform flex-col gap-5 rounded-lg bg-bright px-5 pb-4 pt-5'
          }
        >
          <div className={'flex flex-col gap-3'}>
            <h4>Nouveau dossier</h4>
            <BaseInput onChange={handleChange} name={'label'} value={inputValue} placeholder={'Ex. : Droit suisse'} />
            {errorMessage.length > 0 && <span className={'text-center text-error'}>{errorMessage}</span>}
          </div>
          <div className={'flex flex-col items-center gap-3'}>
            <BaseButton type={'submit'} size={'large'} label={'Créer'} />
            <span onClick={handleCancel} className={'text-xs font-semibold text-primary hover:cursor-pointer'}>
              Annuler
            </span>
          </div>
        </form>
      </BaseModal>

      {(currentOnboardingStep === BEGIN || currentOnboardingStep === ONBOARDING_TOUR) && (
        <>
          <div className={'fixed bottom-0 right-0 z-10 h-screen w-screen bg-[#0000001a]'} />
          <div className={'fixed left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 '}>
            <OnboardingCard />
          </div>
        </>
      )}
    </div>
  )
}
