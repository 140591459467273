import { SVGProps } from 'react'
import { JSX } from 'react/jsx-runtime' // adding props is needed since TheIconBin will be used in various contexts,

// adding props is needed since TheIconBin will be used in various contexts,
// including the BaseButton context, which injects use Cases props programmatically
export function TheIconBin(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg className={props.className} viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.42179 8.01458C1.42179 6.868 1.42179 5.72155 1.42179 4.57522C1.42179 4.20952 1.61656 3.95909 1.93872 3.90067C2.02396 3.88527 2.11147 3.88792 2.19562 3.90845C2.27976 3.92899 2.35866 3.96695 2.42721 4.01988C2.49576 4.07281 2.55243 4.13954 2.59357 4.21574C2.6347 4.29195 2.65939 4.37594 2.66602 4.46228C2.67069 4.51992 2.66835 4.57912 2.66835 4.63676V11.3558C2.66835 12.0825 3.18022 12.5974 3.90323 12.5978C5.56973 12.5978 7.23637 12.5978 8.90313 12.5978C9.61757 12.5978 10.1329 12.081 10.1329 11.3659C10.1329 9.09742 10.1329 6.82866 10.1329 4.55964C10.1329 4.23405 10.3117 3.9848 10.5926 3.91236C10.676 3.88962 10.7633 3.88466 10.8488 3.89779C10.9343 3.91093 11.016 3.94187 11.0888 3.98861C11.1615 4.03535 11.2237 4.09684 11.2711 4.16912C11.3186 4.24139 11.3503 4.32282 11.3643 4.40814C11.3752 4.48006 11.38 4.55275 11.3787 4.62546C11.3787 6.87008 11.3787 9.11443 11.3787 11.3585C11.3787 12.5943 10.5607 13.5827 9.34683 13.8059C9.19853 13.8317 9.04819 13.844 8.89767 13.8425C7.23065 13.8451 5.56389 13.8451 3.89739 13.8425C2.50007 13.8425 1.42257 12.7641 1.4214 11.3671C1.42062 10.2496 1.42075 9.13208 1.42179 8.01458Z" />
      <path d="M3.91486 2.021C3.91486 1.61206 3.91174 1.22416 3.9172 0.836638C3.91442 0.735205 3.93067 0.634148 3.96511 0.538698C4.07185 0.285157 4.27675 0.157802 4.54944 0.157023C5.78328 0.153388 7.01686 0.153388 8.25018 0.157023C8.63194 0.157023 8.88086 0.421469 8.88476 0.807039C8.88788 1.15171 8.88476 1.49639 8.88476 1.84146V2.02061H9.06629C9.81423 2.02061 10.5623 2.02061 11.3105 2.02061C11.5084 2.02061 11.688 2.06735 11.8243 2.21768C12 2.41046 12.0483 2.63479 11.949 2.87977C11.852 3.11734 11.6658 3.24197 11.4122 3.2669C11.3783 3.27001 11.344 3.2669 11.3101 3.2669H1.48951C1.10463 3.2669 0.830778 3.03789 0.803899 2.69633C0.773903 2.3166 1.06217 2.02256 1.47393 2.02139C2.22226 2.01905 2.9702 2.02139 3.71813 2.02139L3.91486 2.021ZM5.16142 2.01126H7.63819V1.41266H5.16142V2.01126Z" />
      <path d="M4.53505 7.9337C4.53505 7.21007 4.5331 6.48684 4.53505 5.76322C4.53505 5.49565 4.66165 5.29586 4.90746 5.18837C5.15326 5.08087 5.37492 5.12449 5.57164 5.29508C5.63978 5.35449 5.69379 5.42836 5.72971 5.51131C5.76563 5.59426 5.78257 5.68418 5.77927 5.77451C5.78083 7.21163 5.78083 8.64876 5.77927 10.0859C5.77927 10.4617 5.50425 10.7328 5.15093 10.7301C4.7976 10.7273 4.53582 10.4508 4.53505 10.0746C4.53388 9.36109 4.53505 8.6472 4.53505 7.9337Z" />
      <path d="M8.26742 7.93155C8.26742 8.65478 8.26742 9.37841 8.26742 10.1016C8.27062 10.2389 8.22745 10.3732 8.14489 10.4829C8.06233 10.5925 7.9452 10.6712 7.81242 10.7061C7.54558 10.7813 7.25497 10.6769 7.12213 10.4335C7.062 10.3233 7.02914 10.2003 7.02631 10.0748C7.01981 8.64674 7.01877 7.2187 7.02319 5.79067C7.02319 5.4051 7.29354 5.12858 7.6527 5.13286C8.01187 5.13715 8.26781 5.41405 8.26819 5.80313C8.26871 6.51299 8.26845 7.22247 8.26742 7.93155Z" />
    </svg>
  )
}
