import { ReactElement } from 'react'

type Props = {
  message: string | ReactElement
  actionMessage?: string
  onClick?: () => void
  icon?: ReactElement
  color?: 'success' | 'danger'
  children: ReactElement
}

export function BaseToaster({ message, color = 'danger', children }: Readonly<Props>) {
  const renderBgColor = () => {
    return color === 'success' ? 'bg-mintMist' : 'bg-rose-100'
  }

  const renderPosition = () => {
    return color === 'success' ? 'bottom-10' : 'top-10'
  }

  return (
    <>
      {message && (
        <div
          className={`fixed left-0 right-0 z-20 m-2 m-auto items-center ${renderBgColor()} mx-auto flex h-12 w-fit items-center gap-4 rounded-lg px-4 ${renderPosition()} md:h-10`}
        >
          {children}
          <div className="py-2 text-base leading-tight text-neutral-800">
            <p className="text-sm font-light text-primary">{message}</p>
          </div>
        </div>
      )}
    </>
  )
}
