export function TheIconInteractiveShield() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="40" height="40" rx="20" fill="#E8ECF1" />
      <rect x="4" y="4" width="40" height="40" rx="20" stroke="#C5CFE0" strokeOpacity="0.4" strokeWidth="8" />
      <g clipPath="url(#clip0_1650_354)">
        <g clipPath="url(#clip1_1650_354)">
          <circle cx="24" cy="24" r="4" fill="#6E7682" />
        </g>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7938 14L23.0549 14C23.6106 14 24.061 14.4713 24.061 15.0526C24.061 15.634 23.6106 16.1053 23.0549 16.1053H19.8354C18.9736 16.1053 18.3877 16.1061 17.9349 16.1448C17.4938 16.1825 17.2682 16.2508 17.1109 16.3347C16.7323 16.5366 16.4244 16.8586 16.2315 17.2548C16.1513 17.4194 16.086 17.6554 16.05 18.1169C16.013 18.5907 16.0122 19.2036 16.0122 20.1053V27.8947C16.0122 28.7964 16.013 29.4093 16.05 29.8831C16.086 30.3446 16.1513 30.5806 16.2315 30.7452C16.4244 31.1414 16.7323 31.4634 17.1109 31.6653C17.2682 31.7492 17.4938 31.8175 17.9349 31.8552C18.3877 31.8939 18.9736 31.8947 19.8354 31.8947H23.0549C23.6106 31.8947 24.061 32.366 24.061 32.9474C24.061 33.5287 23.6106 34 23.0549 34H19.7938C18.9839 34 18.3155 34 17.7711 33.9535C17.2055 33.9051 16.6857 33.8014 16.1974 33.5411C15.4401 33.1374 14.8245 32.4933 14.4386 31.701C14.1898 31.1901 14.0907 30.6462 14.0445 30.0546C14 29.4849 14 28.7855 14 27.9382V20.0618C14 19.2145 14 18.5151 14.0445 17.9454C14.0907 17.3538 14.1898 16.8099 14.4386 16.299C14.8245 15.5067 15.4401 14.8626 16.1974 14.4589C16.6857 14.1986 17.2055 14.0949 17.7711 14.0465C18.3155 14 18.984 14 19.7938 14Z"
        fill="#6E7682"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2062 14L23.0549 14C22.4993 14 22.0488 14.4713 22.0488 15.0526C22.0488 15.634 22.4993 16.1053 23.0549 16.1053H28.1646C29.0264 16.1053 29.6123 16.1061 30.0651 16.1448C30.5062 16.1825 30.7318 16.2508 30.8891 16.3347C31.2677 16.5366 31.5756 16.8586 31.7685 17.2548C31.8487 17.4194 31.914 17.6554 31.95 18.1169C31.987 18.5907 31.9878 19.2036 31.9878 20.1053V27.8947C31.9878 28.7964 31.987 29.4093 31.95 29.8831C31.914 30.3446 31.8487 30.5806 31.7685 30.7452C31.5756 31.1414 31.2677 31.4634 30.8891 31.6653C30.7318 31.7492 30.5062 31.8175 30.0651 31.8552C29.6123 31.8939 29.0264 31.8947 28.1646 31.8947H23.0549C22.4993 31.8947 22.0488 32.366 22.0488 32.9474C22.0488 33.5287 22.4993 34 23.0549 34H28.2062C29.0161 34 29.6845 34 30.229 33.9535C30.7945 33.9051 31.3143 33.8014 31.8026 33.5411C32.5599 33.1374 33.1755 32.4933 33.5614 31.701C33.8102 31.1901 33.9093 30.6462 33.9555 30.0546C34 29.4849 34 28.7855 34 27.9382V20.0618C34 19.2145 34 18.5151 33.9555 17.9454C33.9093 17.3538 33.8102 16.8099 33.5614 16.299C33.1755 15.5067 32.5599 14.8626 31.8026 14.4589C31.3143 14.1986 30.7945 14.0949 30.229 14.0465C29.6845 14 29.016 14 28.2062 14Z"
        fill="#6E7682"
      />
      <defs>
        <clipPath id="clip0_1650_354">
          <rect width="39" height="39" fill="white" transform="translate(6 7)" />
        </clipPath>
        <clipPath id="clip1_1650_354">
          <rect width="77" height="77" fill="white" transform="translate(-16 -15)" />
        </clipPath>
      </defs>
    </svg>
  )
}
