import { ForgottenPasswordForm } from '../components/ForgottenPasswordForm.tsx'
import { EmailError } from '../components/EmailError.tsx'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

export function ResetPasswordVerification() {
  const [searchParams] = useSearchParams()
  const [token, setToken] = useState('')
  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      setToken(token)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderFormOrError = () => {
    return token ? <ForgottenPasswordForm token={token} /> : <EmailError tokenIssue={'invalid'} />
  }
  return <>{renderFormOrError()}</>
}
