import { ProductTourTooltipFooter } from './ProductTourTooltipFooter.tsx'
import { useTooltipHook } from './use-tooltip.hook.ts'
import { ProductTourTooltipArrowIcon } from './ProductTourTooltipArrowIcon.tsx'
import { ProductTourTooltipBody } from './__tests__/ProductTourTooltipBody.tsx'

export function ProductTourTooltip() {
  const { currentTooltipPosition, tooltipRef } = useTooltipHook()

  return (
    <div
      className={`absolute z-10 flex w-[350px] flex-col gap-2 rounded-2xl bg-primary py-3 pl-5 pr-3`}
      style={currentTooltipPosition}
      ref={tooltipRef}
    >
      <div className={'relative'}>
        <ProductTourTooltipBody />
        <ProductTourTooltipFooter />
        <ProductTourTooltipArrowIcon />
      </div>
    </div>
  )
}
