import { ReactNode, useEffect, useRef, useState } from 'react'

interface Props {
  children: ReactNode
}
export function AuthLayout({ children }: Readonly<Props>) {
  const LegalMentionsRef = useRef<HTMLDivElement | null>(null)
  const [isMobile, setIsMobile] = useState(false)
  // Adjust the left position on component mount and when the window is resized
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    adjustLeftPosition()
    window.addEventListener('resize', adjustLeftPosition)
    return () => window.removeEventListener('resize', adjustLeftPosition)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Function to calculate and set the left property
  const adjustLeftPosition = () => {
    if (!LegalMentionsRef.current) return
    const textWidth = LegalMentionsRef.current?.querySelector('a')?.offsetWidth
    if (!textWidth) return
    if (isMobile) {
      LegalMentionsRef.current.style.left = `calc(50% - ${textWidth / 2}px)`
    } else {
      LegalMentionsRef.current.style.left = `calc(25% - ${textWidth / 2}px)`
    }
  }

  return (
    <div className={'relative flex h-screen p-7'}>
      {children}
      <div ref={LegalMentionsRef} className={'absolute bottom-1 hover:cursor-pointer'}>
        <a
          className={'text-sm font-semibold text-primary hover:opacity-70'}
          href={import.meta.env.VITE_OLYMPE_LEGAL_MENTIONS_URL}
          target={'_blank'}
          rel="noreferrer"
        >
          Mentions légales
        </a>
      </div>
    </div>
  )
}
