import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const createUser = createAppAsyncThunk(
  'auth/createUser',
  async (
    payload: {
      email: string
      password: string
      firstname: string
      lastname: string
      jobTitle: string
      cgu: boolean
    },
    { extra: { authGateway } },
  ) => {
    try {
      return await authGateway.createUser(payload)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
