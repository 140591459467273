export const ROUTE_PATHS = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  resetPasswordInfo: '/reset-password-info',
  resetPasswordVerification: '/reset-password-verification',
  resetPasswordConfirmation: '/reset-password-confirmation',
  home: '/',
  greetingUser: '/greeting-user',
  conversationSingle: '/conversations/:conversationId',
  documents: '/documents',
  accountVerificationInfo: '/account-verification-info',
  emailVerification: '/email-verification',
  resetPasswordTokenExpiredError: '/reset-password-token-expired-error',
  resetPasswordTokenInvalidError: '/reset-password-token-invalid-error',
  unexpectedError: '/unexpected-error',
} as const
