import React, { useRef, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { selectAuthErrorMessage } from '../../store/auth/authSlice.ts'
import { getCurrentUser } from '../../store/auth/useCases/getCurrentUser.ts'
import { logUser } from '../../store/auth/useCases/logUser.ts'
import { AppDispatch } from '../../store/configureStore.ts'
import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import { resetRegisterStepper } from '../../store/stepper/steppersSlice.ts'
import {
  EmailErrors,
  PasswordSimpleErrors,
  hasAnyErrors,
  validateEmail,
  validatePasswordSimple,
} from '../../utils/formValidation.ts'
import { useToaster } from '../../utils/useToaster.ts'

type FormKey = 'email' | 'password'

const loginFormInitialState = {
  email: '',
  password: '',
}
const emailErrorsInitialState: EmailErrors = { emptyEmail: '', invalidFormat: '' }
const passwordSimpleErrorsInitialState: PasswordSimpleErrors = {
  emptyPassword: '',
}

const TRUSTED_ORIGINS = (import.meta.env.VITE_OLYMPE_TRUSTED_ORIGINS as string).split(',')
const EVENT_TYPE_LOGIN = 'olympe.login' as const
type OlympeLoginEvent = {
  eventType: typeof EVENT_TYPE_LOGIN
  eventData: {
    email: string
    password: string
  }
}
type OlympeNoopEvent = {
  eventType: undefined
}
type OlympeEvent = OlympeLoginEvent | OlympeNoopEvent

export const useLoginHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { showToast, toasterMessageElement } = useToaster()

  // Form values local state
  const [form, setForm] = useState(loginFormInitialState)

  // Errors local state
  const loginErrorMessage = useAppSelector(selectAuthErrorMessage)
  const [passwordSimpleErrors, setPasswordSimpleErrors] = useState<PasswordSimpleErrors>(
    passwordSimpleErrorsInitialState,
  )
  const [emailErrors, setEmailErrors] = useState<EmailErrors>(emailErrorsInitialState)

  // Refs
  const LegalMentionsRef = useRef<HTMLDivElement | null>(null)

  // Update state functions
  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    getHandler('email', e)
    setEmailErrors(validateEmail(e.target.value))
  }
  function handlePasswordChange(e: React.FocusEvent<HTMLInputElement>) {
    getHandler('password', e)
    setPasswordSimpleErrors(validatePasswordSimple(e.target.value))
  }

  // Validation functions for each input
  function handleEmailValidation(e: React.FocusEvent<HTMLInputElement>) {
    const emailErrors = validateEmail(e.target.value)
    setEmailErrors(emailErrors)
  }

  function handlePasswordValidation(e: React.FocusEvent<HTMLInputElement>) {
    const passwordErrors = validatePasswordSimple(e.target.value)
    setPasswordSimpleErrors(passwordErrors)
  }

  // Utils
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text'>('password')

  const togglePasswordInputType = () => {
    passwordInputType === 'password' ? setPasswordInputType('text') : setPasswordInputType('password')
  }

  const enableLoginFromParentWindow = () => {
    // do not add "message" event listener if no trusted origin is configured
    if (!TRUSTED_ORIGINS.length) return

    window.addEventListener('message', (event: MessageEvent<OlympeEvent>) => {
      if (TRUSTED_ORIGINS.includes(event.origin) && event.data.eventType === EVENT_TYPE_LOGIN) {
        const email = event.data.eventData.email
        const password = event.data.eventData.password
        const emailErrors = validateEmail(email)
        const passwordSimpleErrors = validatePasswordSimple(password)
        if (hasAnyErrors(emailErrors, passwordSimpleErrors)) {
          navigate(ROUTE_PATHS.unexpectedError)
          return
        }

        logUserAndRedirect({
          dispatch,
          navigate,
          showToast,
          email,
          password,
        })
      }
    })
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    setPasswordInputType('password')
    e.preventDefault()
    setEmailErrors(validateEmail(form.email))
    setPasswordSimpleErrors(validatePasswordSimple(form.password))

    if (form.email.length && form.password.length && !hasAnyErrors(emailErrors, passwordSimpleErrors)) {
      await logUserAndRedirect({ dispatch, navigate, showToast, email: form.email, password: form.password })
    }
  }

  const handleResetRegisterStepper = () => {
    dispatch(resetRegisterStepper())
  }

  const getHandler = (name: FormKey, e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [name]: e.target.value })
  }

  return {
    form,
    LegalMentionsRef,
    loginErrorMessage,
    passwordInputType,
    emailErrors,
    passwordSimpleErrors,
    enableLoginFromParentWindow,
    handleSubmit,
    togglePasswordInputType,
    handleEmailChange,
    handlePasswordChange,
    handleEmailValidation,
    handlePasswordValidation,
    handleResetRegisterStepper,
    toasterMessageElement,
  }
}

async function logUserAndRedirect({
  dispatch,
  navigate,
  showToast,
  email,
  password,
}: {
  dispatch: AppDispatch
  navigate: NavigateFunction
  showToast: ReturnType<typeof useToaster>['showToast']
  email: string
  password: string
}) {
  const response = await dispatch(logUser({ email, password }))
  if (logUser.fulfilled.match(response)) {
    const currentUserResponse = await dispatch(getCurrentUser())

    if (getCurrentUser.fulfilled.match(currentUserResponse)) {
      const { emailValidated, firstname } = currentUserResponse.payload
      if (emailValidated) {
        navigate(`/greeting-user?name=${firstname}`)
      } else {
        navigate(`/account-verification-info?email=${email}`)
      }
    }
  } else {
    showToast(response.error.message ?? '')
  }
}
