import { TheEllipseIcon } from './icons/TheEllipsisIcon.tsx'
import { Step } from '../store/stepper/steppersSlice.ts'

interface Props {
  steps: Step[]
}

export function TheEllipseStepper({ steps }: Readonly<Props>) {
  return (
    <div className={'flex justify-center gap-4'}>
      {steps.map((step) => (
        <TheEllipseIcon isActive={step.isActive} key={step.id} />
      ))}
    </div>
  )
}
