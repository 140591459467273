import { UpdateCurrentStepResponse } from '../../types/productTour.ts'
import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import {
  hideProductTour,
  selectArrowPositions,
  selectCurrentOnBoardingStep,
  selectNextStep,
  selectPreviousStep,
  selectTooltipPositionByTitle,
  setTooltipArrowPositions,
} from '../../store/productTour/productTourSlice.ts'
import { updateCurrentStep } from '../../store/productTour/useCases/updateCurrentStep.ts'
import {
  END,
  PRODUCT_TOUR_FILE_COMPARISON,
  PRODUCT_TOUR_SINGLE_FILE,
  PRODUCT_TOUR_USER_FILES,
} from '../../utils/CONVERSATION_CONSTANTS.ts'
import { useEffect, useRef } from 'react'

const steps: Record<
  UpdateCurrentStepResponse['data']['step'],
  { subtitle: string; description: string; title: UpdateCurrentStepResponse['data']['step'] }
> = {
  BEGIN: {
    title: 'BEGIN',
    subtitle: '',
    description: '',
  },
  ONBOARDING_TOUR: {
    title: 'ONBOARDING_TOUR',
    subtitle: '',
    description: '',
  },
  PRODUCT_TOUR_SINGLE_FILE: {
    title: PRODUCT_TOUR_SINGLE_FILE,
    subtitle: 'Interrogez Olympe sur un de vos documents.',
    description:
      'Une question sur un de vos documents ? Posez votre question, joignez votre document et obtenez une réponse.',
  },
  PRODUCT_TOUR_MESSAGE_ONLY: {
    title: 'PRODUCT_TOUR_MESSAGE_ONLY',
    subtitle: 'Discutez en direct, obtenez des réponses instantanées. ',
    description:
      'Posez des questions en direct pour des réponses immédiates à toutes vos questions sur la conformité RGPD.',
  },
  PRODUCT_TOUR_FILE_COMPARISON: {
    title: PRODUCT_TOUR_FILE_COMPARISON,
    subtitle: 'Analyse comparée',
    description: 'Téléchargez deux documents pour une analyse comparée lors de votre conversation.',
  },
  PRODUCT_TOUR_USER_FILES: {
    title: PRODUCT_TOUR_USER_FILES,
    subtitle: 'Un espace dédié à vos documents juridiques',
    description:
      'Déposez ici vos documents juridiques afin de tous les retrouver au même endroit.\n' +
      'Vous pourrez aussi les joindre dans votre conversation avec Olympe.',
  },
  END: {
    title: END,
    subtitle: '',
    description: '',
  },
}

export const useTooltipHook = () => {
  const dispatch = useAppDispatch()
  const step: UpdateCurrentStepResponse['data']['step'] = useAppSelector(selectCurrentOnBoardingStep)
  const nextStep: UpdateCurrentStepResponse['data']['step'] = useAppSelector(selectNextStep)
  const previousStep: UpdateCurrentStepResponse['data']['step'] = useAppSelector(selectPreviousStep)
  const currentTooltipPosition = useAppSelector(selectTooltipPositionByTitle(steps[step].title))
  const currentArrowPosition = useAppSelector(selectArrowPositions())
  const subtitle = steps[step].subtitle
  const description = steps[step].description
  const tooltipRef = useRef<HTMLDivElement>(null)
  const arrowDimension = 40
  const arrowHalfDimension = arrowDimension / 2

  useEffect(() => {
    const targetRect = tooltipRef.current?.getBoundingClientRect()
    if (!targetRect) return
    const paddingAxisX = 32
    const paddingAxisY = 24
    const tooltipArrowPositions = {
      PRODUCT_TOUR_SINGLE_FILE: {
        top: -40,
        left: (targetRect?.width - paddingAxisX) / 2 - arrowHalfDimension,
      },
      PRODUCT_TOUR_FILE_COMPARISON: {
        bottom: -40,
        left: (targetRect?.width - paddingAxisX) / 2 - arrowHalfDimension,
      },
      PRODUCT_TOUR_USER_FILES: {
        top: (targetRect?.height - paddingAxisY) / 2 - arrowHalfDimension,
        left: -50,
      },
    }
    dispatch(setTooltipArrowPositions(tooltipArrowPositions))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = async () => {
    await dispatch(updateCurrentStep(nextStep))
  }

  const handlePrevious = async () => {
    await dispatch(updateCurrentStep(previousStep))
  }

  const handleCancel = async () => {
    dispatch(hideProductTour())
    await dispatch(updateCurrentStep(END))
  }

  const getDescriptionWidth = () => {
    return step === PRODUCT_TOUR_USER_FILES ? 'w-[100%]' : 'w-[90%]'
  }

  return {
    handleNext,
    handlePrevious,
    handleCancel,
    steps,
    step,
    currentTooltipPosition,
    subtitle,
    description,
    arrowDimension,
    arrowHalfDimension,
    tooltipRef,
    currentArrowPosition,
    descriptionWidth: getDescriptionWidth(),
  }
}
