import { OnboardingStepFour } from './steps/OnboardingStepFour.tsx'
import { useAppSelector } from '../../store/hooks.ts'
import { OnboardingStepZero } from './steps/OnboardingStepZero.tsx'
import { selectActiveOnboardingStep } from '../../store/stepper/steppersSlice.ts'
import { OnboardingStepOne } from './steps/OnboardingStepOne.tsx'
import { OnboardingStepTwo } from './steps/OnboardingStepTwo.tsx'
import { OnboardingStepThree } from './steps/OnboardingStepThree.tsx'

export function OnboardingCard() {
  const activeStep = useAppSelector(selectActiveOnboardingStep)
  switch (activeStep.id) {
    case 0:
      return <OnboardingStepZero />
    case 1:
      return <OnboardingStepOne />
    case 2:
      return <OnboardingStepTwo />
    case 3:
      return <OnboardingStepThree />
    case 4:
      return <OnboardingStepFour />
    default:
      return <OnboardingStepZero />
  }
}
