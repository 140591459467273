import { CreateConversationResponseData } from '../../../types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const createConversation = createAppAsyncThunk<CreateConversationResponseData>(
  'conversations/createConversation',
  async (_, { extra: { conversationGateway } }) => {
    try {
      return await conversationGateway.createConversation()
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
