import { ReactElement, Ref } from 'react'

interface Props {
  title: string
  subtitle: string
  children: ReactElement<SVGElement>
  size?: 'small' | 'large'
  isChecked: boolean
  disabled?: boolean
  ref?: Ref<HTMLInputElement>
}
export function CardWithIcon({ title, subtitle, children, isChecked, disabled, size = 'large' }: Readonly<Props>) {
  const sizeStyle = () => (size === 'large' ? 'w-full' : 'w-[15rem]')

  const checkboxStyle = () => {
    if (disabled) return 'bg-bInput'
    return isChecked ? 'bg-brightGray' : 'bg-bright'
  }
  const borderHoverStyle = () => {
    if (disabled) return 'hover:border-bInput hover:cursor-default'
    return isChecked ? 'hover:border-label hover:cursor-pointer' : 'hover:border-mistyAzure hover:cursor-pointer'
  }

  const titleColor = () => (disabled ? 'text-silver' : 'text-primary')

  const subtitleColor = () => (disabled ? 'text-silver' : 'text-[#667085]')

  return (
    <label
      className={`rounded-lg border border-solid border-[#EAECF0] ${borderHoverStyle()} ${checkboxStyle()} px-4 py-3.5 ${sizeStyle()}`}
    >
      <div className={'flex items-center'}>
        <div className={'mr-5'}>{children}</div>
        <div className={'flex flex-col justify-between'}>
          <p className={`text-base font-semibold leading-5 ${titleColor()}`}>{title}</p>
          <p className={`text-sm font-normal ${subtitleColor()}`}>{subtitle}</p>
        </div>
      </div>
    </label>
  )
}
