import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const resetPassword = createAppAsyncThunk(
  'auth/resetPassword',
  async ({ token, newPassword }: { token: string; newPassword: string }, { extra: { authGateway } }) => {
    try {
      return await authGateway.resetPassword(newPassword, token)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
