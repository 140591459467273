import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import { ReactNode } from 'react'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { TheIconFilterMobile } from './icons/TheIconFilterMobile.tsx'

export function TheInputMessageOnlyTypeMobile({
  isQuestionLoading,
  userInput,
  onInput,
  handleKeyDown,
  handleSend,
  handleDisplayFilterMobile,
  messages,
}: Readonly<TheConversationMessageInputProps>) {
  const renderSubmitOrLoadingIcon = (): ReactNode => {
    return isQuestionLoading ? (
      <TheDotAnimation />
    ) : (
      <div className={`flex w-1/4 items-center gap-4 ${messages?.length && 'justify-center'}`}>
        {!messages?.length && <TheIconFilterMobile onClick={handleDisplayFilterMobile} className={'h-7 w-7'} />}{' '}
        <TheIconArrowSubmission onClick={handleSend} disabled={!userInput.length} />
      </div>
    )
  }
  return (
    <div className={`flex w-full justify-between gap-2 bg-bright sm:hidden`}>
      <textarea
        rows={1}
        className="max-h-[200px] w-full resize-none rounded border border-solid border-fadedSilver bg-bright p-3 text-base leading-[18px] shadow-subtleElevation outline-none sm:my-0 sm:py-2.5"
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading}
        onInput={onInput}
        onKeyDown={handleKeyDown}
      />
      {renderSubmitOrLoadingIcon()}
    </div>
  )
}
