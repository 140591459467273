import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configureStore.ts'

export const genericErrorSlice = createSlice({
  name: 'genericError',
  initialState: {
    message: '',
  },
  reducers: {
    setGenericErrorMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
  },
})

export const { setGenericErrorMessage } = genericErrorSlice.actions

export const selectGenericErrorMessage = (state: RootState) => state.entities.genericError.message
