import { ProductTourTooltip } from './tooltip/ProductTourTooltip.tsx'

export function ProductTourWithOverlay({ condition }: Readonly<{ condition: boolean }>) {
  return (
    <>
      {condition && (
        <>
          <div className={'fixed bottom-0 right-0 z-10 h-screen w-screen bg-[#0000001a]'} />
          <ProductTourTooltip />
        </>
      )}
    </>
  )
}
