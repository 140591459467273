import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TheIconOlympeLogoWithText } from '../components/icons/TheIconOlympeLogoWithText.tsx'
import { navigateToFirstConversationOrCreateOneIfNoneExists } from '../store/conversations/conversationsSlice.ts'
import { useAppDispatch } from '../store/hooks.ts'

export function GreetingUser() {
  const [searchParams] = useSearchParams()
  const [name, setName] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const name = searchParams.get('name')
    if (!name) return
    setName(name)
    setTimeout(async () => {
      await navigateToFirstConversationOrCreateOneIfNoneExists(dispatch, navigate)
    }, 2000)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderCapitalizedName = () => {
    if (!name) return
    return name[0].toUpperCase() + name.slice(1)
  }

  return (
    <div className={'flex flex-col items-center justify-center gap-3'}>
      <TheIconOlympeLogoWithText />
      <div className={'flex flex-col items-center justify-center gap-2'}>
        <h1 className={'text-4xl font-semibold text-primary'}>Ravis de vous revoir,</h1>
        <h3 className={'text-4xl font-semibold text-label'}>{renderCapitalizedName()}</h3>
      </div>
    </div>
  )
}
