import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { AppRouter } from './main.tsx'
import { BaseToaster } from './components/atomics/BaseToaster.tsx'
import { ERROR_ACTIONS_MESSAGES } from './utils/errorMessages.ts'
import { TheIconError } from './components/icons/TheIconError.tsx'
import { useAppSelector } from './store/hooks.ts'
import { AppStore } from './store/configureStore.ts'
import { selectGenericErrorMessage } from './store/genericErrorStore.ts'
import { Crisp } from 'crisp-sdk-web'

export function App() {
  const crispWebsiteId = import.meta.env.VITE_CRISP_WEBSITE_ID
  if (crispWebsiteId) Crisp.configure(crispWebsiteId)

  function reload() {
    window.location.reload()
  }

  return (
    <BaseToaster
      onClick={reload}
      actionMessage={ERROR_ACTIONS_MESSAGES.REFRESH}
      message={useAppSelector(selectGenericErrorMessage)}
    >
      <TheIconError />
    </BaseToaster>
  )
}

type ProviderProps = { readonly store: AppStore; readonly router: AppRouter }

export function Provider({ store, router }: ProviderProps) {
  return (
    <ReduxProvider store={store}>
      <App />
      <RouterProvider router={router} />
    </ReduxProvider>
  )
}
