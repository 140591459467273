import { SVGProps, useEffect, useRef } from 'react'
import { JSX } from 'react/jsx-runtime'
import { selectTagSelectHeight, setTagSelectPositions } from '../../store/tags/tagSlice.ts'
import { useDispatch } from 'react-redux'
import { FileData } from '../../types/file.ts'
import { theTagSelectWidth } from '../TheTagsSelect.tsx'
import { useAppSelector } from '../../store/hooks.ts'

interface Props extends JSX.IntrinsicAttributes, SVGProps<SVGSVGElement> {
  itemId?: FileData['id']
}
export function TheIconTripleDotsVertical({ itemId, ...rest }: Readonly<Props>) {
  const dispatch = useDispatch()
  const targetRef = useRef<SVGSVGElement>(null)
  const tagSelectHeight = useAppSelector(selectTagSelectHeight)

  useEffect(() => {
    const targetRect = targetRef.current?.getBoundingClientRect()
    if (!targetRect || !itemId) return
    const theTagSelectWidthPixel = parseInt(theTagSelectWidth.split('-')[1], 10) * 4
    const iconWidth = targetRect.width // Height of your icon
    const top = targetRect.top - tagSelectHeight
    const left = targetRect.left + window.scrollX - theTagSelectWidthPixel + iconWidth / 2
    const position = {
      [itemId]: {
        top,
        left,
      },
    }
    dispatch(setTagSelectPositions(position))
  }, [dispatch, tagSelectHeight, window.scrollY]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <svg {...rest} ref={targetRef} viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 7 4.56006)" />
      <circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 7 9.58691)" />
      <circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 7 14.6143)" />
    </svg>
  )
}
