import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function ArrowIconLeft(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 18 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.7559 9.70508L2.99278 27.4662L33.7559 45.2272L33.7559 9.70508Z" fill="#212121" />
      <defs>
        <clipPath id="clip0_2629_10359">
          <rect width="54" height="18" fill="white" transform="matrix(0 1 -1 0 18 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
