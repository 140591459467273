import { EndpointApi, FetchResponse } from '../../types/shared.ts'
import { makeLeft, makeRight } from '../../utils/either.ts'
import { RefreshTokenError } from '../../types/user.ts'
import { setGenericErrorMessage } from '../../store/genericErrorStore.ts'
import { ERROR_MESSAGES } from '../../utils/errorMessages.ts'
import { AppStore } from '../../store/configureStore.ts'

// importing store directly in a non-component file is not recommended
// because it can lead to circular references
// the correct way to do it, according to Redux documentation
// is to create an injectStore function called in the entrypoint of the application
// in our case, it is in main.tsx
// See https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
let store: AppStore
export const injectStore = (_store: AppStore) => {
  store = _store
}

type ContentType = 'application/json' | 'multipart/form-data' | null
export class OlympeGptApiCaller {
  private prefix: string = '/v1/api'
  private baseUrl: string = import.meta.env.VITE_OLYMPE_GPT_API_URL as string
  private maxRetryAttempts = 3 // Maximum number of retry attempts for token refresh

  async fetchProtected<Error, T>(
    endpoint: string,
    options?: RequestInit,
    contentType: ContentType = 'application/json',
  ): FetchResponse<Error, T> {
    return this.fetchCustom<Error, T>(endpoint, options, contentType, 0)
  }

  async fetchPublic<Error, T>(
    endpoint: string,
    options?: RequestInit,
    contentType: ContentType = 'application/json',
  ): FetchResponse<Error, T> {
    return this.fetchCustom<Error, T>(endpoint, options, contentType, null)
  }

  async fetchStreamable(endpoint: string, options?: RequestInit, contentType: ContentType = 'application/json') {
    const headers = this.formatContentType(contentType)
    const requestOptions: RequestInit = {
      ...options,
      credentials: 'include',
      headers: {
        ...headers,
        ...(options?.headers ?? {}),
      },
    }

    const url = `${this.baseUrl}${this.prefix}${endpoint}`
    return fetch(url, requestOptions)
  }

  async fetchCustom<Error, T>(
    endpoint: string,
    options?: RequestInit,
    contentType: ContentType = 'application/json',
    retryCount: number | null = null,
  ): FetchResponse<Error, T> {
    const formatContentType = this.formatContentType(contentType)
    const requestOptions: RequestInit = {
      ...options,
      credentials: 'include',
      headers: {
        ...formatContentType,
        ...(options?.headers ?? {}),
      },
    }

    const url = `${this.baseUrl}${this.prefix}${endpoint}`
    const response = await fetch(url, requestOptions)
    if (response.status === 500) {
      store.dispatch(setGenericErrorMessage(ERROR_MESSAGES.GENERIC))
    } else if (retryCount !== null && response.status === 401 && retryCount < this.maxRetryAttempts) {
      // Token expired, try refreshing the token and retry the request
      await this.refreshToken()
      return this.fetchCustom(endpoint, options, contentType, retryCount + 1)
    } else if (retryCount !== null && response.status === 401 && retryCount >= this.maxRetryAttempts) {
      // Token refresh failed, redirect to log in
      window.location = (window.location.origin + '/login') as unknown as Location
    }
    if (!response.ok) {
      const responseText = await response.json()
      return makeLeft(responseText as unknown as Error)
    }
    // When responseType is not 'application/json', response.json throw an error
    // If not 'application/json', it is a file
    const isApplicationJson = this.checkContentType(response, 'application/json')
    const data = isApplicationJson ? (await response.json()).data : response

    return makeRight({
      status: response.status,
      data,
    })
  }

  async refreshToken() {
    const refreshTokenEndpoint: EndpointApi = '/auth/refresh-token'
    const url = `${this.baseUrl}${this.prefix}${refreshTokenEndpoint}`
    const response = await fetch(url, { credentials: 'include' })
    if (!response.ok) {
      const responseText = await response.json()
      return makeLeft(responseText as unknown as RefreshTokenError)
    }

    const isApplicationJson = this.checkContentType(response, 'application/json')
    const data = isApplicationJson ? (await response.json()).data : response

    return makeRight({
      status: response.status,
      data,
    })
  }

  private checkContentType(response: Response, contentType: string) {
    return (
      (response.headers.get('content-type') && response.headers.get('content-type')?.indexOf(contentType) !== -1) ||
      false
    )
  }

  /**
   * Should return the contentType except for multipart/form-data
   * See: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
   * @param contentType
   * @private
   */
  private formatContentType(contentType: ContentType) {
    if (contentType === 'multipart/form-data' || contentType === null) {
      return undefined
    } else {
      return {
        'content-type': contentType,
      }
    }
  }
}
