import { PRODUCT_TOUR_FILE_COMPARISON, PRODUCT_TOUR_SINGLE_FILE } from '../../utils/CONVERSATION_CONSTANTS.ts'
import { BaseButton } from '../atomics/BaseButton.tsx'
import { useTooltipHook } from './use-tooltip.hook.ts'

export function ProductTourTooltipFooter() {
  const { step, handlePrevious, handleNext } = useTooltipHook()

  return (
    <div className={'flex items-center justify-end'}>
      {step === PRODUCT_TOUR_FILE_COMPARISON && (
        <BaseButton
          color={'quaternary'}
          label={'Retour'}
          onClick={handlePrevious}
          className={'text-silver hover:cursor-pointer'}
        />
      )}
      {(step === PRODUCT_TOUR_SINGLE_FILE || step === PRODUCT_TOUR_FILE_COMPARISON) && (
        <BaseButton onClick={handleNext} color={'secondary'} label={'Suivant'} />
      )}
    </div>
  )
}
