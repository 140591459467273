import './assets/scss/index.scss'
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom'
import { DefaultLayout, layoutLoader } from './routes/DefaultLayout.tsx'
import { ErrorPage } from './routes/ErrorPage.tsx'
import { Documents, documentsLoader } from './routes/Documents/Documents.tsx'
import { currentUserLoader, ProtectedRoutes } from './components/utils/ProtectedRoutes.tsx'
import { Provider } from './Provider'
import { ROUTE_PATHS } from './ROUTE_PATHS.ts'
import React from 'react'

// To avoid circular references, we don't import the store directly in the file
// But we inject the store in that file from the entrypoint of the React App
// which is main.tsx
// See: https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
import { ConversationPage } from './routes/ConversationPage/ConversationPage.tsx'
import { Page } from './components/atomics/BasePage.tsx'
import { createRoot } from 'react-dom/client'
import { AppStore, createAppStore, Dependencies } from './store/configureStore.ts'
import { WretchAuthGateway } from './gateways/WretchAuth.gateway.ts'
import { WretchConversationGateway } from './gateways/WretchConversation.gateway.ts'
import { WretchFileGateway } from './gateways/WretchFile.gateway.ts'
import { SignUp } from './routes/SignUp/SignUp.tsx'
import { AccountVerificationInfo } from './routes/AccountVerificationInfo.tsx'
import { EmailVerification } from './routes/EmailVerification.tsx'
import { ResetPassword } from './routes/ResetPassword.tsx'
import { EmailError } from './components/EmailError.tsx'
import { ResetPasswordVerification } from './routes/ResetPasswordVerification.tsx'
import { ResetPasswordConfirmation } from './routes/ResetPasswordConfirmation.tsx'
import { ResetPasswordInfo } from './routes/ResetPasswordInfo.tsx'
import { PublicLayout } from './routes/PublicLayout.tsx'
import { WretchProductTourGateway } from './gateways/WretchProductTour.gateway.ts'
import { WretchTagGateway } from './gateways/WretchTag.gateway.ts'
import { olympeGptApiWretch } from './gateways/olympeGptApiWretch.ts'
import { OutagePage } from './routes/OutagePage.tsx'
import { Login } from './routes/Login/Login.tsx'
import { AuthLayout } from './routes/AuthLayout.tsx'
import { GreetingUser } from './routes/GreetingUser.tsx'

const authGateway = new WretchAuthGateway(olympeGptApiWretch)
const conversationGateway = new WretchConversationGateway(olympeGptApiWretch)
const productTourGateway = new WretchProductTourGateway(olympeGptApiWretch)
const fileGateway = new WretchFileGateway(olympeGptApiWretch)
const tagGateway = new WretchTagGateway(olympeGptApiWretch)

const dependencies: Dependencies = {
  fileGateway,
  conversationGateway,
  authGateway,
  productTourGateway,
  tagGateway,
}
export const store = createAppStore(dependencies)

const forNotAuthenticatedOnlyRoutes = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        path: '/',
        element: <PublicLayout />,
        children: [
          {
            path: ROUTE_PATHS.accountVerificationInfo,
            element: (
              <Page title="Informations de validation d'e-mail">
                <AccountVerificationInfo />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.emailVerification,
            element: (
              <Page title="Vérification d'e-mail">
                <EmailVerification />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPassword,
            element: (
              <Page title="Nouveau mot de passe">
                <ResetPassword />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordTokenExpiredError,
            element: (
              <Page title="Erreur">
                <EmailError tokenIssue="expired" />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordTokenInvalidError,
            element: (
              <Page title="Erreur">
                <EmailError tokenIssue="invalid" />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordVerification,
            element: (
              <Page title="Nouveau mot de passe">
                <ResetPasswordVerification />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordConfirmation,
            element: (
              <Page title="Confirmation">
                <ResetPasswordConfirmation />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.resetPasswordInfo,
            element: (
              <Page title="Confirmation">
                <ResetPasswordInfo />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.greetingUser,
            element: (
              <Page title="Bonjour">
                <GreetingUser />
              </Page>
            ),
          },
        ],
      },
      {
        path: ROUTE_PATHS.register,
        element: (
          <Page title="S'inscrire">
            <AuthLayout>
              <SignUp />
            </AuthLayout>
          </Page>
        ),
      },
      {
        path: ROUTE_PATHS.login,
        element: (
          <Page title="Se connecter">
            <Login />
          </Page>
        ),
      },
    ],
  },
]

// For now, we don't have public pages
// Could be used for pages such as CGUs
const publicRoutes: RouteObject[] = []

const needAuthRoutes: RouteObject[] = [
  {
    path: '/',
    element: <ProtectedRoutes />,
    loader: currentUserLoader(),
    children: [
      {
        path: '/',
        element: <DefaultLayout />,
        loader: layoutLoader({ store }),
        children: [
          {
            path: ROUTE_PATHS.conversationSingle,
            element: (
              <Page title="Conversation en cours">
                <ConversationPage />
              </Page>
            ),
          },
          {
            path: ROUTE_PATHS.documents,
            loader: documentsLoader({ store }),
            element: (
              <Page title="Base de connaissance">
                <Documents />
              </Page>
            ),
          },
        ],
      },
    ],
  },
]
const createRouter = ({ store }: { store: AppStore }) => {
  if (import.meta.env.VITE_OUTAGE_PAGE_ENABLED === 'true') {
    return createBrowserRouter([{ path: '*', element: <OutagePage /> }])
  } else {
    return createBrowserRouter([
      ...publicRoutes,
      ...(store.getState().entities.auth.user ? [] : forNotAuthenticatedOnlyRoutes),
      ...needAuthRoutes,
      { path: ROUTE_PATHS.unexpectedError, element: <ErrorPage /> },
      { path: '*', element: <ErrorPage message="Cette page n'existe pas" /> },
    ])
  }
}

export type AppRouter = ReturnType<typeof createRouter>
const container = document.getElementById('root')

if (!container) throw new Error("Could not find root element with id 'root'")

const root = createRoot(container)
const router = createRouter({ store })

root.render(
  <React.StrictMode>
    <Provider store={store} router={router} />
  </React.StrictMode>,
)
