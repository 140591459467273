import { DeleteAllUserFilesError } from '../../../types/file.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const deleteAllUserFiles = createAppAsyncThunk(
  'files/deleteAllUserFiles',
  async (_, { extra: { fileGateway } }) => {
    const response = await fileGateway.deleteAllUserFiles()
    if (!response.ok) {
      const error: DeleteAllUserFilesError = await response.json()
      return Promise.reject(error.message)
    }
    return Promise.resolve()
  },
)
