import { TheIconSignout } from '../icons/TheIconSignout.tsx'

type Props = {
  onLogout?: () => void
}

export function TheSidebarFooter({ onLogout }: Readonly<Props>) {
  return (
    <div className={'h-[20%] w-full'}>
      <ul className={`border-t-solid flex flex-col gap-4 border-t border-t-label pt-5 sm:gap-0 lg:gap-1 lg:pb-5 `}>
        <li className="text-xs text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_PRIVACY_POLICY_URL}
            target="_blank"
            rel="noreferrer"
          >
            Politique de confidentialité
          </a>
        </li>
        <li className="text-xs text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_CGU_URL}
            target="_blank"
            rel="noreferrer"
          >
            CGU
          </a>
        </li>
        <li className="text-xs text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_LEGAL_MENTIONS_URL}
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </li>
        <li className="text-xs text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_CONTACT_URL}
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
        </li>
      </ul>
      <button
        className="border-t-solid hidden items-center gap-2 border-t border-t-label pt-5 hover:cursor-pointer hover:opacity-70 sm:flex"
        onClick={onLogout}
        onKeyDown={onLogout}
      >
        <TheIconSignout className={'h-4 w-4 fill-silver'} />
        <span className="text-xs text-bright">Déconnexion</span>
      </button>
    </div>
  )
}
