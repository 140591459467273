import TheLoadStatusIcon from './TheLoadStatusIcon'
import { FileInfos } from '../types/file'

type Props = {
  fileInfos: FileInfos | undefined
}

export function TheAddedDocumentTag({ fileInfos }: Readonly<Props>) {
  return (
    <div className="flex w-full min-w-[200px] basis-1/4 items-center gap-2 rounded-md bg-[rgba(193,193,193,0.15)] px-3 py-2 sm:w-40">
      <TheLoadStatusIcon status={fileInfos?.uploadStatus} />
      <span className={'block w-full overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm'}>
        {fileInfos?.title}
      </span>
    </div>
  )
}
