import { useEffect, useRef, useState } from 'react'
import { TheFileUploaderMobile } from '../../components/TheFileUploaderMobile.tsx'
import { FileData, FileDataWithExtraColumn, UploadedFile } from '../../types/file.ts'
import { TheFilesListMobile } from '../../components/TheFilesListMobile.tsx'
import { TheUploadInformationsCard } from '../../components/TheUploadInformationsCard.tsx'

import { ReusableTable, TableColumn, TableData } from '../../components/atomics/BaseTable.tsx'
import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import {
  selectAllFiles,
  selectFilesByTagId,
  selectIsUploading,
  selectUploadFileError,
} from '../../store/files/filesSlice.ts'
import { TheIconBin } from '../../components/icons/TheIconBin.tsx'
import { DocumentSearchBar } from '../../components/DocumentSearchBar.tsx'
import BaseCheckbox from '../../components/atomics/BaseCheckbox.tsx'
import { BaseModal } from '../../components/atomics/BaseModal.tsx'
import { FilterKey } from '../../components/TheMobileFileFilterPanel.tsx'
import { TheIconResizeModal } from '../../components/icons/TheIconResizeModal.tsx'
import { listUserFiles } from '../../store/files/useCases/listUserFiles.ts'
import { LoaderFunction } from 'react-router-dom'
import { AppStore } from '../../store/configureStore.ts'
import { TheIconCirclePlus } from '../../components/icons/TheIconCirclePlus.tsx'
import { TheTagList } from '../../components/TheTagList.tsx'
import { TheIconTripleDotsVertical } from '../../components/icons/TheIconTripleDotsVertical.tsx'
import { BaseToaster } from '../../components/atomics/BaseToaster.tsx'
import { TheIconSuccess } from '../../components/icons/TheIconSuccess.tsx'
import {
  selectCurrentTag,
  selectFileCountByTag,
  selectTagWithoutDefaultAndCurrent,
  setActiveTag,
  toggleAddTagsModal,
} from '../../store/tags/tagSlice.ts'
import { listTags } from '../../store/tags/useCases/listTags.ts'
import { Tag } from '../../types/tag.ts'
import { BaseSearchInput } from '../../components/atomics/BaseSearchInput.tsx'
import { SortableOptionsWithIcons } from '../../components/SortableOptionsWithIcons.tsx'
import { TheIconArrowUpAndDown } from '../../components/icons/TheIconArrowUpAndDown.tsx'
import { TheIconMoveFiles } from '../../components/icons/TheIconMoveFiles.tsx'
import { SwitchChevronIcons } from '../../components/SwitchChevronIcons.tsx'
import { TheIconUpdate } from '../../components/icons/TheIconUpdate.tsx'
import BaseInput from '../../components/atomics/BaseInput.tsx'
import { BaseButton } from '../../components/atomics/BaseButton.tsx'
import { FileNameErrors, getErrorMessages, hasErrors } from '../../utils/formValidation.ts'
import { TheTag } from '../../components/TheTag.tsx'
import { useSelector } from 'react-redux'
import { formatDateFileData } from '../../utils/formatDateFileData.ts'
import {
  createDocumentsViewModel,
  expendablesSectionsInitial,
  fileNameErrorsInitialState,
} from './documents.viewmodel.tsx'
import { useKeyPressEvent } from '../../utils/useKeyPressEvent.ts'
import { useToaster } from '../../utils/useToaster.ts'
import { ReusableTableRowActions } from '../../components/ReusableTableRowActions.tsx'

export const documentsLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async () => {
    await store.dispatch(listUserFiles())
    return null
  }

export function Documents() {
  const dispatch = useAppDispatch()
  const files = useAppSelector(selectAllFiles)
  const currentTag = useAppSelector(selectCurrentTag)
  const filesByTag = useAppSelector(selectFilesByTagId(currentTag.id))
  const tags = useAppSelector(selectTagWithoutDefaultAndCurrent)
  const fileCountByTag = useSelector(selectFileCountByTag)
  const isUploading = useAppSelector(selectIsUploading)
  const uploadFileError = useAppSelector(selectUploadFileError)

  const [inputValue, setInputValue] = useState('')
  const [selectedRows, setSelectedRows] = useState<TableData<UploadedFile>>([])
  const [isRowModalVisible, setIsRowModalVisible] = useState(false)
  const [fileNameErrors, setFileNameErrors] = useState<FileNameErrors>(fileNameErrorsInitialState)
  const [expendablesSections, setExpendablesSections] = useState(expendablesSectionsInitial)
  const [selectedItem, setSelectedItem] = useState<FileData | null>(null)
  const [file, setFile] = useState<FileData & { size: string }>({
    createdAt: '',
    extension: '',
    id: '',
    tags: [],
    title: '',
    size: '',
  })
  const [displayLoading, setDisplayLoading] = useState(false)
  const [isRenameTagModalVisible, setIsRenameTagModalVisible] = useState(false)
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false)
  const [previewFile, setPreviewFile] = useState(false)
  const [pdfData, setPdfData] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [emptyArrayMessage, setEmptyArrayMessage] = useState('')
  const [filteredData, setFilteredData] = useState<TableData<FileDataWithExtraColumn>>([])
  const [filterKey, setFilterKey] = useState<FilterKey>('Date')
  const { handleKeyDown } = useKeyPressEvent()
  const { toasterMessageElement, showToast } = useToaster()
  const {
    deleteFileByRowIndex,
    handleInputChange,
    deleteFileByFileId,
    toggleRowModal,
    handleSetSelectedItem,
    handleFileInputChange,
    handleSelectAll,
    toggleRenameFileModal,
    toggleBulkModal,
    toggleRowSelection,
    handleApplyTags,
    handleFilePreview,
    filterFiles,
    handleUpdateDocument,
    handleDeleteDocuments,
    handleClearSearchValue,
    handleDeleteAllDocumentsOnMobile,
    toggleSingleFileActionsModal,
    setIsActive,
    toggleAction,
    handleCancel,
    handleSubmit,
    getFontStyleBasedOnCurrentRef,
  } = createDocumentsViewModel({
    dispatch,
    files,
    setFileNameErrors,
    setInputValue,
    inputValue,
    selectedRows,
    setSelectedRows,
    isRowModalVisible,
    setIsRowModalVisible,
    expendablesSectionsInitial,
    expendablesSections,
    setExpendablesSections,
    selectedItem,
    setSelectedItem,
    setFile,
    file,
    setDisplayLoading,
    currentTag,
    isRenameTagModalVisible,
    setIsRenameTagModalVisible,
    fileNameErrorsInitialState,
    isBulkModalVisible,
    setIsBulkModalVisible,
    previewFile,
    setPreviewFile,
    setPdfData,
    setSearchValue,
    setEmptyArrayMessage,
    setFilteredData,
    filesByTag,
    filterKey,
    setFilterKey,
    showToast,
  })()

  const date = useRef<HTMLButtonElement>(null)
  const extension = useRef<HTMLButtonElement>(null)
  const alphabeticOrder = useRef<HTMLButtonElement>(null)
  const [previewFileModalWidth, setPreviewFileModalWidth] = useState<'small' | 'fullScreen'>('small')
  const [isBulkTagSelectVisible, setIsBulkTagSelectVisible] = useState<boolean>(false)

  // Table data structure
  const columns: TableColumn<FileDataWithExtraColumn>[] = [
    {
      key: 'select',
      render: (item: FileData) => {
        return <BaseCheckbox checked={selectedRows.includes(item)} onChange={() => toggleRowSelection(item)} />
      },
      title: '',
      sortable: false,
      editable: false,
    },
    {
      key: 'title',
      title: 'Nom du document',
      sortable: true,
      editable: true,
    },
    {
      key: 'createdAt',
      title: "Date d'ajout",
      sortable: true,
      editable: false,
    },
    {
      key: 'extension',
      title: 'Format',
      sortable: true,
      editable: false,
    },
    {
      key: 'action',
      sortable: false,
      editable: false,
      title: 'Action',
      render: (item: FileData) => {
        return (
          <ReusableTableRowActions
            displayFileAction={() => handleFilePreview(item)}
            deleteFileAction={() => deleteFileByFileId({ ...item, size: '' })}
            displayMovingFileAction={() => handleSetSelectedItem(item)}
            item={item}
            selectedItem={selectedItem}
            toggleModalAction={() => setSelectedItem(null)}
            selectTagAction={(tag) => handleApplyTags(tag, [item])}
          />
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(listTags())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const formattedDataFromAllFiles = formatDateFileData(files)
    const formattedDataFromFilesFilteredByTagId = formatDateFileData(filesByTag)
    const fileDataList: FileData[] =
      currentTag.id === 0 ? formattedDataFromAllFiles : formattedDataFromFilesFilteredByTagId
    setFilteredData(fileDataList as TableData<FileDataWithExtraColumn>)
    setSearchValue('')
    if (!files.length || !filesByTag.length) {
      setEmptyArrayMessage('Vous n’avez pas encore ajouté de documents dans votre base de connaissance.')
    }
  }, [currentTag, files]) // eslint-disable-line react-hooks/exhaustive-deps

  // Renders
  const renderFilePreviewModal = () => {
    return (
      <BaseModal
        contentVisible={previewFile}
        onClick={() => setPreviewFile(!previewFile)}
        animationClass={'translate-x-full'}
        styleBasedOnScreenSize={`right-0 h-screen mr-4 ${
          previewFileModalWidth === 'small' ? 'w-[31rem]' : 'w-full'
        } bg-bright`}
      >
        <div className={'relative h-full w-full'}>
          <TheIconResizeModal
            className={`absolute hover:cursor-pointer ${
              previewFileModalWidth === 'small' ? 'left-1/2' : 'right-1/3'
            } translate-y-full`}
            onClick={() => {
              previewFileModalWidth === 'small'
                ? setPreviewFileModalWidth('fullScreen')
                : setPreviewFileModalWidth('small')
            }}
          />
          <iframe title="PDF Viewer" src={pdfData} width="100%" height="100%" />
        </div>
      </BaseModal>
    )
  }

  const renderSortingAndFilteringModal = () => {
    const sortActionStatus = expendablesSections.isSortActionsSectionOpened ? 'up' : 'down'
    const moveActionStatus = expendablesSections.isMoveActionsSectionOpened ? 'up' : 'down'
    return (
      <BaseModal
        contentVisible={isBulkModalVisible}
        onClick={toggleBulkModal}
        positionClass={'right-0 left-0 bottom-0'}
        animationClass={'translate-y-full'}
        styleBasedOnScreenSize={'w-full bg-bright shadow-softOutline'}
      >
        <ul className={'flex flex-col gap-4 px-3 pb-7 pt-6'}>
          <li>
            <SortableOptionsWithIcons
              onClick={() => toggleAction('isSortActionsSectionOpened')}
              titleLeftIcon={<TheIconArrowUpAndDown />}
              titleRightIcon={
                <div className={'px-2'}>
                  <SwitchChevronIcons status={sortActionStatus} />
                </div>
              }
              title={'Trier par'}
            >
              <ul
                className={`pl-12 pr-4 ${expendablesSections.isSortActionsSectionOpened ? 'flex flex-col' : 'hidden'}`}
              >
                <li>
                  <button
                    id={'Date'}
                    ref={date}
                    onClick={setIsActive}
                    className={`flex w-full items-center justify-between py-2 text-base ${getFontStyleBasedOnCurrentRef(
                      date,
                    )}`}
                  >
                    Plus récent
                    {date?.current?.id === filterKey && (
                      <TheIconSuccess width="18" height="18" className={'fill-primary'} />
                    )}
                  </button>
                </li>
                <li>
                  <button
                    id={'Extension'}
                    ref={extension}
                    onClick={setIsActive}
                    className={`flex w-full items-center justify-between py-2 text-base ${getFontStyleBasedOnCurrentRef(
                      extension,
                    )}`}
                  >
                    Format
                    {extension?.current?.id === filterKey && (
                      <TheIconSuccess width="18" height="18" className={'fill-primary'} />
                    )}
                  </button>
                </li>
                <li>
                  <button
                    id={'Alphabetic-Order'}
                    ref={alphabeticOrder}
                    onClick={setIsActive}
                    className={`flex w-full items-center justify-between py-2 text-base ${getFontStyleBasedOnCurrentRef(
                      alphabeticOrder,
                    )}`}
                  >
                    Ordre alphabétique
                    {alphabeticOrder?.current?.id === filterKey && (
                      <TheIconSuccess width="18" height="18" className={'fill-primary'} />
                    )}
                  </button>
                </li>
              </ul>
            </SortableOptionsWithIcons>
          </li>
          <li>
            <SortableOptionsWithIcons
              onClick={() => toggleAction('isMoveActionsSectionOpened')}
              titleLeftIcon={<TheIconMoveFiles width="16" height="16" />}
              titleRightIcon={
                <div className={'px-2'}>
                  <SwitchChevronIcons status={moveActionStatus} />
                </div>
              }
              title={'Tout déplacer vers'}
            >
              <ul className={` pl-12 ${expendablesSections.isMoveActionsSectionOpened ? 'flex flex-col' : 'hidden'}`}>
                {tags.map((tag: Tag) => {
                  return (
                    <li key={tag.id}>
                      <button
                        className={`flex items-center justify-between py-2 text-base leading-5 text-label`}
                        onClick={async () => {
                          await handleApplyTags(tag, files)
                          toggleBulkModal()
                        }}
                      >
                        {tag.name}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </SortableOptionsWithIcons>
          </li>
          <div className={'w-6/6 h-0.5 bg-primary'} />
          <button
            onClick={handleDeleteAllDocumentsOnMobile}
            className={'flex gap-2 px-3 py-2 text-base font-semibold leading-5 text-primary'}
          >
            <TheIconBin className={'w-4'} />
            Tout supprimer
          </button>
        </ul>
      </BaseModal>
    )
  }

  const renderSingleFileActionsModal = () => {
    const moveActionStatus = expendablesSections.isMoveActionsSectionOpened ? 'up' : 'down'
    return (
      <BaseModal
        contentVisible={isRowModalVisible}
        onClick={toggleRowModal}
        positionClass={'right-0 bottom-0'}
        animationClass={'translate-y-full'}
        styleBasedOnScreenSize={'w-full bg-bright shadow-softOutline'}
      >
        <ul className={`flex flex-col px-4 py-8`}>
          <li>
            <button
              className={'flex w-full items-center gap-2 px-3.5 py-2'}
              onClick={() => {
                toggleRowModal()
                toggleRenameFileModal()
              }}
            >
              <TheIconUpdate width="16" height="17" className={'fill-primary'} />
              Renommer
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                deleteFileByFileId(file)
              }}
              className={'flex w-full items-center gap-2 px-3.5 py-2'}
            >
              <TheIconBin width="16" height="19" className={'fill-primary'} />
              Supprimer
            </button>
          </li>
          <li>
            <SortableOptionsWithIcons
              onClick={() => toggleAction('isMoveActionsSectionOpened')}
              titleLeftIcon={<TheIconMoveFiles width="16" height="16" />}
              titleRightIcon={
                <div className={'px-2'}>
                  <SwitchChevronIcons status={moveActionStatus} />
                </div>
              }
              title={'Déplacer vers'}
            >
              <ul className={` pl-12 ${expendablesSections.isMoveActionsSectionOpened ? 'flex flex-col' : 'hidden'}`}>
                {tags.map((tag: Tag) => {
                  return (
                    <li key={tag.id}>
                      <button
                        className={`flex items-center justify-between py-2 text-base leading-5 text-label`}
                        onClick={() => {
                          handleApplyTags(tag, [file])
                          toggleRowModal()
                        }}
                      >
                        {tag.name}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </SortableOptionsWithIcons>
          </li>
        </ul>
      </BaseModal>
    )
  }

  const renderRenameFileModal = () => {
    return (
      <BaseModal
        contentVisible={isRenameTagModalVisible}
        onClick={toggleRenameFileModal}
        animationClass={'translate-x-full'}
      >
        <form
          onSubmit={handleSubmit}
          className={
            'fixed left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 transform flex-col gap-5 rounded-lg bg-bright px-5 pb-4 pt-5'
          }
        >
          <div className={'flex flex-col gap-3'}>
            <h4>Renommer</h4>
            <BaseInput
              onChange={handleInputChange}
              name={'label'}
              value={inputValue}
              placeholder={'Ex. : Droit suisse'}
            />
            {hasErrors(fileNameErrors) && (
              <span className={'text-center text-error'}>{getErrorMessages(fileNameErrors)[0]}</span>
            )}
          </div>
          <div className={'flex flex-col items-center gap-3'}>
            <BaseButton type={'submit'} size={'large'} label={'Ok'} />
            <button
              type={'button'}
              onClick={handleCancel}
              className={'text-xs font-semibold text-primary hover:cursor-pointer'}
            >
              Annuler
            </button>
          </div>
        </form>
      </BaseModal>
    )
  }

  const renderTheUploadInformationsCard = () => {
    return (
      displayLoading && (
        <TheUploadInformationsCard
          file={file}
          isUploading={isUploading}
          isUploadSuccess={!uploadFileError.length && !isUploading}
        />
      )
    )
  }

  return (
    <div className="relative flex h-[calc(100vh-3.5rem)] items-start sm:ml-60 sm:h-screen sm:w-[calc(100%-15rem)] sm:pb-6 sm:pl-4 sm:pr-4 sm:pt-12 lg:w-[calc(90%-15rem)] lg:pr-0">
      <div
        className={`${
          previewFile ? 'w-[calc(100%-31rem)]' : 'w-full'
        } flex h-full max-h-screen flex-col justify-between sm:gap-11`}
      >
        <div className={'flex h-full flex-col sm:hidden'}>
          <div className="w-full rounded-b-2xl px-4 pb-5 pt-4 shadow-mysticGlowShadow lg:w-1/2">
            <h1 className="mb-3 text-3xl font-semibold text-primary">Base de connaissance</h1>
            <p className="text-sm font-normal text-label lg:text-base">
              Vous pouvez importer et retrouver ici les documents que vous souhaitez ajouter à votre base de
              connaissance.
            </p>
            <TheFileUploaderMobile handleFileInputChange={handleFileInputChange} />
            {renderTheUploadInformationsCard()}
          </div>
          <div className={'flex basis-full flex-col gap-4 bg-[#EDF1F5] px-4 pt-6'}>
            <div className={'flex items-center justify-between gap-3'}>
              <span className={'text-xl font-semibold text-primary'}>Dossiers</span>
              <TheIconCirclePlus
                onKeyDown={handleKeyDown}
                onClick={() => dispatch(toggleAddTagsModal())}
                className={'w-6 fill-primary hover:cursor-pointer hover:fill-primary'}
              />
            </div>
            <TheTagList>
              {fileCountByTag.map((tag) => (
                <TheTag
                  onClick={() => {
                    setSelectedRows([])
                    dispatch(setActiveTag(tag))
                  }}
                  tag={tag}
                  key={tag.id}
                />
              ))}
            </TheTagList>
            <div className={'flex items-center justify-between'}>
              <h3 className={'text-xl font-semibold leading-6 text-primary'}>Tous les documents</h3>
              <TheIconTripleDotsVertical
                onClick={toggleBulkModal}
                width="21"
                height="36"
                className={'inline-block fill-label hover:fill-label'}
              />
            </div>
            <BaseSearchInput
              placeholder="Rechercher un document"
              onChange={filterFiles}
              onClear={handleClearSearchValue}
              value={searchValue}
            />
            <TheFilesListMobile
              toggleSingleFileActionsModal={toggleSingleFileActionsModal}
              emptyArrayMessage={emptyArrayMessage}
              files={filteredData}
              filterKey={filterKey}
              setFilterKey={setFilterKey}
            />
          </div>
        </div>

        <div className={'hidden sm:flex sm:flex-col sm:gap-4'}>
          <div className="w-full lg:w-1/2">
            <h1 className="mb-3 text-2xl font-semibold text-primary">Base de connaissance</h1>
            <p className="text-sm font-normal text-label lg:text-base">
              Vous pouvez déposer et retrouver ici les documents que vous souhaitez ajouter à votre base de
              connaissance.
            </p>
          </div>
          <div className={'hidden flex-col gap-4 sm:flex'}>
            <div className={'flex items-center gap-3'}>
              <span className={'text-xl font-semibold text-primary'}>Dossiers</span>
              <TheIconCirclePlus
                aria-label={'Ajouter un dossier'}
                tabIndex={1}
                onKeyDown={handleKeyDown}
                onClick={() => dispatch(toggleAddTagsModal())}
                className={'fill- h-4 w-4 fill-lightBlue hover:cursor-pointer hover:fill-primary'}
              />
            </div>
            <TheTagList>
              {fileCountByTag.map((tag) => (
                <TheTag
                  onClick={() => {
                    dispatch(setActiveTag(tag))
                    setSelectedRows([])
                  }}
                  tag={tag}
                  key={tag.id}
                />
              ))}
            </TheTagList>
          </div>
          <DocumentSearchBar
            responsiveStyle={'hidden sm:flex sm:flex-col gap-4 lg:flex-row lg:items-center'}
            title={'Tous les documents'}
            searchValue={searchValue}
            filterData={filterFiles}
            handleClearSearchValue={handleClearSearchValue}
            handleFileInputChange={handleFileInputChange}
          />
          {renderTheUploadInformationsCard()}

          <ReusableTable
            handleUpdateCell={handleUpdateDocument}
            selectedRowsLength={selectedRows.length}
            handleSelectAll={handleSelectAll}
            handleExtraCell={deleteFileByRowIndex}
            handleDeleteAll={handleDeleteDocuments}
            filteredData={filteredData as TableData<FileDataWithExtraColumn>}
            emptyArrayMessage={emptyArrayMessage}
            columns={columns}
            defaultSorting={{ columnKey: 'createdAt', order: 'desc' }}
            isBulkTagSelectVisible={isBulkTagSelectVisible}
            onClick={() => {
              setIsBulkTagSelectVisible(!isBulkTagSelectVisible)
            }}
            handleBulkTagSelectOption={(tag) => handleApplyTags(tag, selectedRows as FileData[])}
          />
        </div>
        <BaseToaster message={toasterMessageElement} color={'success'}>
          <TheIconSuccess width="24" height="24" className={'fill-primary'} />
        </BaseToaster>
      </div>

      {renderFilePreviewModal()}
      {renderSingleFileActionsModal()}
      {renderSortingAndFilteringModal()}
      {renderRenameFileModal()}
    </div>
  )
}
