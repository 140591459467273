import { TheAddedDocumentTag } from './TheAddedDocumentTag.tsx'
import { TheConversationMessageInputProps } from './TheConversationMessageInput.tsx'
import { useRef } from 'react'
import { TheAddDocumentButton } from './TheAddDocumentButton.tsx'
import { TheDotAnimation } from './TheDotAnimation.tsx'
import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import { TheIconFilterMobile } from './icons/TheIconFilterMobile.tsx'

export function TheInputSingleFileTypeMobile({
  isQuestionLoading,
  userInput,
  className,
  handleFileInputChange,
  handleInputFileOpening,
  currentConversation,
  handleSend,
  resize,
  messages,
  handleDisplayFilterMobile,
}: Readonly<TheConversationMessageInputProps>) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (handleInputFileOpening && inputRef) {
      handleInputFileOpening(inputRef)
    }
  }

  const renderSubmitOrLoadingIcon = () => {
    return isQuestionLoading ? (
      <TheDotAnimation size="small" />
    ) : (
      <div
        className={`flex items-center gap-4 ${
          currentConversation?.currentFilesInfos.length ? 'self-start' : 'currentConversation?.currentFilesInfos.length'
        } ${messages?.length && 'justify-center'}`}
      >
        {!messages?.length && <TheIconFilterMobile onClick={handleDisplayFilterMobile} className={'h-7 w-7'} />}{' '}
        <TheIconArrowSubmission
          onClick={handleSend}
          disabled={userInput.length === 0 || currentConversation?.currentFilesInfos.length === 0}
        />
      </div>
    )
  }

  return (
    <div className={`flex w-full items-center justify-evenly gap-4 sm:hidden sm:px-3.5 ${className}`}>
      <div className="flex h-10 w-10 items-center justify-center self-start">
        <TheAddDocumentButton
          disabled={isQuestionLoading || (currentConversation && currentConversation?.currentFilesInfos.length === 1)}
          onClick={handleClick}
        />
      </div>
      <div className={'flex w-9/12 flex-col gap-1 px-1'}>
        <textarea
          value={userInput}
          disabled={isQuestionLoading}
          rows={userInput?.length > 50 ? 2 : 1}
          placeholder="Posez une question"
          className={
            'max-h-[144px] w-full resize-none rounded-lg border border-solid border-fadedSilver bg-bright p-3 text-base leading-5 text-primary shadow-subtleElevation outline-none sm:my-0 sm:py-2.5'
          }
          onInput={resize}
        />
        {!!currentConversation?.currentFilesInfos.length && (
          <TheAddedDocumentTag fileInfos={currentConversation?.currentFilesInfos[0]} />
        )}
      </div>
      {renderSubmitOrLoadingIcon()}
      <input
        id="fileInputRefMobile"
        ref={inputRef}
        accept=".pdf"
        className="hidden"
        type="file"
        onChange={handleFileInputChange}
      />
    </div>
  )
}
