import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { createTag } from './useCases/createTag.ts'
import { Tag, TagWithFileCountAndActiveStatus } from '../../types/tag.ts'
import { RootState } from '../configureStore.ts'
import { applyTags } from './useCases/applyTags.ts'
import { listTags } from './useCases/listTags.ts'
import { selectAllFiles } from '../files/filesSlice.ts'
import { FileData } from '../../types/file.ts'

const tagsEntityAdapter = createEntityAdapter<Tag & { isTagActive: boolean }>()

const defaultTag = {
  id: 0,
  name: 'Tous mes documents',
  userId: '',
  isTagActive: true,
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: tagsEntityAdapter.getInitialState({
    isLoading: false,
    ids: [defaultTag.id],
    entities: {
      [defaultTag.id]: defaultTag,
    },
    showAddTagModal: false,
    tagSelectPositions: {} as Record<FileData['id'], { top: number; left: number }>,
    tagSelectHeight: 0,
  }),
  reducers: {
    setActiveTag: (state, action) => {
      tagsEntityAdapter.updateMany(
        state,
        state.ids.map((id) => {
          return {
            id,
            changes: {
              isTagActive: false,
            },
          }
        }),
      )
      tagsEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          isTagActive: !action.payload.isTagActive,
        },
      })
    },
    toggleAddTagsModal: (state) => {
      state.showAddTagModal = !state.showAddTagModal
    },
    setTagSelectPositions: (state, action) => {
      state.tagSelectPositions = { ...state.tagSelectPositions, ...action.payload }
    },
    setTagSelectHeight: (state, action) => {
      state.tagSelectHeight = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTag.fulfilled, (state, action) => {
        state.isLoading = false
        tagsEntityAdapter.updateMany(
          state,
          state.ids.map((id) => {
            return {
              id,
              changes: {
                isTagActive: false,
              },
            }
          }),
        )
        tagsEntityAdapter.addOne(state, { ...action.payload, isTagActive: true })
      })
      .addCase(createTag.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createTag.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(applyTags.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(listTags.fulfilled, (state, action) => {
        state.isLoading = false
        const payload: (Tag & { isTagActive: boolean })[] = action.payload.map((tag: Tag) => {
          return {
            ...tag,
            isTagActive: false,
          }
        })
        tagsEntityAdapter.setAll(state, [defaultTag, ...payload])
      })
  },
})

export const { setActiveTag, toggleAddTagsModal, setTagSelectHeight, setTagSelectPositions } = tagsSlice.actions
export const tagsSelector = tagsEntityAdapter.getSelectors((state: RootState) => state.entities.tags)
export const selectAllTags = (state: RootState) => tagsSelector.selectAll(state)
export const selectShowAddTagModal = (state: RootState) => state.entities.tags.showAddTagModal
export const selectCurrentTag = (state: RootState) =>
  tagsSelector.selectAll(state).find((tag) => tag.isTagActive) ?? defaultTag

export const selectFileCountByTag = createSelector([selectAllFiles, selectAllTags], (files, tags) => {
  const fileCountByTag: Map<Tag['id'], TagWithFileCountAndActiveStatus> = new Map(
    tags.map((tag) => [tag.id, { ...tag, count: tag.id === 0 ? files.length : 0 }]),
  )
  files.forEach((file) => {
    file.tags.forEach((tagId) => {
      const tag = tags.find((tag) => tag.id === tagId)
      if (tagId !== 0 && tag) {
        fileCountByTag.set(tagId, {
          id: tagId,
          name: tag.name,
          count: (fileCountByTag.get(tagId)?.count ?? 0) + 1,
          isTagActive: tag.isTagActive,
          userId: tag.userId,
        })
      }
    })
  })
  return Array.from(fileCountByTag.values())
})
export const selectTagWithoutDefaultAndCurrent = createSelector(
  [selectAllTags, selectCurrentTag],
  (allTags, currentTag) => allTags.filter((tag) => tag.id !== 0 && tag.id !== currentTag?.id),
)

export const selectTagSelectPositionByFileId = (fileId: FileData['id']) => (state: RootState) =>
  state.entities.tags.tagSelectPositions[fileId]

export const selectTagSelectHeight = (state: RootState) => state.entities.tags.tagSelectHeight
