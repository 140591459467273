import { MessageRepository } from '../Repositories/interfaces/messageRepository.ts'
import { Conversation } from '../types/conversation.ts'
import { Message, Source } from '../types/message.ts'
import { createMessageRepositoryInstance } from '../utils/dependencyFactories.ts'

export class MessageService {
  private messageServiceRepository: MessageRepository

  constructor(messageServiceRepository: MessageRepository = createMessageRepositoryInstance()) {
    this.messageServiceRepository = messageServiceRepository
  }

  async createMessage(
    conversationId: Conversation['id'],
    content: Source['content'],
    sourceCollections: Message['sourceCollections'],
  ) {
    return this.messageServiceRepository.streamResponseMessage(conversationId, content, sourceCollections)
  }
}
