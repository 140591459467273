import { useAppDispatch, useAppSelector } from '../../store/hooks.ts'
import { useParams } from 'react-router-dom'
import { ChangeEvent, useEffect, useState } from 'react'
import { Conversation } from '../../types/conversation.ts'
import { listConversationMessages } from '../../store/messages/useCases/listConversationMessages.ts'
import { uploadFileInConversation } from '../../store/conversations/use Cases/uploadFileInConversation.ts'
import { deleteFilesInConversation } from '../../store/conversations/use Cases/deleteFilesInConversation.ts'
import { TheConversationTypesList } from '../../components/TheConversationTypesList/TheConversationTypesList.tsx'
import { updateConversation } from '../../store/conversations/use Cases/updateConversation.ts'
import {
  BEGIN,
  END,
  PRODUCT_TOUR_USER_FILES,
  TEMPORARY_AI_ID,
  TEMPORARY_HUMAN_ID,
} from '../../utils/CONVERSATION_CONSTANTS.ts'
import { addMessage, streamLastMessageInConversation } from '../../store/messages/messagesSlice.ts'
import { MessageService } from '../../services/messageService.ts'
import { Message } from '../../types/message.ts'
import { nanoid } from '@reduxjs/toolkit'
import { TheMessagesList } from '../../components/TheMessagesList.tsx'
import { Group, NestedCheckboxList } from '../../components/NestedCheckboxList.tsx'
import { TheConversationMessageInput } from '../../components/TheConversationMessageInput.tsx'
import { BaseModal } from '../../components/atomics/BaseModal.tsx'
import ReactMarkdown from 'react-markdown'
import { TheAddedDocumentTag } from '../../components/TheAddedDocumentTag.tsx'
import { TheIconAvatarAI } from '../../components/icons/TheIconAvatarAI.tsx'
import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { selectConversationById } from '../../store/conversations/conversationsSlice.ts'
import { TimeSavedModal } from '../../components/TimeSavedModal.tsx'
import { closeTimeSavedModal, openTimeSavedModal } from '../../store/modal/modalsSlice.ts'
import { listSourceCollections } from '../../store/sourceCollections/use Cases/listSourceCollections.ts'
import { useConversationPageHook } from './use-conversation-page.hook.ts'
import { ProductTourWithOverlay } from '../../components/ProductTourWithOverlay.tsx'

export function ConversationPage() {
  const { conversationId } = useParams() as {
    conversationId: Conversation['id']
  }
  const { isMobile, targetRef, step, showProductTour, messages } = useConversationPageHook()
  const conversation = useAppSelector(selectConversationById(conversationId))
  const dispatch = useAppDispatch()
  const [userInput, setUserInput] = useState('')
  const [isQuestionLoading, setIsQuestionLoading] = useState(false)
  const [selectedSources, setSelectedSources] = useState<string[]>([])
  const [data, setData] = useState<Group[]>([])
  const [displaySourceFilter, setDisplaySourceFilter] = useState(false)
  const [displaySourceFilterMobile, setDisplaySourceFilterMobile] = useState(false)

  const systemMessageContent =
    conversation?.currentFilesInfos.length === 2
      ? 'Analyse des documents suivants en cours :'
      : 'Analyse du document suivant en cours :'

  useEffect(() => {
    dispatch(listConversationMessages(conversationId))
    dispatch(listSourceCollections())
      .unwrap()
      .then((response) => {
        const mappedData = response?.map((el) => {
          return {
            ...el,
            collapsed: true,
          }
        })
        setData(mappedData as Group[])
      })
    setSelectedSources([])
    return () => {
      dispatch(closeTimeSavedModal())
    }
  }, [conversationId]) // eslint-disable-line react-hooks/exhaustive-deps

  async function askQuestion() {
    dispatch(closeTimeSavedModal())
    const question = userInput
    if (!userInput.length || isQuestionLoading) {
      return
    }
    if (!conversation) {
      return
    }

    setIsQuestionLoading(true)

    await dispatch(updateConversation(conversation))

    dispatch(
      addMessage({
        content: userInput,
        id: TEMPORARY_HUMAN_ID,
        sources: [],
        type: 'HUMAN',
        createdAt: new Date().toISOString(),
      }),
    )

    setUserInput('')
    if (!conversation) {
      return
    }
    const messageService = new MessageService()
    const sourceCollections: Message['sourceCollections'] = selectedSources
    const response = await messageService.createMessage(conversationId, question, sourceCollections)
    const reader = response?.body?.getReader()

    if (!reader) {
      return
    }

    dispatch(
      addMessage({
        content: '',
        id: TEMPORARY_AI_ID,
        sources: [],
        type: 'AI',
        createdAt: new Date().toISOString(),
      }),
    )

    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        reader.releaseLock()
        const messages = await dispatch(listConversationMessages(conversationId)).unwrap()
        const AIMessagesList = messages.filter((message) => message.type === 'AI')

        if (AIMessagesList.length === 1) {
          const AIResponse = AIMessagesList[0].content
          const AIResponseLowerCase = AIResponse.toLowerCase()
          const sorryList = [
            'désolé',
            'excusez-moi',
            'sans plus de précisions, il est difficile de donner une réponse précise.',
            'quelle est votre question',
            'je vous invite à reformuler votre question',
          ]
          const sorryRegex = new RegExp(sorryList.join('|'))
          const containsSorry = sorryRegex.test(AIResponseLowerCase)
          if (!containsSorry) {
            dispatch(openTimeSavedModal(conversation.type))
          }
        }

        setUserInput('')
        setIsQuestionLoading(false)

        return
      }
      const token = await new Response(value).text()
      dispatch(
        streamLastMessageInConversation({
          conversationId,
          token,
        }),
      )
    }
  }

  const toggleDisplaySourceFilter = () => {
    setDisplaySourceFilter(!displaySourceFilter)
  }

  const handleUpload = async (file: File) => {
    await dispatch(uploadFileInConversation({ conversationId, file, fileName: file.name }))
  }

  const handleStopFileContext = async () => {
    await dispatch(deleteFilesInConversation(conversationId))
  }

  const handleUserInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(e.target.value)
  }
  const handleSelectedSourcesChange = (newSelectedSources: string[]) => {
    setSelectedSources(newSelectedSources)
  }

  const handleDisplayFilterMobile = () => {
    setDisplaySourceFilterMobile(!displaySourceFilterMobile)
  }

  const systemMessage = { content: systemMessageContent }
  return (
    <div
      ref={targetRef}
      className="flex h-[calc(100vh-3.5rem)] flex-col items-center justify-between px-4 pb-4 pt-10 sm:ml-60 sm:h-screen sm:w-[calc(100%-15rem)] lg:pb-6"
    >
      <div className="no-scrollbar md:no-scrollbar-lg xl:no-scrollbar-xl lg-mt-6 w-full self-start overflow-y-scroll p-4 lg:h-[73vh] lg:w-4/5 lg:self-center lg:px-10 xl:pt-10">
        <div className={`${messages.length ? 'flex flex-col gap-5' : ''} h-[${window.innerHeight}px]`}>
          <div className={'flex flex-col items-center gap-4'}>
            {isMobile && <TheIconOlympeLogoWithText />}
            {!messages.length && (
              <h1 className="mb-4 text-center text-4xl font-semibold text-primary sm:mb-14 sm:text-2xl sm:font-normal sm:leading-5">
                {`${isMobile ? 'Olympe' : 'Bienvenue sur Olympe'}`}
              </h1>
            )}
          </div>

          <TheConversationTypesList subtitle={'Choisissez votre type de conversation'} />

          {!!messages.length && (
            <TheMessagesList messages={messages} className={'py-4 sm:w-5/6 lg:w-full'}>
              {!!conversation?.currentFilesInfos.length && (
                <div
                  className={`flex w-full flex-row rounded-lg bg-brightGray p-3 sm:justify-normal lg:ml-auto lg:w-fit lg:gap-4`}
                >
                  <TheIconAvatarAI className={'ml-2 h-[45px] w-[45px]'} />

                  <div className={'flex flex-col gap-4 text-center'}>
                    <ReactMarkdown className={`prose text-center text-sm leading-8 sm:text-left`}>
                      {systemMessage.content}
                    </ReactMarkdown>
                    <div className={'flex flex-col justify-between gap-2 lg:flex-row'}>
                      {conversation?.currentFilesInfos.map((fileInfos) => (
                        <TheAddedDocumentTag
                          key={nanoid()}
                          fileInfos={{
                            ...fileInfos,
                            uploadStatus: 'UPLOADED',
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </TheMessagesList>
          )}
        </div>
      </div>

      <ProductTourWithOverlay
        condition={
          showProductTour && step !== END && step !== BEGIN && step !== PRODUCT_TOUR_USER_FILES && !messages.length
        }
      />

      <div className={'relative w-full sm:px-2 lg:w-3/5'}>
        {displaySourceFilter && (
          <>
            <button
              onClick={toggleDisplaySourceFilter}
              onKeyDown={toggleDisplaySourceFilter}
              className={'fixed bottom-0 right-0 z-10 h-screen w-screen'}
            />
            <NestedCheckboxList
              title={'SOURCES'}
              className={
                'absolute bottom-20 right-0 z-20 flex w-80 flex-col gap-3 rounded border border-solid border-bInput bg-bright px-2 py-3 shadow-softOutline'
              }
              initialData={data}
              onSelectedSourcesChange={handleSelectedSourcesChange}
            />
          </>
        )}
        <TheConversationMessageInput
          handleDisplayFilterMobile={handleDisplayFilterMobile}
          messages={messages}
          toggleDisplaySourceFilter={toggleDisplaySourceFilter}
          userInput={userInput}
          onInput={handleUserInput}
          handleSend={askQuestion}
          isQuestionLoading={isQuestionLoading}
          upLoadFile={handleUpload}
          handleStopFileContext={handleStopFileContext}
          currentConversation={conversation}
        />
      </div>
      {data.length > 0 && (
        <BaseModal
          contentVisible={displaySourceFilterMobile}
          onClick={handleDisplayFilterMobile}
          animationClass={'translate-y-full'}
          positionClass={'bottom-0'}
          styleBasedOnScreenSize={'w-full bg-bright shadow-softOutline'}
        >
          <NestedCheckboxList
            title={'FILTRER LES SOURCES'}
            className={
              'm-auto my-0 flex w-full flex-col justify-center gap-3 overflow-scroll rounded bg-bright pb-8 pl-3 pt-8 lg:hidden'
            }
            initialData={data}
            onSelectedSourcesChange={handleSelectedSourcesChange}
          />
        </BaseModal>
      )}
      <TimeSavedModal />
    </div>
  )
}
