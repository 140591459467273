import { TheIconClosingCross } from '../../icons/TheIconClosingCross.tsx'
import { TheEllipseStepper } from '../../TheEllipseStepper.tsx'
import { useOnboardingCard } from './use-onboardingCard.hook.tsx'

export function OnboardingStepOne() {
  const { handleNextStep, steps, handleQuitOnboarding } = useOnboardingCard()
  return (
    <div
      className={
        'flex h-[39.25rem] w-[32.875rem] flex-col items-end gap-1 rounded-2xl bg-mistySkyGray pb-7 pl-5 pr-3 pt-2 shadow-deepGrayShadow'
      }
    >
      <button className={'flex flex-col'} onClick={handleQuitOnboarding}>
        <TheIconClosingCross width={'10'} height={'10'} />
      </button>
      {/* 1383 */}
      <div className={'flex flex-1 flex-col items-center justify-center gap-2 self-stretch'}>
        {/* 1380 */}
        <div className={'flex flex-1 flex-col items-center gap-10 self-stretch pr-2'}>
          {/* 1382 */}
          <div className={'flex flex-col items-center gap-2 self-stretch'}>
            <img
              src={'/assets/images/onboarding/onboarding_1.gif'}
              alt={'Illustration 1'}
              className={'h-[18rem] self-stretch rounded-xl border border-solid border-iceberg'}
            />
          </div>
          {/* texte+bouton */}
          <div className={'flex flex-col items-center gap-7 self-stretch'}>
            {/* texte */}
            <div className={'flex flex-col items-center justify-center gap-3 self-stretch'}>
              {/* 1281 */}
              <div className={'flex items-center justify-center gap-2.5 text-center text-2xl font-semibold leading-8'}>
                Mes réponses sont sourcées...
              </div>
              {/* 1282 */}
              <div className={'flex flex-col items-center justify-center gap-2 self-stretch px-[3.25rem] py-0'}>
                <p className={'text-center text-base leading-6 text-label'}>
                  ...pour que vous puissiez les vérifier vous-même.
                </p>
                <p className={'text-center text-base leading-6 text-label'}>
                  Vous pouvez aussi filtrer mes réponses avec les sources que vous souhaitez.
                </p>
              </div>
            </div>
            <button
              className={
                'flex items-center justify-center gap-3 rounded-lg bg-primary px-6 py-3 text-sm leading-5 text-silver'
              }
              onClick={handleNextStep}
            >
              {'Suivant'}
            </button>
          </div>
        </div>
        {/* 1373 */}
        <div className={'flex flex-col items-center gap-7'}>
          {/* 1280 */}
          <TheEllipseStepper steps={steps} />
        </div>
      </div>
    </div>
  )
}
