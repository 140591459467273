import React, { useEffect, useState } from 'react'
import { TheIconExpand } from './icons/TheIconExpand.tsx'
import { TheIconCollapse } from './TheIconCollapse.tsx'
import BaseCheckbox from '../components/atomics/BaseCheckbox.tsx'
import { useAppSelector } from '../store/hooks.ts'
import { selectSourceCollectionsLabels } from '../store/sourceCollections/sourceCollectionsSlice.ts'

interface Collection {
  id: string
  label: string
}

export interface Group {
  label: string
  collections: Collection[]
  collapsed: boolean
  idAsSourceCollection?: string // Optional property
}

interface NestedCheckboxListProps {
  initialData: Group[]
  className: string
  title: string
  onSelectedSourcesChange: (selectedSources: string[]) => void
}

export function NestedCheckboxList({
  initialData,
  title,
  onSelectedSourcesChange,
  className,
}: Readonly<NestedCheckboxListProps>) {
  const selectedSourcesInitialState = useAppSelector(selectSourceCollectionsLabels)
  const [selectedSources, setSelectedSources] = useState<string[]>(selectedSourcesInitialState)
  const [indeterminateCategories, setIndeterminateCategories] = useState<string[]>([])
  const [data, setData] = useState(initialData)

  useEffect(() => {
    const categoriesWithSelectedSources: string[] = []
    for (const group of data) {
      const groupSourceIds = group.collections.map((collection) => collection.id)
      const selectedGroupSources = groupSourceIds.filter((id) => selectedSources.includes(id))

      if (selectedGroupSources.length > 0 && selectedGroupSources.length < groupSourceIds.length) {
        categoriesWithSelectedSources.push(group.label)
      }
    }
    setIndeterminateCategories(categoriesWithSelectedSources)
    onSelectedSourcesChange(selectedSources) // Notify the parent component of changes
  }, [selectedSources, data]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, sourceId: string) => {
    if (event.target.checked) {
      setSelectedSources([...selectedSources, sourceId])
    } else {
      setSelectedSources(selectedSources.filter((id) => id !== sourceId))
    }
  }

  const handleGroupCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, group: Group) => {
    if (group.idAsSourceCollection) {
      if (event.target.checked) {
        setSelectedSources([...selectedSources, group.idAsSourceCollection])
      } else {
        setSelectedSources(selectedSources.filter((id) => id !== group.idAsSourceCollection))
      }
    } else {
      const groupSourceIds = group.collections.map((collection) => collection.id)
      if (event.target.checked) {
        setSelectedSources([...selectedSources, ...groupSourceIds])
      } else {
        setSelectedSources(selectedSources.filter((id) => !groupSourceIds.includes(id)))
      }
    }
  }

  const renderCollections = (group: Group) => {
    if (group.collapsed || group.collections.length === 0) {
      return null
    }
    return group.collections.map((collection) => (
      <div className={`ml-4 rounded px-3 py-1 hover:bg-brightGray`} key={collection.id}>
        <label className={'group flex'}>
          <BaseCheckbox
            type="checkbox"
            className={'group-hover:bg-red accent-primary'}
            onChange={(e) => handleCheckboxChange(e, collection.id)}
            checked={selectedSources.includes(collection.id)}
          />
          <span className={'ml-3 text-sm font-normal text-primary'}>{collection.label}</span>
        </label>
      </div>
    ))
  }

  const displayCollection = (label: string) => {
    setData((prevData) =>
      prevData.map((group) => {
        if (group.label === label) {
          return { ...group, collapsed: !group.collapsed }
        }
        return group
      }),
    )
  }

  const renderGroups = (groups: Group[]) => {
    function getTheIconExpandOrCollapse(group: Group) {
      if (group.collections.length === 0) {
        return null
      }
      if (group.collapsed) {
        return <TheIconExpand onClick={() => displayCollection(group.label)} />
      } else {
        return <TheIconCollapse onClick={() => displayCollection(group.label)} />
      }
    }

    return groups.map((group) => (
      <div className={'flex flex-col'} key={group.label}>
        <div className={'flex w-full items-center justify-between hover:bg-brightGray'}>
          <label className={'flex px-3 py-1'}>
            <BaseCheckbox
              type="checkbox"
              className={`accent-primary`}
              onChange={(e) => handleGroupCheckboxChange(e, group)}
              checked={
                selectedSources.some((sourceId) =>
                  group.collections.some((collection) => collection.id === sourceId),
                ) ||
                (!!group.idAsSourceCollection && selectedSources.includes(group.idAsSourceCollection))
              }
              ref={(checkbox) => {
                if (checkbox) {
                  checkbox.indeterminate = indeterminateCategories.includes(group.label)
                }
              }}
            />
            <span className={'ml-3 text-sm font-semibold text-primary'}>{group.label}</span>
          </label>
          <div className={'mr-2 flex h-6 w-6 items-center justify-center'}>{getTheIconExpandOrCollapse(group)}</div>
        </div>
        {renderCollections(group)}
      </div>
    ))
  }

  return (
    <div className={className}>
      <h2 className={'px-3 text-sm font-medium text-silver'}>{title}</h2>
      {renderGroups(data)}
    </div>
  )
}
