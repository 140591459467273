export function TheIconCollaborateVision() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="40" height="40" rx="20" fill="#E8ECF1" />
      <rect x="4" y="4" width="40" height="40" rx="20" stroke="#C5CFE0" strokeOpacity="0.4" strokeWidth="8" />
      <g clipPath="url(#clip0_1650_438)">
        <g clipPath="url(#clip1_1650_438)">
          <path
            d="M23 16H19.8C18.1198 16 17.2798 16 16.638 16.327C16.0735 16.6146 15.6146 17.0735 15.327 17.638C15 18.2798 15 19.1198 15 20.8V28.2C15 29.8802 15 30.7202 15.327 31.362C15.6146 31.9265 16.0735 32.3854 16.638 32.673C17.2798 33 18.1198 33 19.8 33H27.2C28.8802 33 29.7202 33 30.362 32.673C30.9265 32.3854 31.3854 31.9265 31.673 31.362C32 30.7202 32 29.8802 32 28.2V25"
            stroke="#6E7682"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="30" cy="18" r="4" fill="#6E7682" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1650_438">
          <rect width="39" height="39" fill="white" transform="translate(6 7)" />
        </clipPath>
        <clipPath id="clip1_1650_438">
          <rect width="77" height="77" fill="white" transform="translate(-16 -15)" />
        </clipPath>
      </defs>
    </svg>
  )
}
