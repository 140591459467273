import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function ArrowIconBottom(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 54 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.20508 -15.7551L26.9662 15.008L44.7272 -15.7551L9.20508 -15.7551Z" fill="#212121" />
      <defs>
        <clipPath id="clip0_2629_9808">
          <rect width="54" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
