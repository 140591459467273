import { useAppDispatch } from '../store/hooks.ts'
import { useNavigate } from 'react-router-dom'
import { TheIconMenuBurger } from './icons/TheIconMenuBurger.tsx'
import { TheIconNew } from './icons/TheIconNew.tsx'
import { TheIconOlympeLogoWithoutText } from './icons/TheIconOlympeLogoWithoutText.tsx'
import { createConversation } from '../store/conversations/./use Cases/createConversation.ts'

type Props = {
  onHandleSidebar: () => void
}

export function TheHeaderMobile({ onHandleSidebar }: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleSidebar = () => {
    onHandleSidebar()
  }

  async function createAndNavigateToNewConversation() {
    const newConversation = await dispatch(createConversation()).unwrap()
    navigate(`/conversations/${newConversation.id}`)
  }

  return (
    <header className="flex h-14 w-screen items-center justify-between bg-bright px-5 py-2 shadow sm:hidden">
      <TheIconMenuBurger onClick={handleSidebar} />
      <TheIconOlympeLogoWithoutText className={'h-12'} />
      <TheIconNew onClick={createAndNavigateToNewConversation} />
    </header>
  )
}
